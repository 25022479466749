import {call, getContext, put, takeLatest} from "redux-saga/effects";
import {leadActionTypes} from "./lead.actions";
import {GoldApi} from "../../infra/services/api";
import {PayloadAction} from "@reduxjs/toolkit";
import {
  AddNewLeadPayload,
  BulkLeadUploadPayload,
  GetAnalyticsPayload,
  GetLeadListPayload,
  GetParentLeadId,
  GetParentLeadIdCreditDetail,
  LeadResponse,
} from "./lead.types";
import {leadActions} from "./index";
import {snackBarActions} from "../snackbar";
import {
  setLeadLoading,
  setParentLeadId,
  setCreditDetails,
} from "./lead.reducer";

const {
  getPartnerLeadListSuccess,
  getPartnerLeadListFailure,
  downloadCSVSuccess,
  downloadCSVFailure,
  addNewLeadSuccess,
  addNewLeadFailure,
  bulkLeadUploadSuccess,
  bulkLeadUploadFailure,
  getAnalyticsSuccess,
  getAnalyticsFailure,
  getLeadUnQualifiedReasonsFailure,
  getLeadUnQualifiedReasonsSuccess,
} = leadActions;

function* generalErrorFlow(action: PayloadAction<LeadResponse>) {
  yield put(
    snackBarActions.open({
      message: action.payload.error?.message || "Something went wrong!!",
      variant: "error",
    }),
  );
}

function* getPartnerLeadList(action: PayloadAction<GetLeadListPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.lead.getPartnerLeadList,
    action.payload,
  );
  if (error) {
    yield put(getPartnerLeadListFailure({response: null, error}));
  } else {
    yield put(getPartnerLeadListSuccess({response, error: null}));
  }
}

function* downloadCSVFlow() {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.lead.downloadCSV);
  if (error) {
    yield put(downloadCSVFailure({response: null, error}));
  } else {
    yield put(downloadCSVSuccess({response, error: null}));
    if (response.bulkUploadTemplate) {
      window.open(response.bulkUploadTemplate);
    }
  }
}

function* addNewLeadFlow(action: PayloadAction<AddNewLeadPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.lead.addNewLead, action.payload);
  if (error) {
    yield put(addNewLeadFailure({response: null, error}));
  } else {
    yield put(addNewLeadSuccess({response, error: null}));
  }
}

function* bulkLeadUploadFlow(action: PayloadAction<BulkLeadUploadPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.lead.bulkLeadUpload, action.payload);
  if (error) {
    yield put(bulkLeadUploadFailure({response: null, error}));
  } else {
    yield put(bulkLeadUploadSuccess({response, error: null}));
  }
}

function* getAnalyticsFlow(action: PayloadAction<GetAnalyticsPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.lead.getAnalytics, action.payload);
  if (error) {
    yield put(getAnalyticsFailure({response: null, error}));
  } else {
    yield put(getAnalyticsSuccess({response, error: null}));
  }
}

function* getLeadUnQualifiedReasonsFlow() {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.lead.getLeadUnQualifiedReasons);
  if (error) {
    yield put(getLeadUnQualifiedReasonsFailure({response: null, error}));
  } else {
    yield put(getLeadUnQualifiedReasonsSuccess({response, error: null}));
  }
}

function* getParentLeadIdFlow(action: PayloadAction<GetParentLeadId>) {
  yield put(
    setLeadLoading({
      key: "getParentLeadId",
      value: true,
    }),
  );
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.lead.getParentLeadId,
    action.payload,
  );

  if (error) {
    yield put(setLeadLoading({key: "getParentLeadId", value: false}));
    yield put(
      snackBarActions.open({
        message: error.message || "Something went wrong!!",
        variant: "error",
      }),
    );
  } else {
    yield put(setParentLeadId({response}));
  }
  yield put(
    setLeadLoading({
      key: "getParentLeadId",
      value: false,
    }),
  );
}

function* getParentLeadIdCreditDetailFlow(
  action: PayloadAction<GetParentLeadIdCreditDetail>,
) {
  yield put(
    setLeadLoading({
      key: "getParentLeadIdCreditDetail",
      value: true,
    }),
  );
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.lead.getParentLeadIdCreditDetail,
    action.payload,
  );

  if (error) {
    yield put(
      setLeadLoading({key: "getParentLeadIdCreditDetail", value: false}),
    );
    yield put(
      snackBarActions.open({
        message: error.message || "Something went wrong!!",
        variant: "error",
      }),
    );
  } else {
    yield put(setCreditDetails({response, leadId: action.payload.parentLeadId}));
  }
  yield put(
    setLeadLoading({
      key: "getParentLeadIdCreditDetail",
      value: false,
    }),
  );
}

export default function* leadSaga() {
  yield takeLatest(leadActionTypes.GetPartnerLeadList, getPartnerLeadList);
  yield takeLatest(leadActionTypes.GetPartnerLeadListFailure, generalErrorFlow);

  yield takeLatest(leadActionTypes.DownloadCSV, downloadCSVFlow);
  yield takeLatest(leadActionTypes.DownloadCSVFailure, generalErrorFlow);

  yield takeLatest(leadActionTypes.AddNewLead, addNewLeadFlow);
  yield takeLatest(leadActionTypes.AddNewLeadSuccess, function*() {
    yield put(
      snackBarActions.open({
        message: "Lead added successfully",
        variant: "success",
      }),
    );
  });
  yield takeLatest(leadActionTypes.AddNewLeadFailure, generalErrorFlow);

  yield takeLatest(leadActionTypes.BulkLeadUpload, bulkLeadUploadFlow);
  yield takeLatest(leadActionTypes.BulkLeadUploadSuccess, function*() {
    yield put(
      snackBarActions.open({
        message: "Lead File uploaded successfully",
        variant: "success",
      }),
    );
  });
  yield takeLatest(leadActionTypes.BulkLeadUploadFailure, generalErrorFlow);

  yield takeLatest(leadActionTypes.GetAnalytics, getAnalyticsFlow);
  yield takeLatest(leadActionTypes.GetAnalyticsFailure, generalErrorFlow);

  yield takeLatest(
    leadActionTypes.GetLeadUnQualifiedReasons,
    getLeadUnQualifiedReasonsFlow,
  );
  yield takeLatest(
    leadActionTypes.GetLeadUnQualifiedReasonsFailure,
    generalErrorFlow,
  );

  yield takeLatest(leadActionTypes.GetParentLeadId, getParentLeadIdFlow);

  yield takeLatest(
    leadActionTypes.GetParentLeadIdCreditDetail,
    getParentLeadIdCreditDetailFlow,
  );
}
