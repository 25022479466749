import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "../store";

export interface SnackbarState {
  message: string;
  open: boolean;
  variant: "success" | "error" | "info" | "warning";
}

const initialState: SnackbarState = {
  open: false,
  message: "",
  variant: "info",
};

const snackBarSlice = createSlice({
  name: "snackBar",
  initialState,
  reducers: {
    open: (state, action) => {
      state.open = true;
      state.message = action.payload.message;
      state.variant = action.payload.variant || "info";
    },
    close: (state) => {
      state.open = false;
      state.message = "";
    },
  },
});

// Actions
const snackBarActions = snackBarSlice.actions;

// Selectors
const snackbarSelectors = {
  getMessage: (state: RootState) => state.snackbar.message,
  getOpen: (state: RootState) => state.snackbar.open,
  getVariant: (state: RootState) => state.snackbar.variant,
};

export default snackBarSlice.reducer;
export {snackBarSlice, snackbarSelectors, snackBarActions};
