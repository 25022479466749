import React, {useEffect, useMemo} from "react";
import {Box, Typography} from "@mui/material";
import IGKeyValue from "app/components/IGLabelValue";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import personalDetailsActions from "app/store/loanRenewalRebook/personalDetails/personalDetails.actions";
import {personalDetailsSelectors} from "app/store/loanRenewalRebook/personalDetails";

interface GuarantorProfileDetailsProps {
  crId: number;
}
const GuarantorProfileDetails = ({crId}: GuarantorProfileDetailsProps) => {
  const dispatch = useAppDispatch();
  const guarantorProfileDetails = useAppSelector(
    personalDetailsSelectors.getGuarantorPersonalDetails,
  );

  const guarantorProfileMapping = useMemo(() => {
    if (guarantorProfileDetails) {
      const {
        firstName,
        middleName,
        lastName,
        primaryPhoneNumber,
        gender,
        maritalStatusDisplayValue,
        salutationDisplayValue,
        dob,
        relationshipWithBorrowerDisplayValue,
        maritalStatus,
        salutation,
      } = guarantorProfileDetails;
      return [
        {
          label: "Guarantor's Name",
          value: `${salutation}. ${firstName} ${middleName || ""} ${lastName}`,
        },
        {
          label: "Phone Number",
          value: primaryPhoneNumber,
        },
        {
          label: "Gurantor's DOB",
          value: dob,
        },
        {
          label: "Gender",
          value: gender,
        },
        {
          label: "Marital Status",
          value: maritalStatus,
        },
        {
          label: "Relationship with Borrower",
          value: relationshipWithBorrowerDisplayValue,
        },
      ];
    }
  }, [guarantorProfileDetails]);

  return (
    <Box
      bgcolor="#fff"
      borderRadius="4px"
      width="60%"
      border="1px solid rgba(0, 0, 0, 0.12)"
    >
      <Box display="flex" justifyContent="space-between" flexDirection="column">
        {guarantorProfileMapping?.map((item) => (
          <>
            <IGKeyValue
              label={item?.label}
              labelProps={{
                textAlign: "left",
              }}
              value={String(item?.value)}
              valueProps={{
                textAlign: "right",
              }}
            />
          </>
        ))}
      </Box>
    </Box>
  );
};

export default GuarantorProfileDetails;
