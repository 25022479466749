import {AddressModel} from "../../models/address.model";
import {createSlice} from "@reduxjs/toolkit";
import {GoldApiError} from "../../typings/api/goldApi.types";
import addressActions from "./address.actions";

type LoadingErrorState = "getAddressList";

export interface AddressState {
  addressList: AddressModel[],
  loading: Record<LoadingErrorState, boolean>
  errors: Record<LoadingErrorState, GoldApiError | null>
}

const initialState: AddressState = {
  addressList: [],
  loading: {
    getAddressList: false,
  },
  errors: {
    getAddressList: null,
  },
};

const AddressSlice = createSlice({
  name: "address",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(addressActions.getAddressList,
        (state) => {
          state.loading.getAddressList = true;
          state.errors.getAddressList = initialState.errors.getAddressList;
          state.addressList = initialState.addressList;
        },
      )
      .addCase(addressActions.getAddressListSuccess,
        (state, action) => {
          state.loading.getAddressList = false;
          state.addressList = [
            ...state.addressList,
            ...action.payload.response,
          ];
        },
      )
      .addCase(addressActions.getAddressListFailure,
        (state, action) => {
          state.loading.getAddressList = false;
          state.errors.getAddressList = action.payload.error;
        },
      )
      .addCase(addressActions.getLenderAddressList,
        (state) => {
          state.loading.getAddressList = true;
          state.errors.getAddressList = initialState.errors.getAddressList;
          state.addressList = initialState.addressList;
        },
      )
      .addCase(addressActions.getLenderAddressListSuccess,
        (state, action) => {
          state.loading.getAddressList = false;
          state.addressList = [
            ...state.addressList,
            ...action.payload.response,
          ];
        },
      )
      .addCase(addressActions.getLenderAddressListFailure,
        (state, action) => {
          state.loading.getAddressList = false;
          state.errors.getAddressList = action.payload.error;
        },
      )
  ,
});

export default AddressSlice.reducer;