// TODO: Look for  a way to block axios import in React components

import axios from "axios";
import {useState, useEffect} from "react";

// Local Imports
import store from "app/store/store";
import {
  resetAuthState,
  setAppSignature,
  setAuthToken,
  setLoading,
} from "app/store/auth/reducer";
import packageJson from "../../../../package.json";
import firebaseAppCheckToken from "../../../firebase/appCheckToken";
// Type Imports
import {
  GoldApiError,
  GoldApiService,
  GoldApiHook,
  ProcessApiProps,
} from "app/typings/api/goldApi.types";
// Util Imports
import {checkIfPathOrParamsValid, parseJwt} from "_metronic/utils/utils";
// Constant Imports
import {API_EXCEPTIONS} from "app/constants";
import {AUTH_ENDPOINTS} from "app/infra/services/api/auth/api";
import log from "../services/log";
import getApiUrl from "lib/ApiUrl";
import BugsnagNotify from "../services/bugsnag";

// >>>>>> Gold API Constants <<<<<<<<
const APP_VERSION = packageJson.version;
const APP_ENV = process.env.REACT_APP_ENV || "development";

// UTILITY FUNCTIONS - speicifc to Gold API
const getAppSignature = async () => {
  const appCheckToken = await firebaseAppCheckToken();
  return appCheckToken;
};

// >>>>>> Gold API Client <<<<<<<<<<
const goldApi = axios.create({
  baseURL: getApiUrl(),
  headers: {
    "Content-Type": "application/json",
    "device-version": APP_VERSION, // Should be renamed to x-device-version in the future
    "device-env": APP_ENV, // Should be renamed to x-device-env in the future
    "device-type": "DASHBOARD", // Should be renamed to x-device-type in the future
  },
});

let isRefreshing = false;
let refreshSubscribers: Array<Function> = [];

const onAccessTokenFetched = (accessToken: string) => {
  refreshSubscribers.forEach((callback) => callback(accessToken));
  refreshSubscribers = [];
};

const addRefreshSubscriber = (callback: Function) => {
  refreshSubscribers.push(callback);
};

// >>>>>> Request Interceptor <<<<<<<<<<
goldApi.interceptors.request.use(
  async (request) => {
    request.baseURL = getApiUrl();
    // ------->> Add App Signature <----------
    //    - Getting app signature from store to track a session
    //    - If not present we generate a new one
    //    - We reset this on logout
    const localAppSignature = store.getState().auth.appSignature;
    const appSignature = localAppSignature || (await getAppSignature());

    if (!request.headers) request.headers = {};

    request.headers["x-app-signature"] = appSignature;
    if (!localAppSignature) store.dispatch(setAppSignature(appSignature));

    // --------->> Use auth or not? <----------
    // We sent this header from request initializer based on
    // whether the request should be verified or not
    const verifyRequest = request.headers["x-verify-request"];
    delete request.headers["x-verify-request"];

    if (!verifyRequest) {
      return request;
    }

    // -----> Check if token is valid <-----
    const {authToken} = store.getState().auth;
    // Reset auth state & Reject if token is not present
    if (!authToken) {
      store.dispatch(resetAuthState());
      throw new Error(API_EXCEPTIONS.UNAUTHORIZED);
    }

    // Check if token is expired
    const parsedToken = parseJwt(authToken);

    if (parsedToken.exp <= Math.round(Date.now() / 1000)) {
      if (isRefreshing) {
        return new Promise((resolve) => {
          addRefreshSubscriber((newAccessToken: string) => {
            request.headers!["x-access-token"] = newAccessToken;
            resolve(request);
          });
        });
      }
      isRefreshing = true;
      log("Token Expired, refreshing...");
      const {refreshToken} = store.getState().auth;

      // Renew token
      if (refreshToken) {
        const newAuthToken = await renewAccessTokenHandler(refreshToken);

        // Reject if new token is not received
        if (!newAuthToken) {
          throw new Error(API_EXCEPTIONS.UNAUTHORIZED);
        }
        // Update auth token in request object
        request.headers["x-access-token"] = newAuthToken;
        isRefreshing = false;
        onAccessTokenFetched(newAuthToken);
        return request;
      }
      store.dispatch(resetAuthState());

    } else {
      request.headers["x-access-token"] = authToken;
      return request;
    }
    return request;
  },
  (err) => Promise.reject(err),
);

// >>>>>> Response Interceptor <<<<<<<<<<
goldApi.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const {
      config: originalRequest,
      response: {status, data},
    } = error;

    // Report to Bugsnag if Error doesn't start with "4"
    if (status.toString()[0] !== "4") {
      const {user} = store.getState().auth;
      BugsnagNotify({error, user, response: data});
    }

    // // Handle FWC_TOKEN_EXCEPTION
    // if ([401, 403].includes(status)) {
    //   if (!isRefreshing) {
    //     isRefreshing = true;
    //     try {
    //       const {refreshToken} = store.getState().auth;
    //       if (!refreshToken) {
    //         store.dispatch(resetAuthState());
    //         return Promise.reject(error);
    //       }
    //       const newAccessToken = await renewAccessTokenHandler(refreshToken);
    //       if (!newAccessToken) {
    //         store.dispatch(resetAuthState());
    //         return Promise.reject(error);
    //       }
    //       isRefreshing = false;
    //       onAccessTokenFetched(newAccessToken);
    //     } catch (err) {
    //       isRefreshing = false;
    //       return Promise.reject(err);
    //     }
    //   } else {
    //     return new Promise((resolve) => {
    //       addRefreshSubscriber((newAccessToken: string) => {
    //         originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
    //         resolve(axios(originalRequest));
    //       });
    //     });
    //   }
    // }
    return Promise.reject(error);
  },
);

// >>>>>> Gold Api Service <<<<<<<<<<
export const goldApiService: GoldApiService = async ({
  resource,
  options={},
  headers,
}) => {
  if (
    options.pathVars
    && Object.keys(options.pathVars).length !== 0
  ) {
    if (!checkIfPathOrParamsValid(options.pathVars)) {
      let message = "Something went wrong";
      if (process.env.NODE_ENV === "development") {
        message = `Path params not valid for request "${resource.URL}"`;
      }
      return {
        response: null,
        error: {
          code: API_EXCEPTIONS.INVALID_URL,
          message: message,
        },
        statusCode: 400,
      };
    }
  }

  if (options.queryParams && Object.keys(options.queryParams).length !== 0) {
    if (!checkIfPathOrParamsValid(options.queryParams)) {
      let message = "Something went wrong";
      if (process.env.NODE_ENV === "development") {
        message = `Query params not valid for request "${resource.URL}"`;
      }
      return {
        response: null,
        error: {
          code: API_EXCEPTIONS.INVALID_URL,
          message: message,
        },
        statusCode: 400,
      };
    }
  }

  const {url, method, data} = processApiProps({resource, options});

  const auth = true;

  // log(`[goldApi] 📤 : ${method} -> ${url}`, data);

  let error: any, response: any;

  try {
    response = await goldApi.request({
      url,
      method,
      data,
      headers: {
        ...headers,
        "x-verify-request": options?.hasOwnProperty("auth")
          ? options.auth
          : auth,
      },
    });

    const returnObject = {
      response: response?.data?.result,
      error: null,
      statusCode: response?.status,
    };

    // log("[goldApi] 📥  Success: ", returnObject);

    return returnObject;
  } catch (err) {
    error = err;

    log("[goldApi] 📥  Error: ", err);

    if (error.response) {
      return {
        response: null,
        error: error.response.data.error,
        statusCode: error.response?.status,
      };
    }
    return {
      response: null,
      error: {
        code: API_EXCEPTIONS.UNKNOWN_EXCEPTION,
        message: error?.name || error?.message || "Unknown Error",
      },
      statusCode: 400,
    };
  }
};

// >>>>>> useGoldAPI Hook <<<<<<<<<<
export const useGoldApi: GoldApiHook = async (props) => {
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState<any>(null);
  const [error, setError] = useState<GoldApiError | null>(null);

  if (
    props.options?.pathVars
    && Object.keys(props.options.pathVars).length !== 0
  ) {
    if (!checkIfPathOrParamsValid(props.options.pathVars)) {
      let message = "Something went wrong";
      if (process.env.NODE_ENV === "development") {
        message = `Path params not valid for request "${props.resource.URL}"`;
      }
      setError({
        code: API_EXCEPTIONS.INVALID_URL,
        message: message,
        errors: [],
        developerCode: null,
        fieldError: null,
      });
    }
  }

  if (props.options?.queryParams && Object.keys(props.options.queryParams).length !== 0) {
    if (!checkIfPathOrParamsValid(props.options.queryParams)) {
      let message = "Something went wrong";
      if (process.env.NODE_ENV === "development") {
        message = `Query params not valid for request "${props.resource.URL}"`;
      }
      setError({
        code: API_EXCEPTIONS.INVALID_URL,
        message: message,
        errors: [],
        developerCode: null,
        fieldError: null,
      });
    }
  }

  const {url, method, data} = processApiProps(props);

  // Request handler
  const fetchData = () => {
    goldApi
      .request({
        method,
        url,
        data: data,
        // headers: { ...headers },
      })
      .then((response) => {
        setResponse(response?.data?.result);
      })
      .catch((error) => {
        setError(error?.response?.data?.error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Make the request
  useEffect(() => {
    fetchData();
  }, []);

  // custom Hook return values
  return [response, error, loading];
};

// >>>>>> Gold Api Helper Functions <<<<<<<<<<

const processApiProps: ProcessApiProps = ({resource, options = {}}) => {
  let url = resource.URL;
  const method = resource.METHOD;

  // Replace path vars with their value if any
  const {pathVars} = options;

  if (pathVars) {
    Object.keys(pathVars).forEach(
      (key) => (url = url.replace(`:${key}`, `${pathVars[key]}`)),
    );
  }

  // Add queryParams if any
  const {queryParams} = options;
  if (queryParams) {
    url += `?${Object.keys(queryParams)
      .map((key) => `${key}=${queryParams[key]}`)
      .join("&")}`;
  }

  return {
    url,
    method,
    data: options.data,
  };
};

interface RenewAccessTokenHandler {
  (token: string): Promise<string | null>;
}

export const renewAccessTokenHandler: RenewAccessTokenHandler = async (
  token,
) => {
  if (token) {
    const {authToken} = store.getState().auth;
    const parsedToken = parseJwt(authToken);

    if (!(parsedToken.exp <= Math.round(Date.now() / 1000))) {
      return authToken;
    }
    store.dispatch(setLoading({
      key: "renewAccessToken",
      value: true,
    }));
    const {response, error} = await goldApiService({
      resource: AUTH_ENDPOINTS.REFRESH_TOKEN,
      options: {
        queryParams: {
          refreshToken: token,
        },
        auth: false,
      },
    });

    store.dispatch(setLoading({
      key: "renewAccessToken",
      value: false,
    }));

    if (response) {
      const {accessToken} = response;
      localStorage.setItem("authToken", accessToken);
      store.dispatch(setAuthToken(accessToken));
      return accessToken;
    }

    if (error) {
      store.dispatch(resetAuthState());
      return null;
    }
  }

  return null;
};

export default goldApi;
export {getAppSignature};
