import React, {useEffect, useCallback} from "react";
import {Box, Typography} from "@mui/material";
import {IGButton} from "app/components";
import ExperianLogo from "../GuarantorCreditCheck/ExperianLogo.png";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import {
  personalDetailsActions,
  personalDetailsSelectors,
} from "app/store/loanRenewalRebook/personalDetails";
import {
  creditDetailsActions,
  creditDetailsSelectors,
} from "app/store/loanRenewalRebook/creditDetails";
import {creditSessionStatusEnum} from "app/enums/gold_loan/recommendation";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {isNil} from "lodash";

interface GuarantorCreditCheckProps {
  crId: number;
}
const statusMapping = {
  HAS_CIBIL_SCORE: {text: "", color: "green"},
  PIN_CODE_DOES_NOT_EXIST: {
    text: "PIN CODE DOES NOT EXIST IN OUR RECORD",
    color: "error",
  },
  NO_USER_FOUND: {
    text: "CUSTOMER CREDIT PROFILE DOES NOT EXIST",
    color: "error",
  },
  NO_SCORE_RECEIVED: {
    text: "CUSTOMER CREDIT HISTORY NOT FOUND",
    color: "error",
  },
  FAILURE: {text: "PLEASE ASK LM TO RETRY", color: "error"},
  INTERMEDIATE_STATE: {
    text: "PLEASE WAIT FOR LM TO FETCH CREDIT_SCORE",
    color: "primary",
  },
  ASK_LM_TO_RETRY: {
    text: "PLEASE ASK LM TO RETRY",
    color: "primary",
  },
  DEFAULT: {text: "-", color: "primary"},
};
const GuarantorCreditCheck = ({crId}: GuarantorCreditCheckProps) => {
  const dispatch = useAppDispatch();
  const personalDetails = useAppSelector(
    personalDetailsSelectors.getGuarantorPersonalDetails,
  );

  const creditReportHtml = useAppSelector(
    creditDetailsSelectors.getCreditReportHtml,
  );

  const creditSessionDetails = useAppSelector(
    creditDetailsSelectors.getCreditSessionStatus,
  );

  const {
    status: creditSessionStatus,
    failureReason: creditSessionFailureReason,
    consentStatus,
  } = creditSessionDetails ?? {};

  const {cibilScrore} = personalDetails ?? {};

  const viewCreditReport = () => {
    if (creditReportHtml) {
      const win = window.open(
        "",
        "_blank",
        "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=780,height=200",
      );
      if (win) {
        win.document.body.innerHTML = creditReportHtml;
      }
    }
  };

  const getCibilScoreCase = () => {
    if (cibilScrore) {
      return "HAS_CIBIL_SCORE";
    } else if (creditSessionFailureReason) {
      return "PIN_CODE_DOES_NOT_EXIST";
    } else if (creditSessionStatus === creditSessionStatusEnum.NO_USER_FOUND) {
      return "NO_USER_FOUND";
    } else if (
      creditSessionStatus === creditSessionStatusEnum.NO_SCORE_RECEIVED
    ) {
      return "NO_SCORE_RECEIVED";
    } else if (creditSessionStatus === creditSessionStatusEnum.FAILURE) {
      return "FAILURE";
    } else if (
      creditSessionStatus === creditSessionStatusEnum.INTERMEDIATE_STATE
    ) {
      return "INTERMEDIATE_STATE";
    } else if (
      !creditSessionStatus &&
      !creditSessionFailureReason &&
      creditSessionStatus
    ) {
      return "ASK_LM_TO_RETRY";
    }
    return "DEFAULT";
  };

  const cibilScoreValue = () => {
    const caseType = getCibilScoreCase();
    return statusMapping[caseType].text;
  };

  const cibilScoreColor = () => {
    const caseType = getCibilScoreCase();
    return statusMapping[caseType].color;
  };

  const renderStatusBlock = useCallback(() => {
    const caseType = getCibilScoreCase();
    const {text, color} = statusMapping[caseType];

    if (!text) {
      return null;
    }

    return (
      <Box display="flex" flexDirection="column" alignItems="flex-start" mt={2}>
        <Typography variant="body1" color={color}>
          {text}
        </Typography>
      </Box>
    );
  }, [creditSessionStatus, creditSessionFailureReason, cibilScrore]);

  useEffect(() => {
    if (
      !isNil(creditSessionStatus) &&
      creditSessionStatus === creditSessionStatusEnum.SUCCESS
    ) {
      dispatch(
        creditDetailsActions.getTakeoverCreditReportHtml({
          crId,
          cibilConsentType: "GUARANTOR_CIBIL_CONSENT",
        }),
      );
    }
  }, [creditSessionStatus]);
  return (
    <Box width="30%">
      <Typography lineHeight="133%" fontWeight={500} fontSize="14px">
        Credit Score
      </Typography>
      <Box>
        <Typography fontFamily="Roboto" variant="h6" fontWeight={500}>
          {cibilScrore}
        </Typography>
        {renderStatusBlock()}
      </Box>
      <Box display="flex" alignItems="center" textAlign="center" gap={1}>
        <Typography color="gray" fontFamily="Averta" fontSize="10px">
          POWERED BY
        </Typography>
        <Box component="img" src={ExperianLogo} alt="Descriptive Alt Text" />
      </Box>

      {creditReportHtml && (
        <IGButton
          endIcon={<ArrowForwardIcon />}
          variant="text"
          onClick={() => viewCreditReport()}
        >
          SEE CREDIT REPORT
        </IGButton>
      )}
    </Box>
  );
};

export default GuarantorCreditCheck;
