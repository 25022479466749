import {RootState} from "app/store/store";

const loanRenewRebookSelectors = {
  getLoanRenewRebookMilestones: (state: RootState) =>
    state.loanRenewRebook.loanRenewRebookMilestones,
  getSelectedMileStone: (state: RootState) =>
    state.loanRenewRebook.selectedMileStone,
  checkIsLoanRenewalAllowed: (state: RootState) =>
    state.loanRenewRebook.isLoanRenewalAllowed,
  getRenewRebookJobDetails: (state: RootState) =>
    state.loanRenewRebook.loanRenewRebookJobDetails,
  getLoanRenewRebookConfig: (state: RootState) =>
    state.loanRenewRebook.loanRenewRebookConfig,
  getDocumentListByMilestone: (state: RootState) =>
    state.loanRenewRebook.documentListByMilestone,
  getLoanRenewalOffer: (state: RootState) =>
    state.loanRenewRebook.loanRenewalOfferDetail,
  getLenderGatingStatus: (state: RootState) =>
    state.loanRenewRebook.isLenderGatingDone,
  getLoanRenewalFeatureFlagStatus: (state: RootState) =>
    state.loanRenewRebook.isLoanRenewalFlagEnabled,
  getLoanDetailsEditable: (state: RootState) =>
    state.loanRenewRebook.isLoanDetailsEditable,
  getLoanRenewedDetails: (state: RootState) =>
    state.loanRenewRebook.loanRenewalDetails,

  getEncryptedDocumentDetail: (state: RootState) =>
    state.loanRenewRebook.encryptedDocumentDetail,

  getLoanCreationStatus: (state: RootState) =>
    state.loanRenewRebook.loanCreationStatus,

  getSelectedLender: (state: RootState) => state.loanRenewRebook.selectedLender,
  getLoading: (state: RootState) => state.loanRenewRebook.loading,
  getErrors: (state: RootState) => state.loanRenewRebook.errors,
  getOpenModals: (state: RootState) => state.loanRenewRebook.openModals,
};

export default loanRenewRebookSelectors;
