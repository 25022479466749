import React, {useMemo, useState} from "react";
import {Card, CardMedia} from "@mui/material";
import {IGButton, IGCarousel, IGDialog} from "app/components";
import {KycDocuments} from "app/models/loanRenewalRebook/personalDetails.model";
import {fileType} from "app/enums/generalPurpose";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import PdfIcon from "../GuarantorSupplementDocuments/pdfIcon.jpeg";

interface GuarantorDocViewProps {
  guarantorDocument: KycDocuments;
}
const GuarantorDocView = ({guarantorDocument}: GuarantorDocViewProps) => {
  const [isViewAllOpen, setIsViewAllOpen] = useState(false);

  const viewPdf = (link: string) => {
    window.open(link, "_blank");
  };

  const docImageList = useMemo(() => {
    const images = [];

    if (guarantorDocument) {
      if (guarantorDocument.fileUrl) {
        images.push({title: "", url: guarantorDocument.fileUrl});
      }

      if (guarantorDocument.backFileUrl) {
        images.push({title: "", url: guarantorDocument.backFileUrl});
      }

      guarantorDocument.crDocumentImageResponseDtoList &&
        guarantorDocument.crDocumentImageResponseDtoList.forEach((image) => {
          images.push({title: "", url: image.fileUrl});
        });
    }

    return images;
  }, [guarantorDocument]);

  return (
    <>
      <Card sx={{width: "90px", height: "68px"}}>
        <CardMedia
          component="img"
          height="140"
          image={
            guarantorDocument?.fileType === fileType.PDF
              ? PdfIcon
              : guarantorDocument?.fileUrl
          }
          alt="Image Thumbnail"
        />
      </Card>
      <IGButton
        endIcon={<ArrowForwardIcon />}
        onClick={() => {
          guarantorDocument?.fileType !== fileType.PDF
            ? setIsViewAllOpen(true)
            : viewPdf(guarantorDocument?.fileUrl);
        }}
        variant="text"
      >
        VIEW ALL
      </IGButton>
      {isViewAllOpen && (
        <IGDialog
          open={isViewAllOpen}
          onClose={() => setIsViewAllOpen(false)}
          hideFooter
          maxWidth="sm"
        >
          <IGCarousel
            imageList={docImageList}
            showThumbnailPreview={true}
            orientation="horizontal"
            containerHeight="65vh"
          />
        </IGDialog>
      )}
    </>
  );
};

export default GuarantorDocView;
