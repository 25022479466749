import {RootState} from "../store";

const leadSelectors = {
  getLoading: (state: RootState) => state.lead.loading,
  getErrors: (state: RootState) => state.lead.errors,
  getLeadList: (state: RootState) => state.lead.partnerLeadList,
  getAnalytics: (state: RootState) => state.lead.analyticsDetails,
  getPaginationData: (state: RootState) => state.lead.paginationData,
  getOpenModals: (state: RootState) => state.lead.openModals,
  getLeadUnQualifiedReasons: (state: RootState) =>
    state.lead.leadUnQualifiedReasons,
  getFilters: (state: RootState) => state.lead.filters,
  getListReload: (state: RootState) => state.lead.listReload,
  getParentLeadId: (state: RootState) => state.lead.parentLeadId,
  getCreditDetails: (state: RootState, leadId: number) =>
    state.lead.creditDetails[leadId],
};

export default leadSelectors;
