import {LoanBookingJobDocumentType} from "app/models/lenderLoan.model";
import {RootState} from "../store";

const lenderLoanSelectors = {
  getDisbursedLoansList: (state: RootState) =>
    Object.values(state.lenderLoan.disbursedLoansByCrId),
  getLoanSanctioningJobList: (state: RootState) =>
    Object.values(state.lenderLoan.loanBookingJobsById),

  getDisbursedJobBySelectedCrId: (state: RootState, crId: number) =>
    Object.values(state.lenderLoan.disbursedLoansByCrId).find(
      (l) => l.crId === crId,
    ),
  getLoanBookingJobBySelectedId: (state: RootState, id: number) =>
    state.lenderLoan.loanBookingJobsById[id],
  getLoanBookingJobDocumentsByDocumentType: (
    state: RootState,
    requestId: number,
    documentType: LoanBookingJobDocumentType,
  ) => {
    const job = state.lenderLoan.loanBookingJobsById[requestId];
    return job?.documentList?.[documentType];
  },
  getFilters: (state: RootState) => state.lenderLoan.filters,
  getPagination: (state: RootState) => state.lenderLoan.pagination,
  getOpenModals: (state: RootState) => state.lenderLoan.openModals,
  getLoading: (state: RootState) => state.lenderLoan.loading,
  getErrors: (state: RootState) => state.lenderLoan.errors,
  getActiveTab: (state: RootState, module: "loanBooking" | "loanSanctioning") =>
    state.lenderLoan.activeTabs[module],
  getListReload: (state: RootState) => state.lenderLoan.isListReloadRequired,
  getSelectedCrId: (state: RootState) => state.lenderLoan.selectedCrId,
  getSelectedRequestId: (state: RootState) =>
    state.lenderLoan.selectedRequestId,
};

export default lenderLoanSelectors;
