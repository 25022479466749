import ROUTES from "app/constants/routes";
import {RolesEnum} from "app/enums/roles";

const {
  DG_ORDER,
  EMI_ORDERS,
  GIFTCARD_CONFIG,
  USER_TIER_CONFIG,
  REFFERAL_CONFIG,
} = ROUTES;

export interface SubSectionTab {
  name: string;
  enum: string;
}

export interface SubSection {
  name: string;
  enum: string;
  tabList: SubSectionTab[];
}
export interface Section {
  name: string;
  enum: string;
  subSectionList: SubSection[];
}

export interface SubFeature {
  name: string;
  path: string;
  whitelistedRoles: string[];
}

export interface Feature {
  name: string;
  path: string;
  dynamicSubMenu?: boolean;
  subFeatures?: SubFeature[];
  whitelistedRoles: string[];
}

export interface AllFeatures {
  [key: string]: Feature;
}

// Features
const allFeatures: AllFeatures = {
  emiOrder: {
    name: "EMI Order",
    path: EMI_ORDERS,
    whitelistedRoles: [
      RolesEnum.ADMIN,
      RolesEnum.CST,
      RolesEnum.A_HIMANSHU,
      RolesEnum.AUGMONT,
      RolesEnum.ACCOUNT_TEAM,
      RolesEnum.CREDIT_MANAGER,
      RolesEnum.SCHEDULER,
      RolesEnum.SCHEDULER_TEAM_LEAD,
      RolesEnum.BUSINESS,
      RolesEnum.FOUNDER,
      RolesEnum.CUSTOMER_SUPPORT,
      RolesEnum.SUPPORT_AND_RETENTION,
      RolesEnum.SENIOR_OPERATIONS,
      RolesEnum.CUSTOMER_SUPPORT_TEAM_LEAD,
      RolesEnum.INSIDE_SALES_LOAN,
      RolesEnum.INSIDE_SALES_LOCKER,
      RolesEnum.INSIDE_SALES_TEAM_LEAD,
      RolesEnum.CITY_HEAD,
      RolesEnum.INSIDE_SALES_AFFILIATE,
      RolesEnum.LENDER,
      RolesEnum.CLOSURE_RETENTION_TEAM,
      RolesEnum.ISR_RETENTION_TEAM_LEAD,
    ],
  },
  giftCardConfig: {
    name: "Gift Card Config",
    path: GIFTCARD_CONFIG,
    whitelistedRoles: [RolesEnum.ADMIN, RolesEnum.FOUNDER],
  },
  dgOrder: {
    name: "DG Order",
    path: DG_ORDER,
    whitelistedRoles: [
      RolesEnum.ADMIN,
      RolesEnum.FOUNDER,
      RolesEnum.BUSINESS,
      RolesEnum.SENIOR_OPERATIONS,
      RolesEnum.CUSTOMER_SUPPORT_TEAM_LEAD,
      RolesEnum.CST,
      RolesEnum.ACCOUNT_TEAM,
      RolesEnum.CUSTOMER_SUPPORT,
      RolesEnum.SUPPORT_AND_RETENTION,
      RolesEnum.INSIDE_SALES_TEAM_LEAD,
      RolesEnum.CLOSURE_RETENTION_TEAM,
      RolesEnum.ISR_RETENTION_TEAM_LEAD,
    ],
  },
  users: {
    name: "Users",
    path: "users",
    subFeatures: [
      {
        name: "All Users",
        path: "all-users",
        whitelistedRoles: [
          RolesEnum.ADMIN,
          RolesEnum.FOUNDER,
          RolesEnum.BUSINESS,
          RolesEnum.SENIOR_OPERATIONS,
          RolesEnum.CUSTOMER_SUPPORT_TEAM_LEAD,
          RolesEnum.CST,
          RolesEnum.ACCOUNT_TEAM,
          RolesEnum.CUSTOMER_SUPPORT,
          RolesEnum.SUPPORT_AND_RETENTION,
          RolesEnum.INSIDE_SALES_TEAM_LEAD,
          RolesEnum.CLOSURE_RETENTION_TEAM,
          RolesEnum.ISR_RETENTION_TEAM_LEAD,
        ],
      },
      {
        name: "Banned Users",
        path: "banned-users",
        whitelistedRoles: [RolesEnum.ADMIN, RolesEnum.FOUNDER],
      },
      {
        name: "Banned Devices",
        path: "banned-devices",
        whitelistedRoles: [RolesEnum.ADMIN, RolesEnum.FOUNDER],
      },
      {
        name: "Whiteist Users",
        path: "whitelist-users",
        whitelistedRoles: [RolesEnum.ADMIN, RolesEnum.FOUNDER],
      },
    ],
    whitelistedRoles: [
      RolesEnum.ADMIN,
      RolesEnum.FOUNDER,
      RolesEnum.BUSINESS,
      RolesEnum.SENIOR_OPERATIONS,
      RolesEnum.CUSTOMER_SUPPORT_TEAM_LEAD,
      RolesEnum.CST,
      RolesEnum.ACCOUNT_TEAM,
      RolesEnum.CUSTOMER_SUPPORT,
      RolesEnum.SUPPORT_AND_RETENTION,
      RolesEnum.INSIDE_SALES_TEAM_LEAD,
      RolesEnum.CLOSURE_RETENTION_TEAM,
      RolesEnum.ISR_RETENTION_TEAM_LEAD,
    ],
  },
  payments: {
    name: "Payments",
    path: "payments",
    subFeatures: [
      {
        name: "Payments",
        path: "payments",
        whitelistedRoles: [
          RolesEnum.ADMIN,
          RolesEnum.FOUNDER,
          RolesEnum.BUSINESS,
          RolesEnum.SENIOR_OPERATIONS,
          RolesEnum.CUSTOMER_SUPPORT_TEAM_LEAD,
          RolesEnum.CST,
          RolesEnum.ACCOUNT_TEAM,
          RolesEnum.CUSTOMER_SUPPORT,
          RolesEnum.SUPPORT_AND_RETENTION,
          RolesEnum.INSIDE_SALES_TEAM_LEAD,
          RolesEnum.CLOSURE_RETENTION_TEAM,
          RolesEnum.ISR_RETENTION_TEAM_LEAD,
        ],
      },
      {
        name: "Payouts",
        path: "payouts",
        whitelistedRoles: [
          RolesEnum.ADMIN,
          RolesEnum.FOUNDER,
          RolesEnum.BUSINESS,
          RolesEnum.SENIOR_OPERATIONS,
          RolesEnum.CUSTOMER_SUPPORT_TEAM_LEAD,
          RolesEnum.CST,
          RolesEnum.ACCOUNT_TEAM,
          RolesEnum.CUSTOMER_SUPPORT,
          RolesEnum.SUPPORT_AND_RETENTION,
          RolesEnum.INSIDE_SALES_TEAM_LEAD,
          RolesEnum.CLOSURE_RETENTION_TEAM,
          RolesEnum.ISR_RETENTION_TEAM_LEAD,
        ],
      },
      {
        name: "Report",
        path: "report",
        whitelistedRoles: [
          RolesEnum.ADMIN,
          RolesEnum.FOUNDER,
          RolesEnum.BUSINESS,
          RolesEnum.SENIOR_OPERATIONS,
          RolesEnum.CUSTOMER_SUPPORT_TEAM_LEAD,
          RolesEnum.CST,
          RolesEnum.ACCOUNT_TEAM,
          RolesEnum.CUSTOMER_SUPPORT,
          RolesEnum.SUPPORT_AND_RETENTION,
          RolesEnum.INSIDE_SALES_TEAM_LEAD,
          RolesEnum.CLOSURE_RETENTION_TEAM,
          RolesEnum.ISR_RETENTION_TEAM_LEAD,
        ],
      },
    ],
    whitelistedRoles: [
      RolesEnum.ADMIN,
      RolesEnum.FOUNDER,
      RolesEnum.BUSINESS,
      RolesEnum.SENIOR_OPERATIONS,
      RolesEnum.CUSTOMER_SUPPORT_TEAM_LEAD,
      RolesEnum.CST,
      RolesEnum.ACCOUNT_TEAM,
      RolesEnum.CUSTOMER_SUPPORT,
      RolesEnum.SUPPORT_AND_RETENTION,
      RolesEnum.INSIDE_SALES_TEAM_LEAD,
      RolesEnum.CLOSURE_RETENTION_TEAM,
      RolesEnum.ISR_RETENTION_TEAM_LEAD,
    ],
  },
  promotions: {
    name: "Promotions",
    path: "promotions",
    subFeatures: [
      {
        name: "Dynamic Link",
        path: "dynamic-link",
        whitelistedRoles: [
          RolesEnum.ADMIN,
          RolesEnum.FOUNDER,
          RolesEnum.BUSINESS,
          RolesEnum.SENIOR_OPERATIONS,
          RolesEnum.CUSTOMER_SUPPORT_TEAM_LEAD,
          RolesEnum.CST,
          RolesEnum.ACCOUNT_TEAM,
          RolesEnum.CUSTOMER_SUPPORT,
          RolesEnum.SUPPORT_AND_RETENTION,
          RolesEnum.INSIDE_SALES_TEAM_LEAD,
          RolesEnum.CLOSURE_RETENTION_TEAM,
          RolesEnum.ISR_RETENTION_TEAM_LEAD,
        ],
      },
      {
        name: "Promos",
        path: "promos",
        whitelistedRoles: [RolesEnum.ADMIN, RolesEnum.FOUNDER],
      },
    ],
    whitelistedRoles: [
      RolesEnum.ADMIN,
      RolesEnum.FOUNDER,
      RolesEnum.BUSINESS,
      RolesEnum.SENIOR_OPERATIONS,
      RolesEnum.CUSTOMER_SUPPORT_TEAM_LEAD,
      RolesEnum.CST,
      RolesEnum.ACCOUNT_TEAM,
      RolesEnum.CUSTOMER_SUPPORT,
      RolesEnum.SUPPORT_AND_RETENTION,
      RolesEnum.INSIDE_SALES_TEAM_LEAD,
      RolesEnum.CLOSURE_RETENTION_TEAM,
      RolesEnum.ISR_RETENTION_TEAM_LEAD,
    ],
  },
  csv: {
    name: "CSV",
    path: "csv",
    whitelistedRoles: [
      RolesEnum.ADMIN,
      RolesEnum.FOUNDER,
      RolesEnum.BUSINESS,
      RolesEnum.SENIOR_OPERATIONS,
      RolesEnum.CUSTOMER_SUPPORT_TEAM_LEAD,
      RolesEnum.A_HIMANSHU,
      RolesEnum.AUGMONT,
      RolesEnum.ACCOUNT_TEAM,
      RolesEnum.ISR_RETENTION_TEAM_LEAD,
    ],
  },
  internalUsers: {
    name: "Internal Users",
    path: "internal-users",
    subFeatures: [
      {
        name: "All Internal Users",
        path: "all-internal-users",
        whitelistedRoles: [
          RolesEnum.ADMIN,
          RolesEnum.FOUNDER,
          RolesEnum.USER_ADMIN,
        ],
      },
      {
        name: "Create Internal Users",
        path: "create-internal-users",
        whitelistedRoles: [
          RolesEnum.ADMIN,
          RolesEnum.FOUNDER,
          RolesEnum.USER_ADMIN,
        ],
      },
    ],
    whitelistedRoles: [
      RolesEnum.ADMIN,
      RolesEnum.FOUNDER,
      RolesEnum.USER_ADMIN,
    ],
  },
  userTierConfig: {
    name: "User Tier Config",
    path: USER_TIER_CONFIG,
    whitelistedRoles: [RolesEnum.ADMIN, RolesEnum.FOUNDER],
  },
  refferalConfig: {
    name: "Refferal Config",
    path: REFFERAL_CONFIG,
    whitelistedRoles: [RolesEnum.ADMIN, RolesEnum.FOUNDER],
  },

  support: {
    name: "Support",
    path: "support",
    whitelistedRoles: [
      RolesEnum.ADMIN,
      RolesEnum.FOUNDER,
      RolesEnum.BUSINESS,
      RolesEnum.SENIOR_OPERATIONS,
      RolesEnum.CUSTOMER_SUPPORT_TEAM_LEAD,
      RolesEnum.CST,
      RolesEnum.ACCOUNT_TEAM,
      RolesEnum.CUSTOMER_SUPPORT,
      RolesEnum.SUPPORT_AND_RETENTION,
      RolesEnum.INSIDE_SALES_TEAM_LEAD,
      RolesEnum.CLOSURE_RETENTION_TEAM,
      RolesEnum.ISR_RETENTION_TEAM_LEAD,
    ],
  },
  scheduling: {
    name: "Scheduling",
    path: "scheduling",
    subFeatures: [
      {
        name: "Job View",
        path: "job-view",
        whitelistedRoles: [
          RolesEnum.ADMIN,
          RolesEnum.FOUNDER,
          RolesEnum.BUSINESS,
          RolesEnum.SENIOR_OPERATIONS,
          RolesEnum.SCHEDULER,
          RolesEnum.ISR_RETENTION_TEAM_LEAD,
          RolesEnum.SCHEDULER_TEAM_LEAD,
          RolesEnum.CUSTOMER_SUPPORT_TEAM_LEAD,
          RolesEnum.CITY_HEAD,
          RolesEnum.LOAN_MANAGER_LEAD,
          RolesEnum.LOAN_OPS_ADMIN,
        ],
      },
      {
        name: "LM Availability",
        path: "lm-availability",
        whitelistedRoles: [
          RolesEnum.ADMIN,
          RolesEnum.FOUNDER,
          RolesEnum.BUSINESS,
          RolesEnum.SENIOR_OPERATIONS,
          RolesEnum.SCHEDULER,
          RolesEnum.SCHEDULER_TEAM_LEAD,
          RolesEnum.CUSTOMER_SUPPORT_TEAM_LEAD,
          RolesEnum.INSIDE_SALES_TEAM_LEAD,
          RolesEnum.INSIDE_SALES_AFFILIATE,
          RolesEnum.INSIDE_SALES_LOAN,
          RolesEnum.INSIDE_SALES_LOCKER,
          RolesEnum.CITY_HEAD,
          RolesEnum.LOAN_MANAGER_LEAD,
          RolesEnum.CLOSURE_RETENTION_TEAM,
          RolesEnum.ISR_RETENTION_TEAM_LEAD,
          RolesEnum.LOAN_OPS_ADMIN,
        ],
      },
      {
        name: "Resource View",
        path: "resource-view",
        whitelistedRoles: [
          RolesEnum.ADMIN,
          RolesEnum.FOUNDER,
          RolesEnum.BUSINESS,
          RolesEnum.SENIOR_OPERATIONS,
          RolesEnum.SCHEDULER,
          RolesEnum.ISR_RETENTION_TEAM_LEAD,
          RolesEnum.SCHEDULER_TEAM_LEAD,
          RolesEnum.CUSTOMER_SUPPORT_TEAM_LEAD,
          RolesEnum.LOAN_OPS_ADMIN,
        ],
      },

      {
        name: "Lead Credit History",
        path: "lead-credit-history",
        whitelistedRoles: [
          RolesEnum.ADMIN,
          RolesEnum.FOUNDER,
          RolesEnum.BUSINESS,
          RolesEnum.SENIOR_OPERATIONS,
          RolesEnum.SCHEDULER,
          RolesEnum.SCHEDULER_TEAM_LEAD,
          RolesEnum.CUSTOMER_SUPPORT_TEAM_LEAD,
          RolesEnum.INSIDE_SALES_TEAM_LEAD,
          RolesEnum.INSIDE_SALES_AFFILIATE,
          RolesEnum.INSIDE_SALES_LOAN,
          RolesEnum.INSIDE_SALES_LOCKER,
          RolesEnum.CITY_HEAD,
          RolesEnum.LOAN_MANAGER_LEAD,
          RolesEnum.CLOSURE_RETENTION_TEAM,
          RolesEnum.ISR_RETENTION_TEAM_LEAD,
          RolesEnum.LOAN_OPS_ADMIN,
        ],
      },
    ],
    whitelistedRoles: [
      RolesEnum.ADMIN,
      RolesEnum.FOUNDER,
      RolesEnum.BUSINESS,
      RolesEnum.SENIOR_OPERATIONS,
      RolesEnum.SCHEDULER,
      RolesEnum.SCHEDULER_TEAM_LEAD,
      RolesEnum.CUSTOMER_SUPPORT_TEAM_LEAD,
      RolesEnum.INSIDE_SALES_TEAM_LEAD,
      RolesEnum.CITY_HEAD,
      RolesEnum.INSIDE_SALES_AFFILIATE,
      RolesEnum.INSIDE_SALES_LOAN,
      RolesEnum.INSIDE_SALES_LOCKER,
      RolesEnum.LOAN_MANAGER_LEAD,
      RolesEnum.CLOSURE_RETENTION_TEAM,
      RolesEnum.ISR_RETENTION_TEAM_LEAD,
      RolesEnum.LOAN_OPS_ADMIN,
    ],
  },
  lockerJobScheduling: {
    name: "Locker Job Scheduling",
    path: "locker-job-scheduling",
    whitelistedRoles: [
      RolesEnum.ADMIN,
      RolesEnum.FOUNDER,
      RolesEnum.BUSINESS,
      RolesEnum.SCHEDULER,
      RolesEnum.ISR_RETENTION_TEAM_LEAD,
      RolesEnum.SCHEDULER_TEAM_LEAD,
      RolesEnum.INSIDE_SALES_LOCKER,
      RolesEnum.CUSTOMER_SUPPORT_TEAM_LEAD,
    ],
  },
  activeLoans: {
    name: "Active Loans",
    path: "active-loans",
    subFeatures: [],
    whitelistedRoles: [
      RolesEnum.ADMIN,
      RolesEnum.FOUNDER,
      RolesEnum.BUSINESS,
      RolesEnum.SENIOR_OPERATIONS,
      RolesEnum.CREDIT_MANAGER,
      RolesEnum.SCHEDULER,
      RolesEnum.ISR_RETENTION_TEAM_LEAD,
      RolesEnum.SCHEDULER_TEAM_LEAD,
      RolesEnum.CITY_HEAD,
      RolesEnum.LOAN_MANAGER_LEAD,
      RolesEnum.COLLECTION_MANAGER,
    ],
  },
  accounts: {
    name: "Accounts",
    path: "accounts",
    whitelistedRoles: [
      RolesEnum.ADMIN,
      RolesEnum.FOUNDER,
      RolesEnum.BUSINESS,
      RolesEnum.SENIOR_OPERATIONS,
      RolesEnum.ACCOUNT_TEAM,
      RolesEnum.CREDIT_MANAGER,
    ],
  },
  blacklistManagement: {
    name: "Blacklist",
    path: "blacklist",
    whitelistedRoles: [
      RolesEnum.SENIOR_OPERATIONS,
      RolesEnum.BUSINESS,
      RolesEnum.CREDIT_MANAGER,
    ],
  },
  customerProfiles: {
    name: "Customer Profiles",
    path: "customer-profiles",
    whitelistedRoles: [
      RolesEnum.ADMIN,
      RolesEnum.FOUNDER,
      RolesEnum.BUSINESS,
      RolesEnum.SENIOR_OPERATIONS,
      RolesEnum.ACCOUNT_TEAM,
      RolesEnum.CST,
      RolesEnum.CREDIT_MANAGER,
      RolesEnum.SCHEDULER,
      RolesEnum.SCHEDULER_TEAM_LEAD,
      RolesEnum.CUSTOMER_SUPPORT,
      RolesEnum.SUPPORT_AND_RETENTION,
      RolesEnum.CUSTOMER_SUPPORT_TEAM_LEAD,
      RolesEnum.INSIDE_SALES_TEAM_LEAD,
      // RolesEnum.LENDER,
      RolesEnum.CLOSURE_RETENTION_TEAM,
      RolesEnum.ISR_RETENTION_TEAM_LEAD,
      RolesEnum.TRAINING_MANAGER,
      RolesEnum.COLLECTION_MANAGER,
      RolesEnum.FINANCE_TEAM,
    ],
  },
  tracking: {
    name: "Tracking",
    path: "tracking",
    whitelistedRoles: [
      RolesEnum.ADMIN,
      RolesEnum.FOUNDER,
      RolesEnum.BUSINESS,
      RolesEnum.SENIOR_OPERATIONS,
      RolesEnum.CREDIT_MANAGER,
      RolesEnum.SCHEDULER,
      RolesEnum.SCHEDULER_TEAM_LEAD,
      RolesEnum.CUSTOMER_SUPPORT,
      RolesEnum.SUPPORT_AND_RETENTION,
      RolesEnum.CUSTOMER_SUPPORT_TEAM_LEAD,
      RolesEnum.INSIDE_SALES_TEAM_LEAD,
      RolesEnum.CITY_HEAD,
      RolesEnum.CLOSURE_RETENTION_TEAM,
      RolesEnum.ISR_RETENTION_TEAM_LEAD,
      RolesEnum.LOAN_MANAGER_LEAD,
      RolesEnum.LOAN_OPS_ADMIN,
    ],
  },
  lmDistance: {
    name: "LM Distance",
    path: "lm-distance",
    whitelistedRoles: [
      RolesEnum.ADMIN,
      RolesEnum.FOUNDER,
      RolesEnum.BUSINESS,
      RolesEnum.SENIOR_OPERATIONS,
      RolesEnum.SCHEDULER,
      RolesEnum.ISR_RETENTION_TEAM_LEAD,
      RolesEnum.SCHEDULER_TEAM_LEAD,
      RolesEnum.LOAN_OPS_ADMIN,
    ],
  },
  mis: {
    name: "Reports",
    path: "reports",
    subFeatures: [
      {
        name: "MIS",
        path: "mis",
        whitelistedRoles: [
          RolesEnum.ADMIN,
          RolesEnum.FOUNDER,
          RolesEnum.BUSINESS,
          RolesEnum.ACCOUNT_TEAM,
        ],
      },
      {
        name: "Shivalik Revenue Recon Report",
        path: "shivalik-revenue-recon-report",
        whitelistedRoles: [
          RolesEnum.ADMIN,
          RolesEnum.FOUNDER,
          RolesEnum.BUSINESS,
          RolesEnum.ACCOUNT_TEAM,
        ],
      },
    ],
    whitelistedRoles: [
      RolesEnum.ADMIN,
      RolesEnum.FOUNDER,
      RolesEnum.BUSINESS,
      RolesEnum.ACCOUNT_TEAM,
    ],
  },
  misLender: {
    name: "MIS LENDER",
    path: "mis-lender",
    whitelistedRoles: [RolesEnum.LENDER],
  },
  leads: {
    name: "Leads",
    path: "leads",
    subFeatures: [
      {
        name: "New Lead",
        path: "new-lead",
        whitelistedRoles: [
          RolesEnum.CST,
          RolesEnum.ADMIN,
          RolesEnum.FOUNDER,
          RolesEnum.BUSINESS,
          RolesEnum.CITY_HEAD,
          RolesEnum.SCHEDULER,
          RolesEnum.SCHEDULER_TEAM_LEAD,
          RolesEnum.ACCOUNT_TEAM,
          RolesEnum.CREDIT_MANAGER,
          RolesEnum.CUSTOMER_SUPPORT,
          RolesEnum.SUPPORT_AND_RETENTION,
          RolesEnum.SENIOR_OPERATIONS,
          RolesEnum.INSIDE_SALES_LOAN,
          RolesEnum.INSIDE_SALES_LOCKER,
          RolesEnum.INSIDE_SALES_AFFILIATE,
          RolesEnum.INSIDE_SALES_TEAM_LEAD,
          RolesEnum.CUSTOMER_SUPPORT_TEAM_LEAD,
          RolesEnum.CLOSURE_RETENTION_TEAM,
          RolesEnum.ISR_RETENTION_TEAM_LEAD,
          RolesEnum.COLLECTION_MANAGER,
          RolesEnum.LOAN_OPS_ADMIN,
        ],
      },
      {
        name: "Bulk Upload",
        path: "bulk-upload",
        whitelistedRoles: [
          RolesEnum.ADMIN,
          RolesEnum.FOUNDER,
          RolesEnum.BUSINESS,
          RolesEnum.LENDER,
          RolesEnum.SENIOR_OPERATIONS,
          RolesEnum.INSIDE_SALES_AFFILIATE,
          RolesEnum.CUSTOMER_SUPPORT_TEAM_LEAD,
          RolesEnum.INSIDE_SALES_TEAM_LEAD,
          RolesEnum.ISR_RETENTION_TEAM_LEAD,
          RolesEnum.LOAN_OPS_ADMIN,
        ],
      },
    ],
    whitelistedRoles: [
      RolesEnum.CST,
      RolesEnum.ADMIN,
      RolesEnum.LENDER,
      RolesEnum.FOUNDER,
      RolesEnum.BUSINESS,
      RolesEnum.SCHEDULER,
      RolesEnum.SCHEDULER_TEAM_LEAD,
      RolesEnum.CITY_HEAD,
      RolesEnum.ACCOUNT_TEAM,
      RolesEnum.CREDIT_MANAGER,
      RolesEnum.CUSTOMER_SUPPORT,
      RolesEnum.SUPPORT_AND_RETENTION,
      RolesEnum.INSIDE_SALES_LOAN,
      RolesEnum.INSIDE_SALES_LOCKER,
      RolesEnum.INSIDE_SALES_AFFILIATE,
      RolesEnum.INSIDE_SALES_TEAM_LEAD,
      RolesEnum.SENIOR_OPERATIONS,
      RolesEnum.CUSTOMER_SUPPORT_TEAM_LEAD,
      RolesEnum.CLOSURE_RETENTION_TEAM,
      RolesEnum.ISR_RETENTION_TEAM_LEAD,
      RolesEnum.COLLECTION_MANAGER,
      RolesEnum.LOAN_OPS_ADMIN,
    ],
  },

  blacklistRemoval: {
    name: "Blacklist-Removal",
    path: "blacklist-removal",
    whitelistedRoles: [RolesEnum.ADMIN, RolesEnum.SENIOR_OPERATIONS],
  },
  serviceDesk: {
    name: "Service Desk",
    path: "service-desk",
    dynamicSubMenu: true,
    subFeatures: [
      {
        name: "Loan Services",
        path: "loan-services",
        whitelistedRoles: [
          RolesEnum.ADMIN,
          RolesEnum.FOUNDER,
          RolesEnum.BUSINESS,
          RolesEnum.CREDIT_MANAGER,
          RolesEnum.CUSTOMER_SUPPORT,
          RolesEnum.SUPPORT_AND_RETENTION,
          RolesEnum.SENIOR_OPERATIONS,
          RolesEnum.CUSTOMER_SUPPORT_TEAM_LEAD,
          RolesEnum.CST,
          RolesEnum.CITY_HEAD,
          RolesEnum.CLOSURE_RETENTION_TEAM,
          RolesEnum.ISR_RETENTION_TEAM_LEAD,
          RolesEnum.COLLECTION_MANAGER,
        ],
      },
      {
        name: "Complaints",
        path: "complaints",
        whitelistedRoles: [
          RolesEnum.ADMIN,
          RolesEnum.FOUNDER,
          RolesEnum.BUSINESS,
          RolesEnum.CREDIT_MANAGER,
          RolesEnum.CUSTOMER_SUPPORT,
          RolesEnum.SUPPORT_AND_RETENTION,
          RolesEnum.SENIOR_OPERATIONS,
          RolesEnum.CUSTOMER_SUPPORT_TEAM_LEAD,
          RolesEnum.CST,
          RolesEnum.CITY_HEAD,
          RolesEnum.CLOSURE_RETENTION_TEAM,
          RolesEnum.ISR_RETENTION_TEAM_LEAD,
          RolesEnum.COLLECTION_MANAGER,
        ],
      },
      {
        name: "Agent Availability",
        path: "agent-availability",
        whitelistedRoles: [
          RolesEnum.ADMIN,
          RolesEnum.FOUNDER,
          RolesEnum.BUSINESS,
          RolesEnum.CUSTOMER_SUPPORT_TEAM_LEAD,
          RolesEnum.CITY_HEAD,
          RolesEnum.ISR_RETENTION_TEAM_LEAD,
        ],
      },
      {
        name: "NPA Cases",
        path: "npa-cases",
        whitelistedRoles: [
          RolesEnum.ADMIN,
          RolesEnum.FOUNDER,
          RolesEnum.BUSINESS,
        ],
      },
    ],
    whitelistedRoles: [
      RolesEnum.ADMIN,
      RolesEnum.FOUNDER,
      RolesEnum.BUSINESS,
      RolesEnum.CREDIT_MANAGER,
      RolesEnum.CUSTOMER_SUPPORT,
      RolesEnum.SUPPORT_AND_RETENTION,
      RolesEnum.SENIOR_OPERATIONS,
      RolesEnum.CUSTOMER_SUPPORT_TEAM_LEAD,
      RolesEnum.CST,
      RolesEnum.CITY_HEAD,
      RolesEnum.CLOSURE_RETENTION_TEAM,
      RolesEnum.ISR_RETENTION_TEAM_LEAD,
      RolesEnum.COLLECTION_MANAGER,
    ],
  },
  activeLockerJobs: {
    name: "Active Locker Jobs",
    path: "active-locker-jobs",
    whitelistedRoles: [
      RolesEnum.ADMIN,
      RolesEnum.FOUNDER,
      RolesEnum.BUSINESS,
      RolesEnum.SCHEDULER,
      RolesEnum.ISR_RETENTION_TEAM_LEAD,
      RolesEnum.SCHEDULER_TEAM_LEAD,
      RolesEnum.INSIDE_SALES_LOCKER,
    ],
  },
  analytics: {
    name: "Analytics",
    path: "analytics",
    subFeatures: [],
    dynamicSubMenu: true,
    whitelistedRoles: [
      RolesEnum.ADMIN,
      RolesEnum.FOUNDER,
      RolesEnum.BUSINESS,
      RolesEnum.SENIOR_OPERATIONS,
      RolesEnum.CUSTOMER_SUPPORT_TEAM_LEAD,
      RolesEnum.CREDIT_MANAGER,
      RolesEnum.SCHEDULER_TEAM_LEAD,
      RolesEnum.INSIDE_SALES_LOAN,
      RolesEnum.INSIDE_SALES_TEAM_LEAD,
      RolesEnum.ACCOUNT_TEAM,
      RolesEnum.AUDIT_MANAGER_HEAD,
      RolesEnum.SCHEDULER,
      RolesEnum.ISR_RETENTION_TEAM_LEAD,
      RolesEnum.CITY_HEAD,
      RolesEnum.CLOSURE_RETENTION_TEAM,
      RolesEnum.ISR_RETENTION_TEAM_LEAD,
      RolesEnum.TRAINING_MANAGER,
      RolesEnum.INSIDE_SALES_AFFILIATE,
      RolesEnum.COLLECTION_MANAGER,
    ],
  },
  lockerReleaseRequest: {
    name: "Locker Release Request",
    path: "locker-release-request",
    whitelistedRoles: [
      RolesEnum.ADMIN,
      RolesEnum.FOUNDER,
      RolesEnum.BUSINESS,
      RolesEnum.SCHEDULER,
      RolesEnum.ISR_RETENTION_TEAM_LEAD,
      RolesEnum.SCHEDULER_TEAM_LEAD,
      RolesEnum.INSIDE_SALES_LOCKER,
      RolesEnum.CUSTOMER_SUPPORT_TEAM_LEAD,
    ],
  },
  partnerLeadDashboard: {
    name: "Partner Lead Dashboard",
    path: "lead",
    subFeatures: [],
    whitelistedRoles: [RolesEnum.LEAD_AFFILIATES],
  },
  partnerAnalytics: {
    name: "Partner Analytics",
    path: "analytics-section",
    subFeatures: [],
    whitelistedRoles: [RolesEnum.LEAD_AFFILIATES],
  },

  utilities: {
    name: "Utilities",
    path: "utilities",
    subFeatures: [
      {
        name: "Gold Rate",
        path: "gold-rate",
        whitelistedRoles: [RolesEnum.ADMIN, RolesEnum.CREDIT_MANAGER],
      },
      {
        name: "Recon",
        path: "recon",
        whitelistedRoles: [RolesEnum.ADMIN, RolesEnum.ACCOUNT_TEAM],
      },
      {
        name: "Collateral Movement",
        path: "collateral-movement",
        whitelistedRoles: [
          RolesEnum.ADMIN,
          RolesEnum.SCHEDULER_TEAM_LEAD,
          RolesEnum.SENIOR_OPERATIONS,
        ],
      },

      {
        name: "Reviews",
        path: "reviews",
        whitelistedRoles: [RolesEnum.ADMIN],
      },

      {
        name: "Dsa Mapping",
        path: "dsa-mapping",
        whitelistedRoles: [RolesEnum.ADMIN, RolesEnum.LEAD_OPS],
      },
    ],
    whitelistedRoles: [
      RolesEnum.ADMIN,
      RolesEnum.CREDIT_MANAGER,
      RolesEnum.ACCOUNT_TEAM,
      RolesEnum.SCHEDULER_TEAM_LEAD,
      RolesEnum.SENIOR_OPERATIONS,
      RolesEnum.LEAD_OPS,
    ],
  },
  loanManagement: {
    name: "Loan Management",
    path: "loan-management",
    subFeatures: [
      {
        name: "Disbursed Loans",
        path: "disbursed-loans",
        whitelistedRoles: [
          RolesEnum.ADMIN,
          RolesEnum.LENDER,
          RolesEnum.LENDER_SHIVALIK_ADMIN,
          RolesEnum.LENDER_PIRAMAL_ADMIN,
          RolesEnum.LENDER_FINCARE_ADMIN,
          RolesEnum.LENDER_SHIVALIK_READ_ONLY,
          RolesEnum.LENDER_PIRAMAL_READ_ONLY,
        ],
      },
      {
        name: "Gold Handover",
        path: "gold-handover",
        whitelistedRoles: [
          RolesEnum.ADMIN,
          RolesEnum.LENDER,
          RolesEnum.LENDER_SHIVALIK_ADMIN,
          RolesEnum.LENDER_PIRAMAL_ADMIN,
          RolesEnum.LENDER_FINCARE_ADMIN,
          RolesEnum.LENDER_SHIVALIK_READ_ONLY,
          RolesEnum.LENDER_PIRAMAL_READ_ONLY,
        ],
      },
      {
        name: "Closure",
        path: "closure",
        whitelistedRoles: [
          RolesEnum.ADMIN,
          RolesEnum.LENDER,
          RolesEnum.LENDER_SHIVALIK_ADMIN,
          RolesEnum.LENDER_PIRAMAL_ADMIN,
          RolesEnum.LENDER_FINCARE_ADMIN,
          RolesEnum.LENDER_SHIVALIK_READ_ONLY,
          RolesEnum.LENDER_PIRAMAL_READ_ONLY,
        ],
      },
      {
        name: "Auctions",
        path: "auctions",
        whitelistedRoles: [
          RolesEnum.ADMIN,
          RolesEnum.FOUNDER,
          RolesEnum.LENDER,
          RolesEnum.LENDER_SHIVALIK_ADMIN,
          RolesEnum.LENDER_PIRAMAL_ADMIN,
          RolesEnum.LENDER_FINCARE_ADMIN,
          RolesEnum.LENDER_SHIVALIK_READ_ONLY,
          RolesEnum.LENDER_PIRAMAL_READ_ONLY,
        ],
      },
      {
        name: "Loan Sanctioning",
        path: "loan-sanctioning",
        whitelistedRoles: [
          RolesEnum.ADMIN,
          RolesEnum.LENDER,
          RolesEnum.LENDER_SHIVALIK_ADMIN,
          RolesEnum.LENDER_PIRAMAL_ADMIN,
          RolesEnum.LENDER_FINCARE_ADMIN,
          RolesEnum.LENDER_SHIVALIK_READ_ONLY,
          RolesEnum.LENDER_PIRAMAL_READ_ONLY,
        ],
      },
    ],
    whitelistedRoles: [
      RolesEnum.ADMIN,
      RolesEnum.LENDER,
      RolesEnum.LENDER_SHIVALIK_ADMIN,
      RolesEnum.LENDER_PIRAMAL_ADMIN,
      RolesEnum.LENDER_FINCARE_ADMIN,
      RolesEnum.LENDER_SHIVALIK_READ_ONLY,
      RolesEnum.LENDER_PIRAMAL_READ_ONLY,
    ],
  },
  customerAnalytics: {
    name: "Customer Analytics",
    path: "customer-analytics",
    subFeatures: [],
    dynamicSubMenu: true,
    whitelistedRoles: [
      RolesEnum.ADMIN,
      RolesEnum.LENDER,
      RolesEnum.LENDER_SHIVALIK_ADMIN,
      RolesEnum.LENDER_SHIVALIK_READ_ONLY,
      RolesEnum.LENDER_PIRAMAL_READ_ONLY,
    ],
  },
  closure: {
    name: "Closure",
    path: "closure",
    subFeatures: [],
    whitelistedRoles: [RolesEnum.ADMIN],
  },
};

export {allFeatures};
