import React, {useEffect} from "react";
import {Box, Grid, Typography} from "@mui/material";
import GuarantorDocView from "../GuarantorDocView";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import {
  personalDetailsActions,
  personalDetailsSelectors,
} from "app/store/loanRenewalRebook/personalDetails";

interface GuarantorSupplementDocumentsProps {
  crId: number;
}
const GuarantorSupplementDocuments = ({
  crId,
}: GuarantorSupplementDocumentsProps) => {
  const dispatch = useAppDispatch();
  const guarantorDocuments = useAppSelector(
    personalDetailsSelectors.getKycDocuments,
  );

  return (
    <Box
      bgcolor="#fff"
      borderRadius="4px"
      width="70%"
      border="1px solid rgba(0, 0, 0, 0.12)"
    >
      <Grid container spacing={2} p={2}>
        {guarantorDocuments
          .filter(
            (i) =>
              i.documentType !== "GUARANTOR_AADHAAR" &&
              i.documentType !== "GUARANTOR_PAN",
          )
          .map((doc) => (
            <Grid item xs={6}>
              <Typography variant="body1" fontWeight={600} lineHeight="48px">
                {doc?.documentType.replaceAll("_", " ")}
              </Typography>
              <GuarantorDocView guarantorDocument={doc} />
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

export default GuarantorSupplementDocuments;
