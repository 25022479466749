import React, {useState, useEffect, useMemo} from "react";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import {IGSelect, IGDialog, IGTextField} from "app/components";
import {Stack} from "@mui/material";
import {toggleModals} from "app/store/activeLoans/activeLoans.reducer";
import {activeLoansActions, activeLoansSelectors} from "app/store/activeLoans";
import {isNil} from "lodash";
import {IGLoading} from "app/components";

const AskLmForAdditionalDetail = ({
  open,
  crJobId,
  crId,
  getMileStoneOfCr,
  getNextJobApi,
  sendBackSource,
  crJobStatus,
}) => {
  const dispatch = useAppDispatch();
  const sendBackConfig = useAppSelector(
    activeLoansSelectors.getSendBackCrConfig,
  );
  const {
    sendBackCrConfig: sendBackCrConfigLoading,
    sendBackCr: sendBackCrLoading,
  } = useAppSelector(
    activeLoansSelectors.getLoading,
  );
  const [remark, setRemark] = useState("");
  const [sendBackCategoryId, setSendBackCategoryId] = useState(null);
  const [sendBackSubCategoryId, setSendBackSubCategoryId] = useState(null);
  const {
    sendBackCategoryConfigList,
    sendBackSubCategoryConfigList,
  } = sendBackConfig ?? {};

  const filteredSendBackSubCategoryConfigList = useMemo(() => {
    return sendBackSubCategoryConfigList.filter(
      (list) => list.sendBackCategoryId === sendBackCategoryId,
    );
  }, [sendBackCategoryId, sendBackSubCategoryConfigList]);

  const onClose = () => {
    dispatch(toggleModals({
      type: "askLmForAdditionalDetail",
      value: false,
    }));
  };

  const onConfirm = () => {
    dispatch(activeLoansActions.sendBackCr({
      crId:crId,
      crJobId: crJobId,
      categoryId: sendBackCategoryId,
      subCategoryId: sendBackSubCategoryId,
      remark: remark,
      source: sendBackSource,
      crJobStatus,
    }));

    getMileStoneOfCr();
    getNextJobApi();
  };

  useEffect(() => {
    dispatch(activeLoansActions.getSendBackCrConfig());
  }, [dispatch]);

  return (
    <IGDialog
      open={open}
      onClose={onClose}
      onCancel={onClose}
      title="Request LM for additional details"
      confirmProps={{
        text: "NOTIFY LOAN MANAGER",
        disabled: isNil(sendBackCategoryId)
         || isNil(sendBackSubCategoryId)
         || !remark,
        loading: sendBackCrConfigLoading || sendBackCrLoading,
      }}
      onConfirm={onConfirm}
      subtitle="Please provide more details to be shared with LM."
    >
      {(sendBackCrConfigLoading || sendBackCrLoading) ?
        <IGLoading height={"10vh"}/>
      :(
      <Stack spacing={2}>
        <IGSelect
          required
          name="rejectionCategory"
          label="Select issue"
          options={sendBackCategoryConfigList.map((l) => ({
            value: l.id,
            text: l.displayValue,
          }))}
          onChange={(event) =>
            setSendBackCategoryId(event.target.value)
          }
        />
        <IGSelect
          required
          name="rejectionSubCategory"
          label="Please select send back reason"
          options={filteredSendBackSubCategoryConfigList.map((l) => ({
            value: l.id,
            text: l.displayValue,
          }))}
          onChange={(event) =>
            setSendBackSubCategoryId(event.target.value)
          }
        />
        <IGTextField
          multiline
          required
          rows="5"
          cols="37"
          placeholder="Remarks"
          label="Remarks"
          value={remark}
          onChange={event => setRemark(event.target.value)}
          inputProps={{
            maxLength: 1200,
          }}
        />
      </Stack>
      )}
    </IGDialog>
  );
};

export default AskLmForAdditionalDetail;
