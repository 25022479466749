/* eslint-disable max-len */
import React, {Suspense} from "react";
import {Outlet} from "react-router-dom";

import EMIOrder from "./Icons/EMI";
import DGOrder from "./Icons/DigitalGold";
import UsersIcons, {
  DeviceBanned,
  UserBanned,
  UserInternal,
  UserInternalAll,
  UserWhitelisted,
} from "./Icons/User";
import PaymentIcons from "./Icons/Payment";
import PromotionsIcon from "./Icons/Promotion";
import CSVIcon from "./Icons/CSV";
import SupportIcon from "./Icons/Support";
import SchedulingIcons from "./Icons/Scheduling";
import ActiveLoanIcons from "./Icons/ActiveLoan";
import AccountsIcons from "./Icons/Accounts";
import AnalyticsIcon from "./Icons/Analytics";
import TrackingIcons from "./Icons/Tracking";
import LeadIcon from "./Icons/Lead";
import GiftCardConfigIcon from "./Icons/GiftCardConfig";
import UserTierConfigIcon from "./Icons/UserTierConfig";
import CustomerProfilesIcon from "./Icons/CustomerProfiles";
import LmDistanceIcon from "./Icons/LmDistance";
import MisIcon from "./Icons/Mis";
import ServiceDeskIcon from "./Icons/ServiceDesk";
import LockerReleaseRequestIcon from "./Icons/LockerReleaseRequest";
import ReferralIcon from "./Icons/Referral";
import BlackListManagementIcon from "./Icons/BlackListManagement";

import Loading from "app/components/Loading";
import ROUTES from "app/constants/routes";
import {MenuComponent} from "../models/product";
import lazyWithRetry from "./lazyWithRetry";
import {DashboardRounded, ViewKanbanRounded} from "@mui/icons-material";
import UtilitiesIcon from "./Icons/Utilities";
import BlackListRemovalIcon from "./Icons/BlackListRemoval";

const {EMI_ORDERS, DG_ORDER, GIFTCARD_CONFIG, USER_TIER_CONFIG} = ROUTES;

// Lazy loading of features
const EmiOrdersWrapper = lazyWithRetry(() =>
  import("app/pages/home/EmiOrder/EmiOrders"),
);
const DgOrdersWrapper = lazyWithRetry(() =>
  import("app/pages/home/DgOrder/DgOrders"),
);
const GiftCardWrapper = lazyWithRetry(() => import("app/pages/home/GiftCard"));
const UsersWrapper = lazyWithRetry(() => import("app/pages/home/User/Users"));
const BannedSellUsersWrapper = lazyWithRetry(() =>
  import("app/pages/home/User/BannedSellUsers"),
);
const BannedDevicesWrapper = lazyWithRetry(() =>
  import("app/pages/home/User/BannedDevices"),
);
const PayoutsWrapper = lazyWithRetry(() =>
  import("app/pages/home/Payment/Payouts"),
);
const PaymentsWrapper = lazyWithRetry(() =>
  import("app/pages/home/Payment/Payments"),
);
const InternalUsersWrapper = lazyWithRetry(() =>
  import("app/pages/home/InternalUser"),
);
const ReleaseWrapper = lazyWithRetry(() =>
  import("app/pages/home/Locker/Release"),
);
const CreateInternalUserWrapper = lazyWithRetry(() =>
  import("app/pages/home/InternalUser/CreateInternalUser"),
);
const AllPromosWrapper = lazyWithRetry(() =>
  import("app/pages/home/Promo/AllPromos"),
);
const SDComplaintsWrapper = lazyWithRetry(() =>
  import("app/pages/home/GoldLoan/ServiceDesk/Complaints"),
);
const SDServicesWrapper = lazyWithRetry(() =>
  import("app/pages/home/GoldLoan/ServiceDesk/Services"),
);
const SDManageRenewRebookWrapper = lazyWithRetry(() =>
  import(
    "app/pages/home/GoldLoan/ServiceDesk/Services/ManageRenewRebookRequest"
  ),
);
const SDCloseAndRenewWrapper = lazyWithRetry(() =>
  import(
    "app/pages/home/GoldLoan/ServiceDesk/Services/ManageRenewRebookRequest/CloseAndRenew"
  ),
);
const SDManageRateChangeWrapper = lazyWithRetry(() =>
  import("app/pages/home/GoldLoan/ServiceDesk/Services/ManageRateChangeRequest"),
);
const SDProcessRequestWrapper = lazyWithRetry(() =>
  import("app/pages/home/GoldLoan/ServiceDesk/Services/ProcessRequest"),
);
const SDJumperRemovalWrapper = lazyWithRetry(() =>
  import("app/pages/home/GoldLoan/ServiceDesk/Complaints/JumperRemoval"),
);
const WhitelistUsersWrapper = lazyWithRetry(() =>
  import("app/pages/home/User/WhitelistUsers"),
);
const UserTierConfigWrapper = lazyWithRetry(() =>
  import("app/pages/home/GoldLoan/UserTierConfig"),
);
const PaymentReportWrapper = lazyWithRetry(() =>
  import("app/pages/home/Payment/Report"),
);
const DynamicLinkWrapper = lazyWithRetry(() =>
  import("app/pages/home/Promotion/DynamicLinks"),
);
const CSVWrapper = lazyWithRetry(() =>
  import("app/pages/home/Csv/UploadDownload"),
);
const SupportWrapper = lazyWithRetry(() =>
  import("app/pages/home/Support/DeepLink"),
);
const ActiveLoanWrapper = lazyWithRetry(() =>
  import("app/pages/home/GoldLoan/ActiveLoan"),
);
const CustomerProfilesWrapper = lazyWithRetry(() =>
  import("app/pages/home/GoldLoan/CustomerProfiles"),
);
const LmDistanceCSVWrapper = lazyWithRetry(() =>
  import("app/pages/home/LMDistanceCSV"),
);
const MISWrapper = lazyWithRetry(() =>
  import("app/pages/home/GoldLoan/Reports/MIS"),
);
const ShivalikRevenueReconWrapper = lazyWithRetry(() =>
  import("app/pages/home/GoldLoan/Reports/ShivalikRevenueRecon"),
);
const AccountsWrapper = lazyWithRetry(() =>
  import("app/pages/home/GoldLoan/Accounts"),
);
const TrackingWrapper = lazyWithRetry(() =>
  import("app/pages/home/GoldLoan/Tracking"),
);
const DynamicAnalyticsWrapper = lazyWithRetry(() =>
  import("app/pages/home/Support/DynamicAnalyticsComponent"),
);
const ResourceViewWrapper = lazyWithRetry(() =>
  import("app/pages/home/GoldLoan/Scheduling/ResourceView"),
);
const JobViewWrapper = lazyWithRetry(() =>
  import("app/pages/home/GoldLoan/Scheduling/JobView"),
);
const LmAvailabilityWrapper = lazyWithRetry(() =>
  import("app/pages/home/GoldLoan/Scheduling/LMAvailability"),
);
const NewLeadWrapper = lazyWithRetry(() =>
  import("app/pages/home/GoldLoan/Lead/NewLead"),
);
const BulkUploadWrapper = lazyWithRetry(() =>
  import("app/pages/home/GoldLoan/Lead/BulkUpload"),
);
const RefferalWrapper = lazyWithRetry(() => import("app/pages/home/Refferal"));
const MISLenderWrapper = lazyWithRetry(() =>
  import("app/pages/home/GoldLoan/MISLender"),
);
const SDAgentAvailablityWrapper = lazyWithRetry(() =>
  import("app/pages/home/GoldLoan/ServiceDesk/AgentAvailability"),
);
const PartnerDashboardLeadWrapper = lazyWithRetry(() =>
  import("app/pages/home/PartnerDashboard/lead"),
);
const PartnerAnalyticsWrapper = lazyWithRetry(() =>
  import("app/pages/home/PartnerDashboard/analytics"),
);
const BlackListManagementWrapper = lazyWithRetry(() =>
  import("app/pages/home/GoldLoan/BlackListManagement"),
);
const DisbursedLoansWrapper = lazyWithRetry(() =>
  import("app/pages/home/LenderPortal/DisbursedLoans"),
);
const GoldReleaseWrapper = lazyWithRetry(() =>
  import("app/pages/home/LenderPortal/GoldRelease"),
);
const GoldRateWrapper = lazyWithRetry(() =>
  import("app/pages/home/GoldLoan/Utilities/GoldRate/index"),
);
const LenderCustomerProfilesWrapper = lazyWithRetry(() =>
  import("app/pages/home/LenderPortal/CustomerProfiles"),
);
const ReconWrapper = lazyWithRetry(() =>
  import("app/pages/home/GoldLoan/Utilities/Recon/index"),
);
const AlgoWrapper = lazyWithRetry(() =>
  import("app/pages/home/GoldLoan/Scheduling/JobView/NewLoanBooking/Algo"),
);
const AlgoStartViewWrapper = lazyWithRetry(() =>
  import("app/pages/home/GoldLoan/Scheduling/JobView/NewLoanBooking/Algo/Start"),
);
const AlgoDetailViewWrapper = lazyWithRetry(() =>
  import(
    "app/pages/home/GoldLoan/Scheduling/JobView/NewLoanBooking/Algo/Detail"
  ),
);
const AlgoConfirmViewWrapper = lazyWithRetry(() =>
  import(
    "app/pages/home/GoldLoan/Scheduling/JobView/NewLoanBooking/Algo/Confirm"
  ),
);
const CollateralMovementWrapper = lazyWithRetry(() =>
  import("app/pages/home/GoldLoan/Utilities/CollateralMovement/index"),
);
const LenderClosureJobsWrapper = lazyWithRetry(() =>
  import("app/pages/home/LenderPortal/Closure"),
);
const LenderClosureJobsDetailViewWrapper = lazyWithRetry(() =>
  import("app/pages/home/LenderPortal/Closure/ViewJob"),
);
const GoldReleaseJobsViewWrapper = lazyWithRetry(() =>
  import("app/pages/home/LenderPortal/GoldRelease/ViewJob"),
);
const ReviewsWrapper = lazyWithRetry(() =>
  import("app/pages/home/GoldLoan/Utilities/ReviewsCollection/index"),
);
const NPACasesWrapper = lazyWithRetry(() =>
  import("app/pages/home/LenderPortal/NPACases/index"),
);
const BlackListRemovalWrapper = lazyWithRetry(() =>
  import("app/pages/home/GoldLoan/BlackListRemoval/index"),
);

const DsaMappingWrapper = lazyWithRetry(() =>
  import("app/pages/home/GoldLoan/Utilities/DsaMappingCollection/index"),
);
const LoanSanctioningWrapper = lazyWithRetry(() =>
  import("app/pages/home/LenderPortal/LoanSanctioning/index"),
);

const LeadCreditHistoryWrapper = lazyWithRetry(() =>
  import(
    "app/pages/home/GoldLoan/Scheduling/JobView/LeadCreditHistoryByParentId/index"
  ),
);
const SDManagePartReleaseRequestWrapper = lazyWithRetry(() =>
  import(
    "app/pages/home/GoldLoan/ServiceDesk/Services/ManagePartReleaseRequest"
  ),
);
// Suspense wrappers for Features
export const EmiOrders = (props: any) => (
  <Suspense fallback={<Loading />}>
    <EmiOrdersWrapper {...props} />
  </Suspense>
);
export const DgOrders = (props: any) => (
  <Suspense fallback={<Loading />}>
    <DgOrdersWrapper {...props} />
  </Suspense>
);
export const GiftCard = (props: any) => (
  <Suspense fallback={<Loading />}>
    <GiftCardWrapper {...props} />
  </Suspense>
);
export const Users = (props: any) => (
  <Suspense fallback={<Loading />}>
    <UsersWrapper {...props} />
  </Suspense>
);
export const BannedSellUsers = (props: any) => (
  <Suspense fallback={<Loading />}>
    <BannedSellUsersWrapper {...props} />
  </Suspense>
);
export const BannedDevices = (props: any) => (
  <Suspense fallback={<Loading />}>
    <BannedDevicesWrapper {...props} />
  </Suspense>
);
export const Payouts = (props: any) => (
  <Suspense fallback={<Loading />}>
    <PayoutsWrapper {...props} />
  </Suspense>
);
export const Payments = (props: any) => (
  <Suspense fallback={<Loading />}>
    <PaymentsWrapper {...props} />
  </Suspense>
);
export const InternalUsers = (props: any) => (
  <Suspense fallback={<Loading />}>
    <InternalUsersWrapper {...props} />
  </Suspense>
);
export const Release = (props: any) => (
  <Suspense fallback={<Loading />}>
    <ReleaseWrapper {...props} />
  </Suspense>
);
export const CreateInternalUser = (props: any) => (
  <Suspense fallback={<Loading />}>
    <CreateInternalUserWrapper {...props} />
  </Suspense>
);
export const AllPromos = (props: any) => (
  <Suspense fallback={<Loading />}>
    <AllPromosWrapper {...props} />
  </Suspense>
);
export const SDComplaints = (props: any) => (
  <Suspense fallback={<Loading />}>
    <SDComplaintsWrapper {...props} />
  </Suspense>
);
export const SDServices = (props: any) => (
  <Suspense fallback={<Loading />}>
    <SDServicesWrapper {...props} />
  </Suspense>
);
export const SDAgentAvailablity = () => (
  <Suspense fallback={<Loading />}>
    <SDAgentAvailablityWrapper />
  </Suspense>
);
export const SDManageRenewRebook = (props: any) => (
  <Suspense fallback={<Loading />}>
    <SDManageRenewRebookWrapper {...props} />
  </Suspense>
);
export const SDCloseAndRenew = () => (
  <Suspense fallback={<Loading />}>
    <SDCloseAndRenewWrapper />
  </Suspense>
);
export const SDManageRateChange = () => (
  <Suspense fallback={<Loading />}>
    <SDManageRateChangeWrapper />
  </Suspense>
);
export const SDProcessRequest = () => (
  <Suspense fallback={<Loading />}>
    <SDProcessRequestWrapper />
  </Suspense>
);
export const SDJumperRemoval = () => (
  <Suspense fallback={<Loading />}>
    <SDJumperRemovalWrapper />
  </Suspense>
);
export const WhitelistUsers = (props: any) => (
  <Suspense fallback={<Loading />}>
    <WhitelistUsersWrapper {...props} />
  </Suspense>
);
export const UserTierConfig = (props: any) => (
  <Suspense fallback={<Loading />}>
    <UserTierConfigWrapper {...props} />
  </Suspense>
);
export const DynamicAnalytics = (props: any) => (
  <Suspense fallback={<Loading />}>
    <DynamicAnalyticsWrapper {...props} />
  </Suspense>
);
export const ResourceView = (props: any) => (
  <Suspense fallback={<Loading />}>
    <ResourceViewWrapper {...props} />
  </Suspense>
);
export const JobView = (props: any) => (
  <Suspense fallback={<Loading />}>
    <JobViewWrapper {...props} />
  </Suspense>
);
export const LmAvailability = (props: any) => (
  <Suspense fallback={<Loading />}>
    <LmAvailabilityWrapper {...props} />
  </Suspense>
);
export const NewLead = () => (
  <Suspense fallback={<Loading />}>
    <NewLeadWrapper />
  </Suspense>
);
export const BulkUpload = (props: any) => (
  <Suspense fallback={<Loading />}>
    <BulkUploadWrapper {...props} />
  </Suspense>
);
export const ActiveLoan = (props: any) => (
  <Suspense fallback={<Loading />}>
    <ActiveLoanWrapper {...props} />
  </Suspense>
);
export const Tracking = () => (
  <Suspense fallback={<Loading />}>
    <TrackingWrapper />
  </Suspense>
);
export const Accounts = () => (
  <Suspense fallback={<Loading />}>
    <AccountsWrapper />
  </Suspense>
);
export const Support = (props: any) => (
  <Suspense fallback={<Loading />}>
    <SupportWrapper {...props} />
  </Suspense>
);
export const LMDistanceCSV = () => (
  <Suspense fallback={<Loading />}>
    <LmDistanceCSVWrapper />
  </Suspense>
);
export const MIS = () => (
  <Suspense fallback={<Loading />}>
    <MISWrapper />
  </Suspense>
);
export const ShivalikRevenueRecon = () => (
  <Suspense fallback={<Loading />}>
    <ShivalikRevenueReconWrapper />
  </Suspense>
);
export const CustomerProfiles = () => (
  <Suspense fallback={<Loading />}>
    <CustomerProfilesWrapper />
  </Suspense>
);
export const CSV = (props: any) => (
  <Suspense fallback={<Loading />}>
    <CSVWrapper {...props} />
  </Suspense>
);
export const DynamicLink = (props: any) => (
  <Suspense fallback={<Loading />}>
    <DynamicLinkWrapper {...props} />
  </Suspense>
);
export const PaymentReport = (props: any) => (
  <Suspense fallback={<Loading />}>
    <PaymentReportWrapper {...props} />
  </Suspense>
);
export const Refferal = () => (
  <Suspense fallback={<Loading />}>
    <RefferalWrapper />
  </Suspense>
);
export const MISLender = () => (
  <Suspense fallback={<Loading />}>
    <MISLenderWrapper />
  </Suspense>
);
export const PartnerDashboardLead = () => (
  <Suspense fallback={<Loading />}>
    <PartnerDashboardLeadWrapper />
  </Suspense>
);
export const PartnerAnalytics = () => (
  <Suspense fallback={<Loading />}>
    <PartnerAnalyticsWrapper />
  </Suspense>
);
export const BlackListManagement = () => (
  <Suspense fallback={<Loading />}>
    <BlackListManagementWrapper />
  </Suspense>
);
export const DisbursedLoans = () => (
  <Suspense fallback={<Loading />}>
    <DisbursedLoansWrapper />
  </Suspense>
);
export const GoldRelease = () => (
  <Suspense fallback={<Loading />}>
    <GoldReleaseWrapper />
  </Suspense>
);
export const GoldRate = () => (
  <Suspense fallback={<Loading />}>
    <GoldRateWrapper />
  </Suspense>
);
export const LenderCustomerProfiles = () => (
  <Suspense fallback={<Loading />}>
    <LenderCustomerProfilesWrapper />
  </Suspense>
);
export const Recon = () => (
  <Suspense fallback={<Loading />}>
    <ReconWrapper />
  </Suspense>
);
export const Algo = () => (
  <Suspense fallback={<Loading />}>
    <AlgoWrapper />
  </Suspense>
);
export const AlgoStartView = () => (
  <Suspense fallback={<Loading />}>
    <AlgoStartViewWrapper />
  </Suspense>
);
export const AlgoDetailView = () => (
  <Suspense fallback={<Loading />}>
    <AlgoDetailViewWrapper />
  </Suspense>
);
export const AlgoConfirmView = () => (
  <Suspense fallback={<Loading />}>
    <AlgoConfirmViewWrapper />
  </Suspense>
);
export const CollateralMovement = () => (
  <Suspense fallback={<Loading />}>
    <CollateralMovementWrapper />
  </Suspense>
);
export const LenderClosureJobs = () => (
  <Suspense fallback={<Loading />}>
    <LenderClosureJobsWrapper />
  </Suspense>
);
export const LenderClosureJobsView = () => (
  <Suspense fallback={<Loading />}>
    <LenderClosureJobsDetailViewWrapper />
  </Suspense>
);
export const GoldReleaseJobsView = () => (
  <Suspense fallback={<Loading />}>
    <GoldReleaseJobsViewWrapper />
  </Suspense>
);

export const Reviews = () => (
  <Suspense fallback={<Loading />}>
    <ReviewsWrapper />
  </Suspense>
);

export const BlackListRemoval = () => (
  <Suspense fallback={<Loading />}>
    <BlackListRemovalWrapper />
  </Suspense>
);

export const NPACases = () => (
  <Suspense fallback={<Loading />}>
    <NPACasesWrapper />
  </Suspense>
);
export const LoanSanctioning = () => (
  <Suspense fallback={<Loading />}>
    <LoanSanctioningWrapper />
  </Suspense>
);
export const DsaMapping = () => (
  <Suspense fallback={<Loading />}>
    <DsaMappingWrapper />
  </Suspense>
);

export const LeadCreditHistoryScheduling = () => {
  console.log("hii");
  return (
    <Suspense fallback={<Loading />}>
      <LeadCreditHistoryWrapper />
    </Suspense>
  );
};

export const SDManagePartReleaseRequest = (props: any) => (
  <Suspense fallback={<Loading />}>
    <SDManagePartReleaseRequestWrapper {...props} />
  </Suspense>
);

const FeatureData: MenuComponent[] = [
  {
    name: "EMI Order",
    icon: EMIOrder,
    path: EMI_ORDERS,
    component: () => {
      return <EmiOrders />;
    },
    isMultiMenu: false,
    subMenu: [],
  },
  {
    name: "DG Order",
    icon: DGOrder,
    path: DG_ORDER,
    isMultiMenu: false,
    subMenu: [],
    component: () => {
      return <DgOrders header dialog filters table actions />;
    },
  },
  {
    name: "Gift Card Config",
    icon: GiftCardConfigIcon,
    path: GIFTCARD_CONFIG,
    isMultiMenu: false,
    subMenu: [],
    component: () => {
      return <GiftCard />;
    },
  },
  {
    name: "Users",
    icon: UsersIcons,
    path: "users",
    isMultiMenu: true,
    subMenu: [
      {
        name: "All Users",
        path: "all-users",
        component: () => {
          return <Users header dialog filters table actions />;
        },
        icon: UsersIcons,
      },
      {
        name: "Banned Users",
        icon: UserBanned,
        path: "banned-users",
        component: () => {
          return <BannedSellUsers header table dialog actions />;
        },
      },
      {
        name: "Banned Devices",
        icon: DeviceBanned,
        path: "banned-devices",
        component: () => {
          return <BannedDevices header table dialog actions />;
        },
      },
      {
        name: "Whitelist Users",
        icon: UserWhitelisted,
        path: "whitelist-users",
        component: () => {
          return <WhitelistUsers />;
        },
      },
    ],
    component: () => <Outlet />,
  },
  {
    name: "Payments",
    icon: PaymentIcons,
    path: "payments",
    isMultiMenu: true,
    subMenu: [
      {
        name: "Payments",
        path: "payments",
        component: () => {
          return <Payments header dialog filters table actions />;
        },
      },
      {
        name: "Payouts",
        path: "payouts",
        component: () => {
          return <Payouts header dialog filters table actions />;
        },
      },
      {
        name: "Report",
        path: "report",
        component: () => {
          return <PaymentReport header />;
        },
      },
    ],
    component: () => <Outlet />,
  },
  {
    name: "Promotions",
    icon: PromotionsIcon,
    path: "promotions",
    isMultiMenu: true,
    subMenu: [
      {
        name: "Dynamic Link",
        path: "dynamic-link",
        component: () => {
          return <DynamicLink header dialog filters table actions />;
        },
      },
      {
        name: "Promos",
        path: "promos",
        component: () => {
          return <AllPromos />;
        },
      },
    ],
    component: () => <Outlet />,
  },
  {
    name: "Referral Config",
    icon: ReferralIcon,
    path: "referral-config",
    isMultiMenu: false,
    subMenu: [],
    component: () => {
      return <Refferal />;
    },
  },
  {
    name: "CSV",
    icon: CSVIcon,
    path: "csv",
    isMultiMenu: false,
    subMenu: [],
    component: () => {
      return <CSV header />;
    },
  },
  {
    name: "Internal Users",
    icon: UserInternal,
    path: "internal-users",
    isMultiMenu: true,
    subMenu: [
      {
        name: "All Internal Users",
        path: "all-internal-users",
        icon: UserInternalAll,
        component: () => {
          return <InternalUsers />;
        },
      },
      {
        name: "Create Internal Users",
        path: "create-internal-users",
        icon: UserWhitelisted,
        component: () => {
          return <CreateInternalUser />;
        },
      },
    ],
    component: () => <Outlet />,
  },
  {
    name: "User Tier Config",
    icon: UserTierConfigIcon,
    path: USER_TIER_CONFIG,
    isMultiMenu: false,
    subMenu: [],
    component: () => {
      return <UserTierConfig />;
    },
  },
  {
    name: "Promos",
    icon: PaymentIcons,
    path: "promos",
    isMultiMenu: false,
    subMenu: [],
    component: () => {
      return <AllPromos />;
    },
  },
  {
    name: "Support",
    icon: SupportIcon,
    path: "support",
    isMultiMenu: false,
    subMenu: [],
    component: () => {
      return (
        <>
          <Support header />
        </>
      );
    },
  },
  {
    name: "Scheduling",
    icon: SchedulingIcons,
    path: "scheduling",
    isMultiMenu: true,
    subMenu: [
      {
        name: "Job View",
        path: "job-view",
        component: () => {
          return <JobView />;
        },
      },
      {
        name: "LM Availability",
        path: "lm-availability",
        component: () => {
          return <LmAvailability />;
        },
      },
      {
        name: "Resource View",
        path: "resource-view",
        component: (props: any) => {
          return <ResourceView {...props} />;
        },
      },

      {
        name: "Lead Credit History",
        path: "lead-credit-history",
        component: (props: any) => {
          return <LeadCreditHistoryScheduling {...props} />;
        },
      },
    ],
    component: () => <Outlet />,
  },
  {
    name: "Locker Job Scheduling",
    icon: SchedulingIcons,
    path: "locker-job-scheduling",
    isMultiMenu: false,
    subMenu: [],
    component: (props: any) => {
      return <ResourceView locker {...props} />;
    },
  },
  {
    name: "Active Loans",
    icon: ActiveLoanIcons,
    path: "active-loans",
    isMultiMenu: false,
    subMenu: [],
    component: (props: any) => <ActiveLoan {...props} />,
  },
  {
    name: "Accounts",
    icon: AccountsIcons,
    path: "accounts",
    isMultiMenu: false,
    subMenu: [],
    component: () => {
      return <Accounts />;
    },
  },
  {
    name: "Blacklist",
    icon: BlackListManagementIcon,
    path: "blacklist",
    isMultiMenu: false,
    subMenu: [],
    component: () => {
      return <BlackListManagement />;
    },
  },
  {
    name: "Customer Profiles",
    icon: CustomerProfilesIcon,
    path: "customer-profiles",
    isMultiMenu: false,
    subMenu: [],
    component: () => {
      return <CustomerProfiles />;
    },
  },
  {
    name: "Tracking",
    icon: TrackingIcons,
    path: "tracking",
    isMultiMenu: false,
    subMenu: [],
    component: () => {
      return <Tracking />;
    },
  },
  {
    name: "LM Distance",
    icon: LmDistanceIcon,
    path: "lm-distance",
    isMultiMenu: false,
    subMenu: [],
    component: () => {
      return <LMDistanceCSV />;
    },
  },
  {
    name: "Reports",
    icon: MisIcon,
    path: "reports",
    isMultiMenu: true,
    subMenu: [
      {
        name: "MIS",
        path: "mis",
        component: () => {
          return <MIS />;
        },
      },
      {
        name: "Shivalik Revenue Recon Report",
        path: "shivalik-revenue-recon-report",
        component: () => {
          return <ShivalikRevenueRecon />;
        },
      },
    ],
    component: () => <Outlet />,
  },

  {
    name: "MIS Lender",
    icon: MisIcon,
    path: "mis-lender",
    isMultiMenu: false,
    subMenu: [],
    component: () => {
      return <MISLender />;
    },
  },
  {
    name: "Leads",
    icon: LeadIcon,
    path: "leads",
    isMultiMenu: true,
    subMenu: [
      {
        name: "New Lead",
        path: "new-lead",
        component: () => {
          return <NewLead />;
        },
      },
      {
        name: "Bulk Upload",
        path: "bulk-upload",
        component: () => {
          return <BulkUpload />;
        },
      },
    ],
    component: () => <Outlet />,
  },

  {
    name: "Blacklist Removal",
    icon: BlackListRemovalIcon,
    path: "blacklist-removal",
    isMultiMenu: false,
    subMenu: [],
    component: () => {
      return <BlackListRemoval />;
    },
  },
  {
    name: "Service Desk",
    icon: ServiceDeskIcon,
    path: "service-desk",
    id: "service-desk",
    isMultiMenu: true,
    dynamicSubMenu: true,
    subMenu: [
      {
        name: "Loan Services",
        path: "loan-services",
        component: () => {
          return <SDServicesWrapper />;
        },
      },
      {
        name: "Complaints",
        path: "complaints",
        component: () => {
          return <SDComplaints />;
        },
      },
      {
        name: "Agent Availability",
        path: "agent-availability",
        component: () => {
          return <SDAgentAvailablity />;
        },
      },
      {
        name: "NPA Cases",
        path: "npa-cases",
        component: () => {
          return <NPACases />;
        },
      },
    ],
    component: () => <Outlet />,
    generateSubMenu: (subMenu: any) => {
      return subMenu.map((item: any) => {
        return {
          name: item.name,
          path: item.path.replace("/", ""),
          component: () => (
            <DynamicAnalytics
              key={item.path}
              name={item.name}
              tabs={item.tabs}
            />
          ),
          icon: null,
        };
      });
    },
  },
  {
    name: "Active Locker Jobs",
    icon: ActiveLoanIcons,
    path: "active-locker-jobs",
    isMultiMenu: false,
    subMenu: [],
    component: (props: any) => {
      return <ActiveLoan locker {...props} />;
    },
  },
  {
    name: "Analytics",
    icon: AnalyticsIcon,
    path: "analytics",
    id: "analytics",
    isMultiMenu: false,
    dynamicSubMenu: true,
    subMenu: [],
    component: () => <Outlet />,
    generateSubMenu: (subMenu: any) => {
      return subMenu.map((item: any) => {
        return {
          name: item.name,
          path: item.path.replace("/", ""),
          component: () => (
            <DynamicAnalytics
              key={item.path}
              name={item.name}
              tabs={item.tabs}
            />
          ),
          icon: null,
        };
      });
    },
  },
  {
    name: "Locker Release Request",
    icon: LockerReleaseRequestIcon,
    path: "locker-release-request",
    isMultiMenu: false,
    subMenu: [],
    component: () => {
      return <Release />;
    },
  },
  {
    name: "Partner Lead",
    icon: ViewKanbanRounded,
    path: "lead",
    isMultiMenu: false,
    subMenu: [],
    component: () => <PartnerDashboardLead />,
  },
  {
    name: "Partner Analytics",
    icon: DashboardRounded,
    path: "analytics-section",
    isMultiMenu: false,
    subMenu: [],
    component: () => <PartnerAnalytics />,
  },
  {
    name: "Utilities",
    icon: UtilitiesIcon,
    path: "utilities",
    isMultiMenu: true,
    subMenu: [
      {
        name: "Gold Rate",
        path: "gold-rate",
        component: () => {
          return <GoldRate />;
        },
      },
      {
        name: "Recon",
        path: "recon",
        component: () => {
          return <Recon />;
        },
      },
      {
        name: "Collateral Movement",
        path: "collateral-movement",
        component: () => {
          return <CollateralMovement />;
        },
      },

      {
        name: "Reviews",
        path: "reviews",
        component: () => {
          return <Reviews />;
        },
      },
      {
        name: "Dsa Mapping",
        path: "dsa-mapping",
        component: () => {
          return <DsaMapping />;
        },
      },
    ],
    component: () => <Outlet />,
  },
  {
    name: "Loan Management",
    icon: ActiveLoanIcons,
    enum: "LOAN_MANAGEMENT",
    path: "loan-management",
    isMultiMenu: true,
    subMenu: [
      {
        name: "Loan Sanctioning",
        path: "loan-sanctioning",
        component: () => {
          return <LoanSanctioning />;
        },
      },
      {
        name: "Disbursed Loans",
        path: "disbursed-loans",
        enum: "DISBURSED_LOANS",
        component: () => {
          return <DisbursedLoans />;
        },
      },
      {
        name: "Closure",
        path: "closure",
        enum: "CLOSURE",
        component: () => {
          return <LenderClosureJobs />;
        },
      },
      {
        name: "Gold Handover",
        path: "gold-handover",
        enum: "GOLD_HANDOVER",
        component: () => {
          return <GoldRelease />;
        },
      },
      {
        name: "NPA Cases",
        path: "npa-cases",
        component: () => {
          return <NPACases />;
        },
      },
    ],
    component: () => <Outlet />,
  },
  {
    name: "Customer Details",
    icon: CustomerProfilesIcon,
    path: "customer-details",
    enum: "CUSTOMER_DETAILS",
    isMultiMenu: true,
    subMenu: [
      {
        name: "Customer Profile",
        path: "customer-profile",
        enum: "CUSTOMER_PROFILE",
        component: () => {
          return <LenderCustomerProfiles />;
        },
      },
      // {
      //   name: "Communications",
      //   path: "communication-history",
      //   enum: "COMMUNICATION_HISTORY",
      //   component: () => {
      //     return <></>;
      //   },
      // },
    ],
    component: () => <Outlet />,
  },
  {
    name: "Customer Analytics",
    icon: AnalyticsIcon,
    path: "customer-analytics",
    id: "customerAnalytics",
    isMultiMenu: false,
    dynamicSubMenu: true,
    subMenu: [],
    component: () => <Outlet />,
    generateSubMenu: (subMenu: any) => {
      return subMenu.map((item: any) => {
        return {
          name: item.name,
          path: item.path.replace("/", ""),
          component: () => (
            <DynamicAnalytics
              key={item.path}
              name={item.name}
              tabs={item.tabs}
            />
          ),
          icon: null,
        };
      });
    },
  },
  // {
  //   name: "MIS",
  //   path: "mis-dashboard",
  //   enum: "MIS_DASHBOARD",
  //   isMultiMenu: false,
  //   subMenu: [],
  //   component: () => {
  //     return <MISLender />;
  //   },
  // },
  // {
  //   name: "Leads",
  //   path: "leads-dashboard",
  //   enum: "LEADS_DASHBOARD",
  //   isMultiMenu: true,
  //   subMenu: [
  //     {
  //       name: "Bulk Upload",
  //       path: "lead-bulk-upload",
  //       enum: "LEAD_BULK_UPLOAD",
  //       component: () => {
  //         return <BulkUpload />;
  //       },
  //     },
  //   ],
  //   component: () => <Outlet />,
  // },
];

export {FeatureData};
