import React, {FC, useState, useEffect, useCallback, useRef} from "react";
import {Box, IconButton, Typography} from "@mui/material";
import styled from "@emotion/styled";
import {ChevronLeftRounded, ChevronRightRounded} from "@mui/icons-material";
import SwipeableViews from "react-swipeable-views";
import IGLoading from "../Loading";
import ImageComponent from "../Image";

interface IGCarouselProps {
  showHeaderName?: boolean;
  showThumbnailPreview?: boolean;
  loading?: boolean;
  imageList: {
    title?: string;
    url: string;
  }[];
  orientation?: "horizontal" | "vertical";
  maxImageHeight?: string;
  maxImageWidth?: string;
  containerHeight?: string;
}

const StyledBox = styled(Box)`
  &.active {
    border: 2px solid rgba(0, 0, 0, 0.87);
    box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2),
      0px 16px 24px 2px rgba(0, 0, 0, 0.14),
      0px 6px 30px 5px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
  }
`;

const ThumbnailPreview = ({children, ...props}: any) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (props.className === "active") {
      ref.current?.scrollIntoView({behavior: "smooth", block: "nearest", inline: "start"});
    }
  }, [props.className]);

  return (
    <StyledBox ref={ref} {...props}>
      {children}
    </StyledBox>
  );
};

const IGCarousel: FC<IGCarouselProps> = ({
  showHeaderName = false,
  showThumbnailPreview,
  imageList,
  loading,
  orientation = "horizontal",
  maxImageHeight = "100%",
  maxImageWidth = "100%",
  containerHeight = "100%",
}) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const isVertical = orientation === "vertical";

  const updateIndex = useCallback(
    (newIndex: number) => {
      if (newIndex < 0) {
        newIndex = imageList.length - 1;
      } else if (newIndex >= imageList.length) {
        newIndex = 0;
      }
      setActiveIndex(newIndex);
    },
    [imageList.length],
  );

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "ArrowLeft") {
        // Move the carousel backward
        updateIndex(activeIndex - 1);
      } else if (e.key === "ArrowRight") {
        // Move the carousel forward
        updateIndex(activeIndex + 1);
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [activeIndex, updateIndex]);

  return (
    <>
      <Box
        position="relative"
        sx={{
          height: isVertical ? containerHeight : "auto",
        }}
      >
        {loading && (
          <>
            <IGLoading height="10vh" />
          </>
        )}
        {!loading && (
          <Box
            sx={{
              display: "flex",
              flexDirection: isVertical ? "row" : "column",
              justifyContent: "flex-end",
              gap: "1rem",
              position: "relative",
            }}
          >
            <Box>
              {imageList.length > 0 && (
                <SwipeableViews
                  index={activeIndex}
                  onChangeIndex={(index: number) => setActiveIndex(index)}
                  style={{
                    maxWidth: "500px",
                  }}
                >
                  {imageList.map((item, index) => (
                    <Box
                      position="relative"
                      id={`slide_${index}`}
                      key={`slide_${index}`}
                    >
                      {showHeaderName && (
                        <Typography
                          my={0.5}
                          align="center"
                          fontWeight="bold"
                          variant="h5"
                        >
                          {item?.title}
                        </Typography>
                      )}
                      <ImageComponent
                        src={item.url}
                        height={maxImageHeight}
                        width={maxImageWidth}
                        zoom
                        rotate={false}
                      />
                      {/* <img
                        src={item.url}
                        style={{
                          maxHeight: maxImageHeight,
                          objectFit: "contain",
                          maxWidth: maxImageWidth,
                          height: "100%",
                          width: "100%",
                        }}
                        alt={item.title || ""}
                      /> */}
                      {!showHeaderName && item.title && (
                        <Typography p={1} variant="body1" align="center">
                          {item.title}
                        </Typography>
                      )}
                    </Box>
                  ))}
                </SwipeableViews>
              )}
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                m={1}
              >
                <IconButton onClick={() => updateIndex(activeIndex - 1)}>
                  <ChevronLeftRounded />
                </IconButton>
                <Typography color="gray">{`${activeIndex + 1} of ${
                  imageList.length
                }`}</Typography>
                <IconButton onClick={() => updateIndex(activeIndex + 1)}>
                  <ChevronRightRounded />
                </IconButton>
              </Box>
            </Box>
            {showThumbnailPreview && (
              <Box
                p={2}
                sx={{
                  overflowX: "scroll",
                  overflowY: "scroll",
                  height: isVertical ? containerHeight : "auto",
                }}
                bgcolor="#F5F5F5"
                borderRadius="10px"
                display="flex"
                flexDirection={isVertical ? "column" : "row"}
                gap="1rem"
                minWidth={isVertical ? "100px" : "auto"}
              >
                {imageList.map((item, index) => (
                  <ThumbnailPreview
                    className={index === activeIndex ? "active" : ""}
                    key={`thumbnail_${index}`}
                    onClick={() => setActiveIndex(index)}
                  >
                    <img src={item.url} width="100%" height="100px" />
                  </ThumbnailPreview>
                ))}
              </Box>
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

export default IGCarousel;
