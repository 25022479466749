import {call, getContext, put, takeLatest} from "redux-saga/effects";
import {addressActionTypes} from "./address.actions";
import {PayloadAction} from "@reduxjs/toolkit";
import {UserIdPayload} from "./address.types";
import {GoldApi} from "../../infra/services/api";
import {addressActions} from "./index";
import {LoanIdPayload} from "../loan/loan.types";

const {
  getAddressListSuccess,
  getAddressListFailure,
  getLenderAddressListSuccess,
  getLenderAddressListFailure,
} = addressActions;

function* getAddressListFlow(action: PayloadAction<UserIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.address.getAddressList,
    action.payload,
  );
  if (error) {
    yield put(getAddressListFailure({response: null, error}));
  } else {
    yield put(getAddressListSuccess({response, error: null}));
  }
}

function* getLenderAddressListFlow(action: PayloadAction<LoanIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.address.getLenderAddressList,
    action.payload,
  );
  if (error) {
    yield put(getLenderAddressListFailure({response: null, error}));
  } else {
    yield put(getLenderAddressListSuccess({response, error: null}));
  }
}

export default function* addressSaga() {
  yield takeLatest(
    addressActionTypes.GetAddressList,
    getAddressListFlow,
  );

  yield takeLatest(
    addressActionTypes.GetLenderAddressList,
    getLenderAddressListFlow,
  );
}