import {
  AssignDisbursalJobPayload,
  GetDisbursedLoansPayload,
  CrIdPayload,
  GetLoanBookingJobListPayload,
  LenderLoanResponse,
  MarkDisbursedJobAsReceivedPayload,
  UnAssignDisbursalJobPayload,
  RequestIdPayload,
  RejectSanctionedLoanPayload,
  ApproveSanctionedLoanPayload,
} from "./lenderLoan.types";
import {call, getContext, put, takeLatest} from "redux-saga/effects";
import {PayloadAction} from "@reduxjs/toolkit";
import {GoldApi} from "../../infra/services/api";
import {snackBarActions} from "../snackbar";
import lenderLoanActions, {lenderLoanActionTypes} from "./lenderLoan.actions";
import {
  setLenderLoanError,
  setLenderLoanLoading,
  setLoanBookingJobDocuments,
  setLoanBookingJobsById,
  toggleModals,
  triggerListReload,
} from "./lenderLoan.reducer";
import {GetDocumentListByCrIdPayload} from "../loanRenewalRebook/cifValidation/cifValidation.types";
import {LoanBookingJobDocumentType} from "app/models/lenderLoan.model";

const {
  getDisbursedLoansSuccess,
  getDisbursedLoansFailure,
  markDisbursedJobAsCompletedSuccess,
  markDisbursedJobAsCompletedFailure,
  assignDisbursalJobToMeFailure,
  assignDisbursalJobToMeSuccess,
  unAssignDisbursalJobToMeSuccess,
  unAssignDisbursalJobToMeFailure,
} = lenderLoanActions;

function* generalErrorFlow(action: PayloadAction<LenderLoanResponse>) {
  yield put(
    snackBarActions.open({
      message: action.payload.error?.message || "Something went wrong!!",
      variant: "error",
    }),
  );
}

function* getDisbursedLoansFlow(
  action: PayloadAction<GetDisbursedLoansPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.lenderLoan.getDisbursedLoans,
    action.payload,
  );
  if (error) {
    yield put(getDisbursedLoansFailure({response: null, error}));
  } else {
    yield put(getDisbursedLoansSuccess({response, error: null}));
  }
}
function* markDisbursedJobAsCompletedFlow(
  action: PayloadAction<MarkDisbursedJobAsReceivedPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.lenderLoan.markDisbursedJobAsReceieved,
    action.payload,
  );
  if (error) {
    yield put(markDisbursedJobAsCompletedFailure({response: null, error}));
  } else {
    yield put(markDisbursedJobAsCompletedSuccess({response, error: null}));
  }
}

function* assignDisbursalJobFlow(
  action: PayloadAction<AssignDisbursalJobPayload>,
) {
  const api: GoldApi = yield getContext("api");

  const {error, response} = yield call(
    api.lenderLoan.assignDisbursalJobToMe,
    action.payload,
  );

  if (error) {
    yield put(assignDisbursalJobToMeFailure({error, response: null}));
  } else {
    yield put(
      assignDisbursalJobToMeSuccess({
        error: null,
        response: {...response, ...action.payload},
      }),
    );
  }
}

function* unAssignDisbursalJobFlow(
  action: PayloadAction<UnAssignDisbursalJobPayload>,
) {
  const api: GoldApi = yield getContext("api");

  const {error, response} = yield call(
    api.lenderLoan.unassignDisbursalJob,
    action.payload,
  );

  if (error) {
    yield put(unAssignDisbursalJobToMeFailure({error, response: null}));
  } else {
    yield put(
      unAssignDisbursalJobToMeSuccess({
        error: null,
        response: {...response, ...action.payload},
      }),
    );
  }
}

function* getLoanBookingJobsFlow(
  action: PayloadAction<GetLoanBookingJobListPayload>,
) {
  yield put(
    setLenderLoanLoading({loadingType: "getLoanBookingJobList", value: true}),
  );
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.lenderLoan.getLoanBookingJobList,
    action.payload,
  );
  if (error) {
    yield put(
      setLenderLoanError({errorType: "getLoanBookingJobList", error}),
    );
    yield put(setLoanBookingJobsById({jobs: [], clearAll: true}));
  } else {
    yield put(setLoanBookingJobsById({jobs: response, clearAll: true}));
  }
  yield put(
    setLenderLoanLoading({loadingType: "getLoanBookingJobList", value: false}),
  );
}

function* getLoanBookingJobDetailsFlow(
  action: PayloadAction<RequestIdPayload>,
) {
  yield put(
    setLenderLoanLoading({
      loadingType: "getLoanBookingJobDetails",
      value: true,
    }),
  );
  yield put(
    setLenderLoanError({
      errorType: "getLoanBookingJobDetails",
      error: null,
    }),
  );
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.lenderLoan.getLoanBookingJobDetails,
    action.payload,
  );
  if (error) {
    yield put(
      setLenderLoanError({errorType: "getLoanBookingJobDetails", error}),
    );
  } else {
    const payloadForReducer = {...response, ...action.payload};

    yield put(
      setLoanBookingJobsById({jobs: [payloadForReducer], clearAll: false}),
    );
  }
  yield put(
    setLenderLoanLoading({
      loadingType: "getLoanBookingJobDetails",
      value: false,
    }),
  );
}

function* getLoanBookingJobDocumentsFlow(
  action: PayloadAction<GetDocumentListByCrIdPayload>,
) {
  yield put(
    setLenderLoanLoading({
      loadingType: "getLoanBookingJobDocuments",
      value: true,
    }),
  );
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.lenderLoan.getLoanBookingDocuments,
    action.payload,
  );
  if (error) {
    yield put(
      setLenderLoanError({errorType: "getLoanBookingJobDocuments", error}),
    );
  } else {
    yield put(
      setLoanBookingJobDocuments({
        requestId: action.payload.requestId,
        documentTypeList: action.payload
          .documentTypeList as LoanBookingJobDocumentType[],
        documents: response,
      }),
    );
  }
  yield put(
    setLenderLoanLoading({
      loadingType: "getLoanBookingJobDocuments",
      value: false,
    }),
  );
}

function* assignLoanBookingJobFlow(action: PayloadAction<RequestIdPayload>) {
  yield put(
    setLenderLoanLoading({
      loadingType: "assignLoanBookingJobToMe",
      value: true,
    }),
  );
  const api: GoldApi = yield getContext("api");
  const {error} = yield call(
    api.lenderLoan.assignLoanBookingJobToMe,
    action.payload,
  );

  if (error) {
    yield put(
      setLenderLoanError({errorType: "assignLoanBookingJobToMe", error}),
    );
    yield put(
      snackBarActions.open({
        message: error.message || "Something went wrong!!",
        variant: "error",
      }),
    );
  } else {
    yield put(triggerListReload());
    yield put(
      snackBarActions.open({
        message: "Loan Booking Job Assigned Successfully",
        variant: "success",
      }),
    );
    yield put(
      toggleModals({
        type: "approveLoanBookingJob",
        value: true,
      }),
    );
  }
  yield put(
    setLenderLoanLoading({
      loadingType: "assignLoanBookingJobToMe",
      value: false,
    }),
  );
}

function* unAssignLoanBookingJobFlow(action: PayloadAction<RequestIdPayload>) {
  yield put(
    setLenderLoanLoading({
      loadingType: "unassignLoanBookingJobToMe",
      value: true,
    }),
  );
  const api: GoldApi = yield getContext("api");
  const {error} = yield call(
    api.lenderLoan.unAssignLoanBookingJobToMe,
    action.payload,
  );

  if (error) {
    yield put(
      setLenderLoanError({errorType: "unassignLoanBookingJobToMe", error}),
    );
    yield put(
      snackBarActions.open({
        message: error.message || "Something went wrong!!",
        variant: "error",
      }),
    );
  } else {
    yield put(triggerListReload());
    yield put(
      snackBarActions.open({
        message: "Loan Booking Job Unassigned",
        variant: "success",
      }),
    );
  }
  yield put(
    setLenderLoanLoading({
      loadingType: "unassignLoanBookingJobToMe",
      value: false,
    }),
  );
}

function* approveLoanBookingJobFlow(
  action: PayloadAction<ApproveSanctionedLoanPayload>,
) {
  yield put(
    setLenderLoanLoading({loadingType: "approveLoanBookingJob", value: true}),
  );
  const api: GoldApi = yield getContext("api");
  const {error} = yield call(
    api.lenderLoan.approveLoanBookingJob,
    action.payload,
  );
  if (error) {
    yield put(
      setLenderLoanError({errorType: "approveLoanBookingJob", error}),
    );
    yield put(
      snackBarActions.open({
        message: error.message || "Something went wrong!!",
        variant: "error",
      }),
    );
  } else {
    yield put(
      snackBarActions.open({
        message: "Loan Booking Job Approved Successfully",
        variant: "success",
      }),
    );
    yield put(triggerListReload());
    yield put(toggleModals({type: "approveLoanBookingJob", value: false}));
  }
  yield put(
    setLenderLoanLoading({loadingType: "approveLoanBookingJob", value: false}),
  );
}

function* rejectLoanBookingJobFlow(
  action: PayloadAction<RejectSanctionedLoanPayload>,
) {
  yield put(
    setLenderLoanLoading({loadingType: "rejectLoanBookingJob", value: true}),
  );
  const api: GoldApi = yield getContext("api");
  const {error} = yield call(
    api.lenderLoan.rejectLoanBookingJob,
    action.payload,
  );
  if (error) {
    yield put(setLenderLoanError({errorType: "rejectLoanBookingJob", error}));
    yield put(
      snackBarActions.open({
        message: error.message || "Something went wrong!!",
        variant: "error",
      }),
    );
  } else {
    yield put(
      snackBarActions.open({
        message: "Loan Booking Job Rejected",
        variant: "success",
      }),
    );
    yield put(triggerListReload());
    yield put(toggleModals({type: "approveLoanBookingJob", value: false}));
    yield put(toggleModals({type: "rejectSanctionedLoan", value: false}));
  }
  yield put(
    setLenderLoanLoading({loadingType: "rejectLoanBookingJob", value: false}),
  );
}

export default function* lenderLoanSaga() {
  yield takeLatest(
    lenderLoanActionTypes.GetDisbursedLoans,
    getDisbursedLoansFlow,
  );

  yield takeLatest(
    lenderLoanActionTypes.MarkDisbursedJobAsCompleted,
    markDisbursedJobAsCompletedFlow,
  );

  yield takeLatest(
    lenderLoanActionTypes.GetDisbursedLoansFailure,
    generalErrorFlow,
  );

  yield takeLatest(
    lenderLoanActionTypes.MarkDisbursedJobAsCompletedFailure,
    generalErrorFlow,
  );
  yield takeLatest(
    lenderLoanActionTypes.AssignDisbursalJob,
    assignDisbursalJobFlow,
  );
  yield takeLatest(
    lenderLoanActionTypes.UnAssignDisbursalJob,
    unAssignDisbursalJobFlow,
  );

  yield takeLatest(
    lenderLoanActionTypes.AssignDisbursalJobFailure,
    generalErrorFlow,
  );
  yield takeLatest(
    lenderLoanActionTypes.UnAssignDisbursalJobFailure,
    generalErrorFlow,
  );
  yield takeLatest(
    lenderLoanActionTypes.AssignDisbursalJobSuccess,
    function*() {
      yield put(
        snackBarActions.open({
          message: "Job has been successfully Assigned!",
          variant: "success",
        }),
      );
    },
  );
  yield takeLatest(
    lenderLoanActionTypes.UnAssignDisbursalJobSuccess,
    function*() {
      yield put(
        snackBarActions.open({
          message: "Job has been successfully unassigned!",
          variant: "success",
        }),
      );
    },
  );

  yield takeLatest(
    lenderLoanActionTypes.MarkDisbursedJobAsCompletedSuccess,
    function*() {
      yield put(
        snackBarActions.open({
          message: "Job Marked as completed successfully!",
          variant: "success",
        }),
      );
    },
  );

  yield takeLatest(
    lenderLoanActionTypes.GetLoanBookingJobList,
    getLoanBookingJobsFlow,
  );
  yield takeLatest(
    lenderLoanActionTypes.AssignLoanBookingJob,
    assignLoanBookingJobFlow,
  );
  yield takeLatest(
    lenderLoanActionTypes.UnassignLoanBookingJob,
    unAssignLoanBookingJobFlow,
  );
  yield takeLatest(
    lenderLoanActionTypes.GetLoanBookingJobDetails,
    getLoanBookingJobDetailsFlow,
  );
  yield takeLatest(
    lenderLoanActionTypes.GetLoanBookingJobDocuments,
    getLoanBookingJobDocumentsFlow,
  );
  yield takeLatest(
    lenderLoanActionTypes.ApproveLoanBookingJob,
    approveLoanBookingJobFlow,
  );
  yield takeLatest(
    lenderLoanActionTypes.RejectLoanBookingJob,
    rejectLoanBookingJobFlow,
  );
}
