import React, {useCallback, useEffect, useState, useMemo} from "react";
import {useAppSelector, useSnackBar, useAppDispatch} from "app/store/hooks";
import {authSelectors} from "app/store/auth";
import Title from "../styles/Title";
import SubTitle from "../styles/SubTitle";
import OccupationValueContainer from "./styled";
import {Grid, Box, IconButton, Typography} from "@mui/material";
import fetchEmploymentDetails from "api/business/fetchAdminEmploymentDetails";
import getDocument from "api/business/getDocument";
import fetchLoanRequirementReason from "api/business/getLoanRequirementReason";
import {IGButton, IGDocumentViewer} from "app/components";
import GoldenDivider from "../KYCDetails/styles/GoldenDivider";
import ArrowUpFromBracket from "app/AppLayout/Icons/ArrowUpFromBracket";
import {fileType} from "app/enums/generalPurpose";
import {useIGTranslation} from "app/infra/plugins/i18n";
import AddIcon from "@mui/icons-material/Add";
import AgriDocumentDetails from "./AgriDocumentDetails/index";
import {activeLoansActions, activeLoansSelectors} from "app/store/activeLoans";

const OccupationDetails = ({crId, crJobId}) => {
  const accessToken = useAppSelector(authSelectors.getAuthToken);
  const dispatch = useAppDispatch();
  const isAgriLoanAllowedWithoutDoc = useAppSelector(
    activeLoansSelectors.getAgriFlowFlag,
  );
  const {showError} = useSnackBar();
  const {tButton} = useIGTranslation();
  const [occupationDetails, setOccupationDetails] = useState({});
  const [isAgriLoanEnable, setIsAgriLoanEnable] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [udhyamDoc, setUdhyamDoc] = useState(null);
  const [loanRequirementReason, setLoanRequirementReason] = useState(null);

  const fetchOccupationalDetails = useCallback(async () => {
    const {error, response} = await fetchEmploymentDetails({
      crId,
    });

    if (!error) {
      setOccupationDetails(response);
      setIsLoading(false);
    } else {
      showError(error?.message || "not able to fetch occupdation details");
      setIsLoading(false);
    }
  }, [crId]);

  const fetchLoanRequiremetReasonDetails = useCallback(async () => {
    const {error, response} = await fetchLoanRequirementReason({
      crId,
    });

    if (!error) {
      setLoanRequirementReason(response?.loanRequirementReasonDisplayValue);
      setIsLoading(false);
    } else {
      showError(error?.message || "not able to fetch loan requirement reason");
      setIsLoading(false);
    }
  }, [crId]);

  const fetchUdhyamDocument = useCallback(async () => {
    const {payload, statusCode} = await getDocument({
      accessToken,
      crId,
      documentType: ["UDHYAM_CERTIFICATE"],
    });

    if (statusCode === 200) {
      if (payload.length) {
        setUdhyamDoc(payload[0]);
      }
    } else {
      showError("Unable to fetch udhyam certificate");
    }
  }, [accessToken, crId]);

  useEffect(() => {
    fetchOccupationalDetails();
    fetchLoanRequiremetReasonDetails();
    fetchUdhyamDocument();
    dispatch(
      activeLoansActions.getInsuranceFeatureFlag({
        crId,
      }),
    );
  }, []);

  const {
    occupationTypeDisplayValue,
    yearInIndustry,
    annualIncome,
    businessIndustryDisplayValue,
    userDesignationDisplayValue,
    employmentStatusDisplayValue,
    employerName,
    borrowerCategoryDisplayValue,
    weakerSectionCategoryDisplayValue,
    userWorkSubSectorDisplayValue,
    userWorkSectorDisplayValue,
    landArea,
  } = occupationDetails;

  const occupationDetailsFieldsMapping = useMemo(
    () => [
      {
        key: "User Work Sector",
        value: userWorkSectorDisplayValue,
      },
      {
        key: "Occupation Type",
        value: occupationTypeDisplayValue,
      },
      {
        key: "Years In Industry",
        value: yearInIndustry,
      },
      {
        key: "Annual Income",
        value: annualIncome,
      },
      {
        key: "Business Industry",
        value: businessIndustryDisplayValue,
      },
      // {
      //   key: "User Designation",
      //   value: userDesignationDisplayValue,
      // },
      {
        key: "User Work Sub Sector",
        value: userWorkSubSectorDisplayValue,
      },
      // {
      //   key: "Employment Status",
      //   value: employmentStatusDisplayValue,
      // },
      // {
      //   key: "Employment Name",
      //   value: employerName,
      // },
      {
        key: "Weaker Section Category",
        value: weakerSectionCategoryDisplayValue,
      },
      {
        key: "Borrower Category",
        value: borrowerCategoryDisplayValue,
      },
      {
        key: "Loan Requirement Reason",
        value: loanRequirementReason,
      },
      {
        key: "Land Area",
        value: landArea || "N/A",
      },
    ],
    [
      occupationTypeDisplayValue,
      yearInIndustry,
      annualIncome,
      businessIndustryDisplayValue,
      userDesignationDisplayValue,
      userWorkSectorDisplayValue,
      userWorkSubSectorDisplayValue,
      employmentStatusDisplayValue,
      employerName,
      weakerSectionCategoryDisplayValue,
      borrowerCategoryDisplayValue,
      loanRequirementReason,
      landArea,
    ],
  );

  const viewUdhyamDocPdf = (link) => {
    window.open(link, "_blank");
  };

  // if (isLoading) {
  //   return <Loading height="20vh" />;
  // }

  return (
    <>
      <Title>
        Please verify all details and mark checkbox to proceed to next step or
        send back and ask LM to fill correctly
      </Title>

      <Grid container rowSpacing={2}>
        {occupationDetailsFieldsMapping?.map((item) => (
          <Grid item xs={4}>
            <SubTitle>{item.key}</SubTitle>
            <OccupationValueContainer>{item.value}</OccupationValueContainer>
          </Grid>
        ))}
      </Grid>

      {udhyamDoc && (
        <>
          <GoldenDivider />
          <Title>
            {udhyamDoc?.documentType?.replaceAll("_", " ")}
            <p>{`Udhyam Doc Number - ${udhyamDoc?.documentNumber}`}</p>
          </Title>

          {udhyamDoc.fileType === fileType.PDF ? (
            <IGButton
              color="golden"
              onClick={() => viewUdhyamDocPdf(udhyamDoc.fileUrl)}
            >
              {tButton("VIEW_DOCUMENT")}
              <Box ml={1}>
                <ArrowUpFromBracket />
              </Box>
            </IGButton>
          ) : (
            <>
              <IGDocumentViewer document={udhyamDoc} />
            </>
          )}
        </>
      )}

      <GoldenDivider />
      {!isAgriLoanAllowedWithoutDoc && (
        <Box display="flex" justifyContent="space-between">
          <Typography fontWeight={600}>Add Agri Documents</Typography>
          <IconButton onClick={() => setIsAgriLoanEnable(!isAgriLoanEnable)}>
            <AddIcon />
          </IconButton>
        </Box>
      )}

      {isAgriLoanEnable && (
        <AgriDocumentDetails
          crId={crId}
          crJobId={crJobId}
          isRenewRebookFlow={false}
        />
      )}
    </>
  );
};

export default OccupationDetails;
