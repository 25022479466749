import React, {useEffect, useState, useMemo} from "react";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import {IGSelect, IGDialog, IGTextField} from "app/components";
import {Stack} from "@mui/material";
import {toggleModals} from "app/store/activeLoans/activeLoans.reducer";
import {activeLoansActions, activeLoansSelectors} from "app/store/activeLoans";
import {isNil} from "lodash";
import {IGLoading} from "app/components";

const ReasonToReject = ({
  crId,
  crJobId,
  open,
  rejectionSource,
  getMileStoneOfCr,
  getNextJobApi,
  crJobStatus,
  title,
  confirmText,
}) => {
  const dispatch = useAppDispatch();
  const rejectCrConfig = useAppSelector(
    activeLoansSelectors.getRejectCrConfig,
  );
  const {
    rejectCrConfig: rejectCrConfigLoading,
    rejectCr: rejectCrLoading,
  } = useAppSelector(
    activeLoansSelectors.getLoading,
  );
  const [remark, setRemark] = useState("");
  const [rejectionCategoryId, setRejectionCategoryId] = useState(null);
  const [rejectionSubCategoryId, setRejectionSubCategoryId] = useState(null);
  const {
  rejectionCategoryConfigList,
  rejectionSubCategoryConfigList,
  } = rejectCrConfig ?? {};
  const filteredRejectionSubCategoryConfigList = useMemo(() => {
    return rejectionSubCategoryConfigList.filter(
      (list) => list.rejectionCategoryId === rejectionCategoryId,
    );
  }, [rejectionCategoryId, rejectionSubCategoryConfigList]);


  const onClose = () => {
    dispatch(toggleModals({
      type: "rejectCr",
      value: false,
    }));
  };

  const onConfirm = () => {
    dispatch(activeLoansActions.rejectCr({
      crId:crId,
      crJobId: crJobId,
      categoryId: rejectionCategoryId,
      subCategoryId: rejectionSubCategoryId,
      remark: remark,
      source: rejectionSource,
      crJobStatus,
    }));

    getMileStoneOfCr();
    getNextJobApi();
  };

  useEffect(() => {
    dispatch(activeLoansActions.getRejectCrConfig());
  }, [dispatch]);

  return (
    <IGDialog
      open={open}
      onClose={onClose}
      onCancel={onClose}
      title={title}
      confirmProps={{
        text: confirmText,
        disabled: isNil(rejectionCategoryId)
        || isNil(rejectionSubCategoryId)
        || !remark,
        loading: rejectCrConfigLoading || rejectCrLoading,
      }}
      onConfirm={onConfirm}
      subtitle="Please provide more details"
    >
      {(rejectCrConfigLoading || rejectCrLoading) ?
        <IGLoading height={"10vh"}/>
      :(
      <Stack spacing={2}>
        <IGSelect
          required
          name="rejectionCategory"
          label="What is the reason or rejecting"
          options={rejectionCategoryConfigList.map((l) => ({
            value: l.id,
            text: l.displayValue,
          }))}
          onChange={(event) =>
            setRejectionCategoryId(event.target.value)
          }
        />
        <IGSelect
          required
          name="rejectionSubCategory"
          label="Please select the rejection category"
          options={filteredRejectionSubCategoryConfigList.map((l) => ({
            value: l.id,
            text: l.displayValue,
          }))}
          onChange={(event) =>
            setRejectionSubCategoryId(event.target.value)
          }
        />
        <IGTextField
          required
          multiline
          rows="5"
          cols="37"
          label="Remarks"
          placeholder="Remarks"
          value={remark}
          onChange={event => setRemark(event.target.value)}
          inputProps={{
            maxLength: 1200,
          }}
        />
      </Stack>
      )}
    </IGDialog>
  );
};

export default ReasonToReject;
