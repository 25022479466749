import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {useLocation, Outlet} from "react-router-dom";
import {List, Box} from "@mui/material";
import LayoutMenu from "./MenuList";
import NavBar from "./NavBar";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import {authSelectors} from "app/store/auth";
import {layoutActions, layoutSelector} from "app/store/layout";
import {StyledNavDrawer} from "./styles";
import {getProductByName} from "app/models/product";
import {allFeatures} from "app/models/feature";
import featureFlagActions from "app/store/featureFlags/featureFlags.actions";
import {IGLoading} from "app/components";

function MiniDrawer() {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(authSelectors.getIsAuthenticated);
  const user = useAppSelector(authSelectors.getUser);
  const dynamicRoutes = useAppSelector(layoutSelector.getDynamicRoutes);
  const layoutLoading = useAppSelector(layoutSelector.getLoading);
  // Url data
  const {pathname} = useLocation();
  const productPath = pathname.split("/")[1];
  const featurePath = pathname.split("/")[2];
  const subFeaturePath = pathname.split("/")[3];

  // States
  const [activeTab, setActiveTab] = useState({});
  const [activeMenu, setActiveMenu] = useState({});
  const [activeSubMenu, setActiveSubMenu] = useState({});
  const [open, setOpen] = useState(false);
  const [toggleMenu, setToggleMenu] = useState("");
  const [dynamicRouteSuccess, setDynamicRouteSuccess] = useState(false);

  // Method to get current product/tab menu
  const productMenus = useMemo(() => {
    if (!user) {
      return [];
    }
    const menus = {};
    user.products.forEach((product) => {
      if (!product.getMenu) {
        const newProduct = getProductByName(product.name);
        product.getMenu = newProduct.getMenu;
      }
      menus[product.path] = product.getMenu(user.userRoleList, dynamicRoutes);
    });
    return menus;
  }, [
    user,
    dynamicRoutes,
  ]);

  // Method to process url paths, active tab, menu & submenu states
  const processTab = useCallback(() => {
    let newTab = activeTab;

    if (!productPath || !activeTab.path) {
      newTab = user?.products?.[0];
      setActiveTab(newTab || {});
    } else if (activeTab.path !== productPath) {
      newTab = user?.products?.find(p => p.path === productPath);
      setActiveTab(newTab || {});
    }

    return newTab || {};
  }, [user, productPath, activeTab]);

  const processMenu = useCallback(() => {
    let newMenu;
    const newTab = processTab();

    if (!featurePath || !activeMenu.path) {
      newMenu = productMenus[newTab.path]?.[0];
      setActiveMenu(newMenu || {});
    } else if (activeMenu.path !== featurePath) {
      newMenu = productMenus[newTab.path]?.find(f => f.path === featurePath);
      setActiveMenu(newMenu || {});
    }
    return newMenu || activeMenu;
  }, [productMenus, featurePath, activeMenu, processTab]);

  const processSubMenu = useCallback(() => {
    const newMenu = processMenu();
    if (newMenu.isMultiMenu) {
      if (!subFeaturePath || !activeSubMenu.path) {
        setActiveSubMenu(newMenu.subMenu[0]);
      } else if (activeSubMenu.path !== subFeaturePath) {
        setActiveSubMenu(
          newMenu.subMenu.find((m) => m.path === subFeaturePath) ||
            newMenu.subMenu[0],
        );
      }
    } else if (activeSubMenu.path) {
      setActiveSubMenu({});
      setToggleMenu("");
    }
  }, [activeSubMenu?.path, subFeaturePath, processMenu]);


  const fetchDynamicRoutes = () => {
    setDynamicRouteSuccess(false);
    if (user) {
      if (user.products.some((product) => product.name === "LENDER_PORTAL")) {
        dispatch(layoutActions.getLenderMenu());
      }

      for (const [featureName, feature] of Object.entries(allFeatures)) {
        if (
          user?.userRoleList &&
          feature.dynamicSubMenu &&
          user.userRoleList.some((role) =>
            feature.whitelistedRoles.includes(role),
          )
        ) {
          if (featureName === "serviceDesk") {
            dispatch(
              layoutActions.getSubMenu({
                menuName: "service-desk",
                sectionEnumList: ["CST_AGENT_CSAT"],
              }),
            );
          } else {
            dispatch(layoutActions.getSubMenu({menuName: featureName}));
          }
        }
      }
    }
    setDynamicRouteSuccess(true);
  };

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(featureFlagActions.getFeatureFlag({
        moduleEnum: "INTERNAL_DASHBOARD",
        moduleFeatureEnum: "INTERNAL_DASHBOARD_MONITORING_CHECK",
      }));
      fetchDynamicRoutes();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (dynamicRouteSuccess) {
      processSubMenu();
    }
  }, [dynamicRouteSuccess, processSubMenu]);

  if (!dynamicRouteSuccess || layoutLoading) {
    return <IGLoading />;
  }


  return (
    <Box display="flex">
      <NavBar
        open={open}
        setOpen={setOpen}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <StyledNavDrawer
        variant="permanent"
        open={open}
        PaperProps={{
          elevation: 2,
        }}
        onClose={() => setOpen(false)}
        sx={{
          width: open ? 260 : 80,

          "& .MuiPaper-root": {
            width: open ? 260 : 80,
            mt: "64px",
            height: "calc(100vh - 64px)",
            p: "8px",
          },
        }}
      >
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          onMouseEnter={() => setOpen(true)}
          onMouseLeave={() => setOpen(false)}
        >
          <LayoutMenu
            open={open}
            activeTab={activeTab}
            activeMenu={activeMenu}
            activeSubMenu={activeSubMenu}
            toggleMenu={toggleMenu}
            menuList={productMenus[activeTab.path] || []}
            setOpen={setOpen}
            setActiveMenu={setActiveMenu}
            setToggleMenu={setToggleMenu}
            setActiveSubMenu={setActiveSubMenu}
          />
        </List>
      </StyledNavDrawer>

      <main
        style={{
          flexGrow: 1,
          height: "calc(100vh - 64px)",
          overflowY: "auto",
          width: "80%",
          marginTop: "64px",
        }}
      >
        <Outlet />
      </main>
    </Box>
  );
}
export default MiniDrawer;
