import React, {useEffect, useState} from "react";
import {Box, Stack, useTheme} from "@mui/material";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import {leadActions, leadSelectors} from "app/store/lead";
import {useSearchParams} from "react-router-dom";
import LeadCreditHistory from "./LeadCreditHistory/LeadCreditHistory";
import {
  IGButton,
  IGLoading,
  IGSection,
  IGSectionContent,
  IGSectionHeader,
  IGTextField,
} from "app/components";

const LeadCreditHistoryByParentId = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const crmLeadIdFromUrl = searchParams.get("crmLeadId");
  const parentLeadId = useAppSelector(leadSelectors.getParentLeadId);
  const {getParentLeadId: isParentLeadIdLoading} = useAppSelector(
    leadSelectors.getLoading,
  );
  const [crmLeadId, setCrmLeadId] = useState<string | null>(null);

  useEffect(() => {
    if (crmLeadIdFromUrl && !parentLeadId) {
      setCrmLeadId(crmLeadIdFromUrl);
      dispatch(
        leadActions.getParentLeadId({
          crmLeadId: crmLeadIdFromUrl,
        }),
      );
    }
  }, [crmLeadIdFromUrl, dispatch, parentLeadId]);

  return (
    <>
      <IGSection>
        <IGSectionHeader title="Lead Credit History" />
        <IGSectionContent>
          <Stack gap={3}>
            <Stack flexDirection="row" gap={2}>
              <IGTextField
                name="crmLeadId"
                label="CRM Lead ID"
                value={crmLeadId}
                onChange={(e) => setCrmLeadId(e.target.value)}
                sx={{
                  width: "500px",
                }}
                disabled={isParentLeadIdLoading}
              />
              <IGButton
                loading={isParentLeadIdLoading}
                onClick={() => {
                  if (crmLeadId) {
                    dispatch(
                      leadActions.getParentLeadId({
                        crmLeadId,
                      }),
                    );
                  }
                }}
              >
                Search
              </IGButton>
            </Stack>
            {parentLeadId && (
              <Box
                bgcolor="#FFFFFF"
                boxShadow={"2px 2px 2px 2px #E0E0E0"}
                border={`1px solid ${theme.palette.grey.A400}`}
                borderRadius={1}
                p={2}
                width="700px"
              >
                {isParentLeadIdLoading ? (
                  <IGLoading height="10vh" />
                ) : (
                  <LeadCreditHistory leadId={Number(parentLeadId)} isLsqFlow />
                )}
              </Box>
            )}
          </Stack>
        </IGSectionContent>
      </IGSection>
    </>
  );
};

export default LeadCreditHistoryByParentId;
