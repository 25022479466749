import {goldApiService} from "../../../plugins/axios";
import {AddressApiInterface, AddressApiResources} from "app/store/address/address.types";

const ADDRESS_ENDPOINTS: AddressApiResources = {
  GET_LOAN_DETAILS: {
    URL: "/admin/business/user/:userId/address",
    METHOD: "GET",
  },
  GET_LENDER_ADDRESS_LIST: {
    URL: "/admin/business/lender/loan/:loanId/user-address",
    METHOD: "GET",
  },
};

const addressApi: AddressApiInterface = {
  getAddressList: async ({userId}) => {
    return await goldApiService({
      resource: ADDRESS_ENDPOINTS.GET_LOAN_DETAILS,
      options: {
        pathVars: {userId},
      },
    });
  },
  getLenderAddressList: async ({loanId}) => {
    return await goldApiService({
      resource: ADDRESS_ENDPOINTS.GET_LENDER_ADDRESS_LIST,
      options: {
        pathVars: {loanId},
      },
    });
  },
};

export {ADDRESS_ENDPOINTS};
export default addressApi;