import {goldApiService} from "app/infra/plugins/axios";
import {PartReleaseApiInterface, PartReleaseApiResources} from "./types";

const PartReleaseApiEndpoints: PartReleaseApiResources = {
  GET_PART_RELEASE_DATA: {
    URL: "/admin/service-desk/request/:requestId/part-release-data",
    METHOD: "GET",
  },
  GET_ORNAMENT_LIST: {
    URL: "/api/v1/admin/service-desk/loan/part-release/:requestId/ornament-list",
    METHOD: "GET",
  },
  UPDATE_CST_OFFER: {
    URL: "/api/v1/admin/service-desk/loan/part-release/:requestId/cst/update-offer",
    METHOD: "POST",
  },
  GET_OFFER_HISTORY: {
    URL: "/api/v1/admin/service-desk/loan/part-release/:requestId/offer-history",
    METHOD: "GET",
  },
  GET_OFFER_HISTORY_ORNAMENT_SELECTION_DETAIL: {
    URL: "/api/v1/admin/service-desk/loan/part-release/:requestId/offer-history/:offerHistoryId/ornament-selection-detail",
    METHOD: "GET",
  },
  GET_CONSUMER_OFFER_DETAIL: {
    URL: "/api/v1/admin/service-desk/loan/part-release/:requestId/consumer-offer-detail",
    METHOD: "GET",
  },
  GET_NEW_LOAN_OFFER_DETAIL: {
    URL: "/api/v1/admin/service-desk/loan/part-release/:requestId/cst/offer-detail",
    METHOD: "GET",
  },
  GET_NET_PAYABLE_AMOUNT: {
    URL: "/api/v1/admin/service-desk/loan/part-release/:requestId/cst/net-payable-amount",
    METHOD: "GET",
  },
  CHECK_IS_OFFER_EXPIRED: {
    URL: "/api/v1/admin/service-desk/loan/part-release/:requestId/is-offer-expired",
    METHOD: "GET",
  },
  SHARE_OFFER: {
    URL: "/api/v1/admin/service-desk/loan/part-release/:requestId/share-offer",
    METHOD: "POST",
  },
  GET_CST_CONFIG: {
    URL: "/api/v1/admin/service-desk/loan/part-release/:requestId/cst/config",
    METHOD: "GET",
  },
  GET_CM_CONFIG: {
    URL: "/api/v1/admin/service-desk/loan/part-release/:requestId/cm/config",
    METHOD: "GET",
  },
  GET_CM_OFFER_DETAIL: {
    URL: "/api/v1/admin/service-desk/loan/part-release/:requestId/cm/offer-detail",
    METHOD: "GET",
  },
  UPDATE_CM_OFFER: {
    URL: "/api/v1/admin/service-desk/loan/part-release/:requestId/cm/update-offer",
    METHOD: "PUT",
  },
  UPDATE_OFFER_ROI: {
    URL: "/api/v1/admin/service-desk/loan/part-release/:requestId/cm/update-offer-roi",
    METHOD: "PUT",
  },
};

const partReleaseApi: PartReleaseApiInterface = {
  // getPartReleaseOfferHistory: async ({requestId}) => {
  //   return await goldApiService({
  //     resource: PartReleaseApiEndpoints.GET_PART_RELEASE_OFFER_HISTORY,
  //     options: {
  //       pathVars: {requestId},
  //     },
  //   });
  // },
  getPartReleaseData: async ({requestId}) => {
    return await goldApiService({
      resource: PartReleaseApiEndpoints.GET_PART_RELEASE_DATA,
      options: {
        pathVars: {requestId},
      },
    });
  },
  getOrnamentList: async ({requestId, offerBy}) => {
    return await goldApiService({
      resource: PartReleaseApiEndpoints.GET_ORNAMENT_LIST,
      options: {
        pathVars: {requestId},
        queryParams: {offerBy},
      },
    });
  },
  updateCstOffer: async ({requestId, formData}) => {
    return await goldApiService({
      resource: PartReleaseApiEndpoints.UPDATE_CST_OFFER,
      options: {
        pathVars: {requestId},
        data: formData,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  getPartReleaseOfferHistory: async ({requestId, offerBy}) => {
    return await goldApiService({
      resource: PartReleaseApiEndpoints.GET_OFFER_HISTORY,
      options: {
        pathVars: {requestId},
        queryParams: {offerBy},
      },
    });
  },
  getOfferHistoryOrnamentSelectionDetail: async ({
    requestId,
    offerHistoryId,
    offerBy,
  }) => {
    return await goldApiService({
      resource:
        PartReleaseApiEndpoints.GET_OFFER_HISTORY_ORNAMENT_SELECTION_DETAIL,
      options: {
        pathVars: {requestId, offerHistoryId},
        queryParams: {offerBy},
      },
    });
  },
  getConsumerOfferDetail: async ({requestId}) => {
    return await goldApiService({
      resource: PartReleaseApiEndpoints.GET_CONSUMER_OFFER_DETAIL,
      options: {
        pathVars: {requestId},
      },
    });
  },
  getNewLoanOfferDetail: async ({requestId}) => {
    return await goldApiService({
      resource: PartReleaseApiEndpoints.GET_NEW_LOAN_OFFER_DETAIL,
      options: {
        pathVars: {requestId},
      },
    });
  },
  getNetPayableAmount: async ({requestId}) => {
    return await goldApiService({
      resource: PartReleaseApiEndpoints.GET_NET_PAYABLE_AMOUNT,
      options: {
        pathVars: {requestId},
      },
    });
  },
  checkIsOfferExpired: async ({requestId, offerBy}) => {
    return await goldApiService({
      resource: PartReleaseApiEndpoints.CHECK_IS_OFFER_EXPIRED,
      options: {
        pathVars: {requestId},
        queryParams: {offerBy},
      },
    });
  },
  shareOffer: async ({
    requestId,
    ...rest
  }) => {
    return await goldApiService({
      resource: PartReleaseApiEndpoints.SHARE_OFFER,
      options: {
        pathVars: {requestId},
        data: {
          ...rest,
        },
      },
    });
  },
  getCstConfig: async ({requestId}) => {
    return await goldApiService({
      resource: PartReleaseApiEndpoints.GET_CST_CONFIG,
      options: {
        pathVars: {requestId},
      },
    });
  },
  getCmConfig: async ({requestId}) => {
    return await goldApiService({
      resource: PartReleaseApiEndpoints.GET_CM_CONFIG,
      options: {
        pathVars: {requestId},
      },
    });
  },
  getCmOfferDetail: async ({requestId}) => {
    return await goldApiService({
      resource: PartReleaseApiEndpoints.GET_CM_OFFER_DETAIL,
      options: {
        pathVars: {requestId},
      },
    });
  },
  updateCmOffer: async ({requestId, payload}) => {
    return await goldApiService({
      resource: PartReleaseApiEndpoints.UPDATE_CM_OFFER,
      options: {
        pathVars: {requestId},
        data: payload,
      },
    });
  },
  updateOfferRoi: async ({requestId, formData}) => {
    return await goldApiService({
      resource: PartReleaseApiEndpoints.UPDATE_OFFER_ROI,
      options: {
        pathVars: {requestId},
        data: formData,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};

export {PartReleaseApiEndpoints};
export default partReleaseApi;