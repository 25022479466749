import React, {useCallback, useEffect} from "react";
import {useAppSelector} from "app/store/hooks";
import {
  unscheduledSelectors,
  unscheduledActions,
} from "app/store/scheduling/unscheduled";
import {Grid, Paper, Typography, Box} from "@mui/material";
import {creditReportStatusEnum} from "app/enums/gold_loan/recommendation";
import {useDispatch} from "react-redux";
import {IGButton, IGLoading} from "app/components";
import {useIGTranslation} from "app/infra/plugins/i18n";
import {leadActions, leadSelectors} from "app/store/lead";

interface LeadCreditHistoryProps {
  leadId: number;
  isLsqFlow: boolean;
}
const LeadCreditHistory = ({
  leadId,
  isLsqFlow = false,
}: LeadCreditHistoryProps) => {
  const {t} = useIGTranslation();
  const dispatch = useDispatch();
  const {
    isFetching: isLeadCreditReportStatusFetching,
    data: leadCreditReportStatus,
  } = useAppSelector(unscheduledSelectors.getUnscheduledLeadCreditReportStatus);
  const {
    isFetching: isLeadCreditDetailsFetching,
    data: leadCreditDetails,
  } = useAppSelector(unscheduledSelectors.getUnscheduledLeadCreditDetails);
  const parentLeadIdCreditDetails = useAppSelector((state) =>
    leadSelectors.getCreditDetails(state, leadId),
  );
  const {
    getParentLeadIdCreditDetail: isParentLeadIdCreditDetailLoading,
  } = useAppSelector(leadSelectors.getLoading);
  const isCreditReportStatusSuccess =
    leadCreditReportStatus?.status === creditReportStatusEnum.SUCCESS;

  const fetchCreditDetails = useCallback(() => {
    if (!isLsqFlow) {
      dispatch(
        unscheduledActions.getLeadCreditReportStatus({
          id: leadId,
        }),
      );
    } else {
      dispatch(
        leadActions.getParentLeadIdCreditDetail({
          parentLeadId: leadId,
        }),
      );
    }
  }, [dispatch, leadId, isLsqFlow]);

  const handleCreditReportStatus = useCallback(() => {
    if (isCreditReportStatusSuccess && !isLsqFlow) {
      dispatch(
        unscheduledActions.getLeadCreditDetails({
          id: leadId,
        }),
      );
    }
  }, [
    dispatch,
    leadId,
    isLsqFlow,
    isCreditReportStatusSuccess,
  ]);

  const loadingToUse = isLsqFlow
    ? isParentLeadIdCreditDetailLoading
    : isLeadCreditDetailsFetching || isLeadCreditReportStatusFetching;
  const creditDetailsToUse = isLsqFlow
    ? parentLeadIdCreditDetails
    : leadCreditDetails;
  const {creditScore, activeAccounts, totalAccounts, wilfulDefault, link} =
    creditDetailsToUse || {};
  const creditReportDetails = [
    {
      name: "Credit Score",
      value: creditScore || "NA",
    },

    {
      name: "Active Accounts",
      value: activeAccounts || "NA",
    },
    {
      name: "Total Accounts",
      value: totalAccounts || "NA",
    },

    {
      name: "WillFul Default Status",
      value: (
        <Typography
          sx={{
            color: wilfulDefault ? "red" : "green",
          }}
        >
          {String(wilfulDefault)}
        </Typography>
      ),
    },
  ];

  const viewCreditReport = () => {
    if (link) {
      const win = window.open(
        "",
        "_blank",
        "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=780,height=500",
      );
      if (win) {
        win.document.body.innerHTML = link || "";
      }
    }
  };

  useEffect(() => {
    fetchCreditDetails();
  }, [fetchCreditDetails]);

  useEffect(() => {
    handleCreditReportStatus();
  }, [handleCreditReportStatus]);

  if (
    !isLsqFlow &&
    !isCreditReportStatusSuccess &&
    !isLeadCreditReportStatusFetching
  ) {
    return (
      <Paper>
        <Typography align="center">No Credit History found!</Typography>
      </Paper>
    );
  }

  return (
    <>
      <Paper>
        {loadingToUse && <IGLoading height="10vh" />}
        {creditScore ? (
          <>
            {creditReportDetails?.map((detail) => (
              <Grid container>
                <Grid item xs={6} spacing={3} p={1}>
                  <Typography align="left">{detail.name}</Typography>
                </Grid>
                <Grid item xs={6} spacing={3} p={1}>
                  <Typography align="right" fontWeight={500}>
                    {detail.value}
                  </Typography>
                </Grid>
              </Grid>
            ))}
            <Box m={1} pb={2}>
              {link && (
                <IGButton color="golden" onClick={() => viewCreditReport()}>
                  {t("VIEW_CREDIT_REPORT")}
                </IGButton>
              )}
            </Box>
          </>
        ) : (
          <Typography align="center">No Credit History found!</Typography>
        )}
      </Paper>
    </>
  );
};

export default LeadCreditHistory;
