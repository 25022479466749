import React, {FC, InputHTMLAttributes, useRef, useState} from "react";
import styled from "@emotion/styled";
import {
  Box,
  FormHelperText,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import {IGButton} from "../index";
import {AttachFileRounded, CancelRounded} from "@mui/icons-material";
import {isArray} from "lodash";

export interface IGFileUploadProps {
  inputProps?: Omit<
    InputHTMLAttributes<HTMLInputElement>,
    "value" | "onChange" | "multiple"
  >;
  buttonText?: string;
  value: File | File[] | FileList |null;
  text?: string;
  onChange: (files: FileList | any[], clear?: boolean) => void;
  multiple?: boolean;
  error?: boolean;
  touched?: boolean;
  helperText?: React.ReactNode;
  disabled?: boolean;
  draggable?: boolean;
  showFilePreview?: boolean;
}

interface FileUploadWrapperProps {
  drag?: boolean;
  error?: boolean;
  disabled?: boolean;
}

const FileUploadWrapper = styled(Box)<FileUploadWrapperProps>`
  position: relative;
  border-radius: 4px;

  .content {
    height: 150px;
    padding: 1rem;
    border: 1px dashed;
    border-color: ${({error, disabled}) =>
      error ? "#d32f2f" : disabled ? "#e0e0e0" : "#1976d2"};
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  input {
    //display: none;
    position: ${({drag}) => (drag ? "absolute" : "relative")};
    display: ${({drag}) => (drag ? "block" : "none")};
    inset: 0;
    opacity: 0;
  }
`;

const IGFileUpload: FC<IGFileUploadProps> = ({
  value,
  onChange,
  inputProps,
  multiple = false,
  text = "Import an Excel/CSV file",
  touched = false,
  error = false,
  helperText = "",
  disabled = false,
  draggable = true,
  buttonText = "Add",
  showFilePreview = false,
}) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const [dragActive, setDragActive] = useState(false);
  // We need to change this to reset input when we are removing selected file
  const [inputKey, setInputKey] = useState(Date.now());

  const {accept = "*/*"} =
    inputProps || ({} as InputHTMLAttributes<HTMLInputElement>);

  const handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleChange = (fileList: any) => {
    // file extension validation
    const file = fileList[0];
    if (!file) return;
    const fileExtension = file.name.split(".").pop().toLowerCase();
    const fileType = file.type.split("/")[0]; // Extracts the type from the file's MIME type (e.g., 'image' from 'image/png')

    // Check for MIME type pattern in the accept string (e.g., 'image/*')
    const isMimeTypePattern = accept.includes("/*");

    if (accept !== "*/*") {
      if (isMimeTypePattern) {
        const mimeType = accept.split("/")[0]; // Extracts the MIME type from the accept string (e.g., 'image' from 'image/*')
        if (mimeType !== fileType) {
          alert("Please upload a valid file");
          return;
        }
      } else if (!accept.split(",").map(ext => ext.trim().toLowerCase()).some(ext => ext.includes(fileExtension))) {
        // If not a MIME type pattern, check for specific file extensions
        alert("Please upload a valid file");
        return;
      }
    }
    onChange(fileList);
  };

  const draggableComponent = () => {
    return (
      <FileUploadWrapper
        drag={dragActive || undefined}
        error={touched ? error : undefined}
        onDragEnter={handleDrag}
        onDragOver={handleDrag}
        onDragLeave={handleDrag}
        onDrop={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (e.dataTransfer && e.dataTransfer.files) {
            setDragActive(false);
            handleChange(e.dataTransfer.files);
          }
        }}
        disabled={disabled}
      >
        <div className="content">
          <Typography fontWeight="bold" color={
            disabled ? "text.disabled" : "text.primary"
          }>{text}</Typography>
          <IGButton
            variant="outlined"
            onClick={() => {
              hiddenFileInput.current?.click();
            }}
            disabled={disabled}
          >
            Choose a file
          </IGButton>
          {!multiple && isArray(value) && value.length > 0 && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap={0.5}
              width="100%"
            >
              {showFilePreview && value[0] ? (
              <img
                src={URL.createObjectURL(value[0])}
                alt={value[0]?.name}
                style={{maxWidth: "50px", maxHeight: "50px"}}
              />
            ) : (
              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {value[0]?.name}
              </Typography>
            )}
              <IconButton
                size="small"
                onClick={() => {
                  setInputKey(Date.now());
                  onChange([]);
                }}
              >
                <CancelRounded />
              </IconButton>
            </Box>
          )}
        </div>
        <input
          ref={hiddenFileInput}
          type="file"
          key={inputKey}
          multiple={multiple}
          onChange={(event) => {
            handleChange(event.target.files || new FileList());
            event.target.value = "";
          }}
          accept={accept}
          disabled={disabled}
        />
        {touched && error && (
          <FormHelperText error={true}>{helperText}</FormHelperText>
        )}
      </FileUploadWrapper>
    );
  };

  const standardComponent = () => {
    return (
      <Stack alignItems="start" gap={1}>
        <input
          ref={hiddenFileInput}
          hidden
          type="file"
          key={inputKey}
          multiple={multiple}
          onChange={(event) => {
            handleChange(event.target.files || new FileList());
          }}
          accept={accept}
          disabled={disabled}
        />
        {(multiple || (isArray(value) && !value.length)) && (
          <Stack gap={1}>
            <Typography fontWeight="bold">{text}</Typography>
            <IGButton
              variant="outlined"
              onClick={() => {
                hiddenFileInput.current?.click();
              }}
              disabled={disabled}
              startIcon={<AttachFileRounded />}
            >
              {buttonText}
            </IGButton>
          </Stack>
        )}
        {!multiple && isArray(value) && value.length > 0 && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            gap={0.5}
            width="100%"
          >
            {showFilePreview ? (
              <img
                src={URL.createObjectURL(value[0])}
                alt={value[0]?.name}
                style={{maxWidth: "50px", maxHeight: "50px"}}
              />
            ) : (
              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                color="primary"
              >
                {value[0]?.name}
              </Typography>
            )}
            <IconButton
              size="small"
              onClick={() => {
                setInputKey(Date.now());
                onChange([]);
              }}
              color="error"
            >
              <CancelRounded />
            </IconButton>
          </Box>
        )}
        {multiple && isArray(value) && value.length > 0 && (
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            justifyContent="flex-start"
            gap={0.5}
            width="100%"
          >
            {Array.from(value).map((file, index) => (
              <Box display="flex" width="100%">
                <Typography
                  key={index}
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "80%",
                  }}
                  color="primary"
                >
                  {file.name}
                </Typography>
                <IconButton
                  size="small"
                  onClick={() => {
                    setInputKey(Date.now());
                    onChange(
                      Array.from(value).filter((file, i) => i !== index),
                      true,
                    );
                  }}
                  color="error"
                >
                  <CancelRounded />
                </IconButton>
              </Box>
            ))}
          </Box>
        )}
        {touched && error && (
          <FormHelperText error={true}>{helperText}</FormHelperText>
        )}
      </Stack>
    );
  };

  return <>{draggable ? draggableComponent() : standardComponent()}</>;
};

export default IGFileUpload;
