import React, {useEffect} from "react";
import {Box, Typography} from "@mui/material";
import GuarantorProfileDetails from "./GuarantorProfileDetails";
import GuaranterDocumentDetails from "./GuarantorDocumentDetails";
import GuarantorCreditCheck from "./GuarantorCreditCheck";
import GuarantorSupplementDocuments from "./GuarantorSupplementDocuments";
import GuarantorAddressDetails from "./GuarantorAddressDetails";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import {customerPersonalDetailsEnum} from "app/enums/accounts";
import {
  personalDetailsActions,
  personalDetailsSelectors,
} from "app/store/loanRenewalRebook/personalDetails";
import {
  creditDetailsActions,
  creditDetailsSelectors,
} from "app/store/loanRenewalRebook/creditDetails";
import {IGLoading} from "app/components";

interface GuarantorDetailsProps {
  crId: number;
}
const GuarantorDetails = ({crId}: GuarantorDetailsProps) => {
  const dispatch = useAppDispatch();
  const {
    getKycDocuments: iskycDocLoading,
    getGuarantorPersonalDetails: isPersonalDetailsLoading,
    getGuarantorAddressDetails: isAddressDetailsLoading,
  } = useAppSelector(personalDetailsSelectors.getLoading);
  const {getCreditSessionStatus: isCreditSessionLoading} = useAppSelector(
    creditDetailsSelectors.getLoading,
  );

  useEffect(() => {
    if (crId) {
      dispatch(
        personalDetailsActions.getGuarantorPersonalDetails({
          crId,
          personalDetailType:
            customerPersonalDetailsEnum.GUARANTOR_PERSONAL_DETAIL,
        }),
      );
      dispatch(
        personalDetailsActions.getKycDocuments({
          crId,
          isRenewRebook: false,
          documentTypeList: [
            "GUARANTOR_AADHAAR",
            "GUARANTOR_PAN",
            "GUARANTOR_CO_BORROWER_FORM",
            "GUARANTOR_BANK_STATEMENT",
            "GUARANTOR_POST_DATED_CHEQUE",
            "GUARANTOR_RESIDENCE_PROOF",
          ],
        }),
      );
      dispatch(
        personalDetailsActions.getGuarantorAddressDetails({
          crId: crId,
          addressType: ["RESIDENTIAL_ADDRESS", "PERMANENT_ADDRESS"],
        }),
      );
      dispatch(
        creditDetailsActions.getCreditSessionStatus({
          crId,
          cibilConsentType: "GUARANTOR_CIBIL_CONSENT",
        }),
      );
    }
  }, []);

  return (
    <>
      {isPersonalDetailsLoading ||
      isAddressDetailsLoading ||
      iskycDocLoading ||
      isCreditSessionLoading ? (
        <IGLoading height="20vh" />
      ) : (
        <Box>
          <Typography fontSize="14px" fontWeight={600}>
            Please verify all the details or send back to LM to proceed
          </Typography>

          <Box mt={3} display="flex" justifyContent="space-between" gap={2}>
            <GuarantorProfileDetails crId={crId} />
            <GuaranterDocumentDetails crId={crId} />
          </Box>
          <Box mt={3} display="flex" justifyContent="space-between" gap={2}>
            <GuarantorCreditCheck crId={crId} />
            <GuarantorSupplementDocuments crId={crId} />
          </Box>
          <Box mt={3}>
            <GuarantorAddressDetails crId={crId} />
          </Box>
        </Box>
      )}
    </>
  );
};

export default GuarantorDetails;
