import React, {memo} from "react";
import {Grid, Box} from "@mui/material";

import {
  IconImgWrapper,
  Item,
  RedItem,
  CustomChip,
  ChipContainer,
  HeaderItem,
} from "./style";
import numberWithCurrencyAndComma from "lib/numberWithCurrencyAndComma";

export interface LenderExposureTableHeaderProps {
  lender: string;
  disbursalAllowed: string;
  activeLoans: string;
  totalExposure: string;
}

export interface LenderExposureTableDataProps {
  lender: string;
  disbursalAllowed: string;
  activeLoans: string;
  totalExposure: number;
  maxExposure: number;
}

export interface LenderExposureTableProps {
  header: LenderExposureTableHeaderProps;
  data: LenderExposureTableDataProps;
}

const GLLenderExposureTable = ({header, data}: LenderExposureTableProps) => {
  return (
    <>
      <GLLenderExposureHeader rowData={header} />
      {Object.values(data).map((item) => (
        <GLLenderExposureRow rowData={item} />
      ))}
    </>
  );
};

const GLLenderExposureHeader = ({
  rowData,
}: {
  rowData: LenderExposureTableHeaderProps;
}) => {
  return (
    <Box m={2}>
      <Grid container>
        <Grid item xs={3} md={3}>
          <HeaderItem>{rowData.lender}</HeaderItem>
        </Grid>
        <Grid item xs={2} md={2}>
          <HeaderItem>{rowData.disbursalAllowed}</HeaderItem>
        </Grid>
        <Grid item xs={2} md={2}>
          <HeaderItem>{rowData.activeLoans}</HeaderItem>
        </Grid>
        <Grid item xs={5} md={5}>
          <HeaderItem>{rowData.totalExposure}</HeaderItem>
        </Grid>
      </Grid>
    </Box>
  );
};

const GLLenderExposureRow = ({
  rowData,
}: {
  rowData: LenderExposureTableDataProps;
}) => {
  return (
    <Box m={2}>
      <Grid container>
        <Grid item xs={3} md={3}>
          <IconImgWrapper src={rowData.lender} alt="lender-icon" />
        </Grid>
        <Grid item xs={2} md={2}>
          {rowData.disbursalAllowed ? <Item>Yes</Item> : <RedItem>No</RedItem>}
        </Grid>
        <Grid item xs={2} md={2}>
          <Item>{rowData.activeLoans}</Item>
        </Grid>
        <Grid item xs={5} md={5}>
          {rowData.disbursalAllowed ? (
            <Item>
              {`${numberWithCurrencyAndComma(
                rowData.totalExposure,
              )} / ${numberWithCurrencyAndComma(rowData.maxExposure)}`}
            </Item>
          ) : (
            <Item>
              <RedItem>
                {`${numberWithCurrencyAndComma(
                  rowData.totalExposure,
                )} / ${numberWithCurrencyAndComma(rowData.maxExposure)}`}
              </RedItem>
              <ChipContainer>
                <CustomChip>Limit Exceeded</CustomChip>
              </ChipContainer>
            </Item>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default memo(GLLenderExposureTable);
