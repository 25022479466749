import React, {useCallback, useRef, useState, useEffect, memo} from "react";
import {HourglassEmpty, Warning} from "@mui/icons-material";

import StatusContainer from "./styles/StatusContainer";
import SendSMSContainer from "./styles/SendSMSContainer";
import Loading from "app/components/Loading";
import ButtonComponent from "app/components/Button";
import getPersonalDetails from "api/business/getPersonalDetail";
import {getGoldLoanTakeoverCreditSessionStatus} from "api/business/getGoldLoanCreditSessionStatus";
import sentTakeoverCibilLink from "api/business/sentTakeoverCibilLink";
import {useAppSelector, useSnackBar} from "app/store/hooks";
import {authSelectors} from "app/store/auth";
import {
  creditReportStatusEnum,
  creditSessionStatusEnum,
  INTERMEDIATE_STATE_LIST,
} from "app/enums/gold_loan/recommendation";
import getGoldLoanRecommendationApi from "api/business/getGoldLoanRecommendation";
import CreditRecommendationDetails from "app/components/CreditRecommendation";
import {getGoldLoanTakeoverCreditReportHtml} from "api/business/getGoldLoanCreditReportHtml";
import getGoldLoanCreditReportStatus from "api/business/getGoldLoanCreditReportStatus";
import GoldenUnderline from "./styles/GoldenUnderline";
import {useIGTranslation} from "app/infra/plugins/i18n";

const timeout_duration = 600000;

const CreditChecks = ({crId}) => {
  const {t, tErrors} = useIGTranslation();
  const accessToken = useAppSelector(authSelectors.getAuthToken);
  const snackbar = useSnackBar();
  const countRef = useRef(0);
  const [loading, setLoading] = useState(false);
  const [linkLoading, setLinkLoading] = useState(false);
  const [cibilScore, setCibilScore] = useState("");
  const [cibilStatus, setCibilStatus] = useState(undefined);
  const mileStonesRequestTimer = 20000;
  const [ltvRecommendations, setLtvRecommendations] = useState(null);
  const [evaluatedRule, setEvaluatedRule] = useState(null);
  const [recLoading, setRecLoading] = useState(false);
  const [creditReportHtml, setCreditReportHtml] = useState(false);
  const [creditReportStatus, setCreditReportStatus] = useState("");
  const [showRecommendation, setShowRecommendation] = useState(true);
  const [creditSessionStatus, setCreditSessionStatus] = useState("");
  const [creditSessionFailureReason, setCreditSessionFailureReason] = useState(
    null,
  );

  const [customerType, setCustomerType] = useState("");
  const getCibilAndCreditSessionData = useCallback(async () => {
    setLoading(true);
    const {response, error} = await getGoldLoanTakeoverCreditSessionStatus({
      accessToken,
      crId,
    });

    if (response) {
      setCibilStatus(response.consentStatus);
      setCreditSessionStatus(response.status);
      setCreditSessionFailureReason(response.failureReason);

      if (
        creditSessionStatusEnum.NO_USER_FOUND === response.status ||
        creditSessionStatusEnum.FAILURE === response.status ||
        creditSessionStatusEnum.NO_SCORE_RECEIVED === response.status ||
        !response.status
      ) {
        setShowRecommendation(false);
      } else if (INTERMEDIATE_STATE_LIST.includes(response.status)) {
        setShowRecommendation(false);
        setCreditSessionStatus(creditSessionStatusEnum.INTERMEDIATE_STATE);
      }
    } else {
      snackbar.showError(error.message || tErrors("CIBIL_CONSENT"));
    }
    setLoading(false);
  }, [accessToken, crId, snackbar, t]);

  const getCibilRecommendation = useCallback(async () => {
    if (!crId) {
      return;
    }
    setRecLoading(true);
    try {
      const {response, error} = await getGoldLoanRecommendationApi(crId);
      if (!error) {
        setEvaluatedRule(response.evaluatedRule);
        setLtvRecommendations(response.ltvRecommendations);
      }
    } catch (error) {}
    setRecLoading(false);
  }, [crId]);

  const getCibilReportHtml = useCallback(async () => {
    if (!crId) {
      return;
    }
    try {
      const {response, error} = await getGoldLoanTakeoverCreditReportHtml(crId);
      if (!error) {
        setCreditReportHtml(response);
      }
    } catch (error) {}
  }, [crId]);

  const fetchPersonalDetails = useCallback(async () => {
    setLoading(true);
    const {payload, statusCode} = await getPersonalDetails({
      accessToken,
      crId,
      personalDetailType: "TAKEOVER_PERSONAL_DETAIL",
    });

    if (statusCode === 200) {
      setCibilScore(payload.cibilScrore);
      setCustomerType(payload.userType);
    }
    setLoading(false);
  }, [accessToken, crId]);

  const getCibilReportStatus = useCallback(async () => {
    if (!crId) {
      return;
    }
    setRecLoading(true);
    const {response, error} = await getGoldLoanCreditReportStatus(crId);
    if (!error) {
      const now = Date.now();
      const end = response.createdAt;
      const duration = now - end;
      if (
        (response.status === creditReportStatusEnum.IN_PROGRESS ||
          response.status === creditReportStatusEnum.PUBLISHED) &&
        duration > timeout_duration
      ) {
        setCreditReportStatus(creditReportStatusEnum.FAILURE);
      } else {
        setCreditReportStatus(response.status);
      }
    } else {
      setCreditReportStatus(creditReportStatusEnum.FAILURE);
      setShowRecommendation(false);
    }
    setRecLoading(false);
  }, [crId]);

  useEffect(() => {
    if (creditReportStatus === creditReportStatusEnum.SUCCESS) {
      getCibilRecommendation();
      getCibilReportHtml();
    }
  }, [creditReportStatus]);

  useEffect(() => {
    if (countRef.current === 1) return;
    countRef.current = 1;
    getCibilAndCreditSessionData();
    fetchPersonalDetails();
  }, [getCibilAndCreditSessionData, fetchPersonalDetails]);

  useEffect(() => {
    if (creditSessionStatus === creditSessionStatusEnum.SUCCESS) {
      getCibilReportStatus();
      getCibilReportHtml();
    }
  }, [creditSessionStatus, getCibilReportStatus, getCibilReportHtml]);

  useEffect(() => {
    const timer = setInterval(() => {
      getCibilAndCreditSessionData();
      fetchPersonalDetails();
      if (
        cibilStatus &&
        creditSessionStatus === creditSessionStatusEnum.SUCCESS
      ) {
        getCibilReportStatus();
      }
    }, mileStonesRequestTimer);
    return () => {
      clearInterval(timer);
    };
  }, [
    getCibilAndCreditSessionData,
    fetchPersonalDetails,
    getCibilReportStatus,
    cibilStatus,
    creditSessionStatus,
  ]);

  const handleSendSMS = useCallback(async () => {
    setLinkLoading(true);
    const {statusCode, error} = await sentTakeoverCibilLink({
      accessToken,
      crId,
    });

    if (statusCode === 200) {
      snackbar.showSuccess(t("ACTIVE_LOANS.SMS_SENT_SUCCESSFULLY"));
    } else {
      snackbar.showError(error);
    }
    setLinkLoading(false);
  }, [accessToken, crId, snackbar, t]);

  if (loading) {
    return <Loading height={"20vh"} />;
  }

  const cibilScoreValue = () => {
    if (cibilScore) {
      return cibilScore;
    } else if (creditSessionFailureReason && !loading) {
      return <>{t("ACTIVE_LOANS.PIN_CODE_DOES_NOT_EXIST_IN_OUR_RECORD")}</>;
    } else if (
      creditSessionStatus === creditSessionStatusEnum.NO_USER_FOUND &&
      !loading
    ) {
      return t("ACTIVE_LOANS.CUSTOMER_CREDIT_PROFILE_DOES_NOT_EXIST");
    } else if (
      creditSessionStatus === creditSessionStatusEnum.NO_SCORE_RECEIVED &&
      !loading
    ) {
      return <>{t("ACTIVE_LOANS.CUSTOMER_CREDIT_HISTORY_NOT_FOUND")}</>;
    } else if (
      creditSessionStatus === creditSessionStatusEnum.FAILURE &&
      !loading
    ) {
      return <>{t("ACTIVE_LOANS.PLEASE_ASK_LM_TO_RETRY")}</>;
    } else if (
      creditSessionStatus === creditSessionStatusEnum.INTERMEDIATE_STATE &&
      !loading
    ) {
      return t("ACTIVE_LOANS.PLEASE_WAIT_FOR_LM_TO_FETCH_CREDIT_SCORE");
    } else if (
      !creditSessionStatus &&
      !creditSessionFailureReason &&
      cibilStatus &&
      !loading
    ) {
      return <>{t("ACTIVE_LOANS.PLEASE_ASK_LM_TO_RETRY")}</>;
    }
    return "-";
  };
  const viewCreditReport = () => {
    const win = window.open(
      "",
      "_blank",
      "Title",
      "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=780,height=200,",
    );
    win.document.body.innerHTML = creditReportHtml;
  };
  return (
    <>
      {!cibilStatus && (
        <SendSMSContainer>
          Trigger Consent SMS
          <ButtonComponent
            onClickHandler={handleSendSMS}
            disabled={linkLoading}
          >
            SEND SMS
          </ButtonComponent>
        </SendSMSContainer>
      )}

      <SendSMSContainer>
        User Type
        <StatusContainer>{customerType || "NA"}</StatusContainer>
      </SendSMSContainer>
      <SendSMSContainer>
        CREDIT SCORE
        <StatusContainer>{cibilScoreValue()}</StatusContainer>
      </SendSMSContainer>
      {recLoading && !evaluatedRule && (
        <>
          <Loading height={"5vh"} />
        </>
      )}
      {showRecommendation &&
        cibilStatus &&
        creditReportStatus === creditReportStatusEnum.SUCCESS &&
        evaluatedRule &&
        !recLoading && (
          <CreditRecommendationDetails
            evaluatedRule={evaluatedRule}
            ltvRecommendations={ltvRecommendations}
            creditReportHtml={creditReportHtml}
          />
        )}
      {creditReportHtml && (
        <GoldenUnderline onClick={() => viewCreditReport()}>
          {t("VIEW_CREDIT_REPORT")}
        </GoldenUnderline>
      )}
      {showRecommendation &&
        cibilStatus &&
        (creditReportStatus === creditReportStatusEnum.IN_PROGRESS ||
          creditReportStatus === creditReportStatusEnum.PUBLISHED ||
          creditReportStatus === "") &&
        !recLoading && (
          <SendSMSContainer className="d-flex justify-content-center">
            <span>
              <HourglassEmpty color="#FF7900" />
              {t("ACTIVE_LOANS.PLEASE_WAIT_WHILE_WE_FETCH")}
            </span>
          </SendSMSContainer>
        )}
      {showRecommendation &&
        cibilStatus &&
        creditReportStatus === creditReportStatusEnum.FAILURE &&
        !recLoading && (
          <SendSMSContainer className="d-flex justify-content-center">
            <span>
              <Warning color={"Error"} />
              {t("ACTIVE_LOANS.CAN_NOT_GENERATE_RECOMMENDATION")}
            </span>
          </SendSMSContainer>
        )}
    </>
  );
};

export default memo(CreditChecks);
