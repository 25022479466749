import React, {useCallback, useState, useEffect, memo} from "react";
import {useSnackBar} from "app/store/hooks";
import LenderExposureTable from "./LenderExposureTable";
import {loanTypeEnum} from "app/enums/customerProfiles";
import {GetLenderConfig} from "api/business/getLenderConfig";
import {GetLenderExposure} from "api/activeLoan/getLenderExposure";
import Loading from "app/components/Loading";

const Headers = {
  lender: "Lender",
  disbursalAllowed: "Disbursal Allowed",
  activeLoans: "Active Loans",
  totalExposure: "Total Exposure",
};

const LenderExposure = ({crId, setShowAlert}) => {
  const {showError} = useSnackBar();
  const [lenderList, setLenderList] = useState([]);
  const [lenderExposureData, setLenderExposureData] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchLenderConfig = useCallback(async () => {
    setLoading(true);
    const {response, error} = await GetLenderConfig({
      loanType: loanTypeEnum.GOLD_LOAN,
    });
    if (response) {
      const tempData = {};
      let tempLenderList = [];
      response.forEach((item) => {
        tempLenderList = [...tempLenderList, item.lenderName];
        tempData[item.lenderName] = {
          lender: item.icon,
          disbursalAllowed: "",
          activeLoans: "",
          totalExposure: "",
          maxExposure: item.maxAmountExposure,
        };
      });
      setLenderExposureData(tempData);
      setLenderList(tempLenderList);
    } else {
      showError(error.message);
    }
    setLoading(false);
  }, []);

  const fetchLenderExposureDetail = useCallback(
    async (lenderName) => {
      if (Object.keys(lenderExposureData).length <= 0) {
        return;
      }
      setLoading(true);
      const {response, error} = await GetLenderExposure({
        crId,
        lenderName,
      });
      if (response) {
        const tempLenderExposureData = {
          ...lenderExposureData[lenderName],
          disbursalAllowed: response.isDisbursalAllowed,
          activeLoans: response.activeLoanCount,
          totalExposure: response.totalLoanAmountForActiveLoan,
        };
        if (!response?.isDisbursalAllowed) {
          /* eslint-disable no-console */
          console.log(setShowAlert, "setShowAlert");
          setShowAlert && setShowAlert(true);
        }
        setLenderExposureData((oldData) => {
          return {
            ...oldData,
            [lenderName]: tempLenderExposureData,
          };
        });
      } else {
        showError(error.message);
      }
      setLoading(false);
    },
    [lenderExposureData],
  );

  const fetchAllLenderExposureDetail = async (list) => {
    list.forEach((item) => {
      fetchLenderExposureDetail(item);
    });
  };

  useEffect(() => {
    fetchLenderConfig();
  }, []);

  useEffect(() => {
    if (lenderList.length > 0) {
      fetchAllLenderExposureDetail(lenderList);
    }
  }, [lenderList]);

  if (loading) {
    return <Loading height="10vh" />;
  }

  return <LenderExposureTable header={Headers} data={lenderExposureData} />;
};

export default memo(LenderExposure);
