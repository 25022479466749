import {Box, Grid, Typography, Stack} from "@mui/material";
import {DownloadRounded} from "@mui/icons-material";
import {IGButton, IGCarousel, IGLoading} from "app/components";
import React, {useCallback, useEffect, useState} from "react";
import OrnamentsMenu from "../OrnamentsMenu";
import {OrnamentsSelectors, ornamentsActions} from "app/store/ornaments";
import {useAppDispatch, useAppSelector, useSnackBar} from "app/store/hooks";
import {useFormik} from "formik";
import {
  GetOrnamentsNames,
  OrnamentsFormValues,
} from "app/models/ornaments.model";
import {
  setOrnamentFormValues,
  setSelectedOrnamentId,
} from "app/store/ornaments/ornaments.reducer";
import HallmarkImg from "./hallmark.jpg";
import WeightInfoTable from "./WeightInfoTable";
import ImpurityInfoTable from "./ImpurityInfoTable";
import {authSelectors} from "app/store/auth";
import getNextJob from "api/business/getNextJob";
import updateStatus from "api/business/updateStatus";
import {resetOrnamentsList} from "app/store/ornaments/ornaments.reducer";
import {
  useIGValidations,
  useNumberValidations,
  useStringValidations,
} from "app/validations";
interface OrnamentDetailsProps {
  crId: number;
  crJobId: number;
  isVisible: boolean;
  disableForm?: boolean;
  isLender?: boolean;
  getMileStoneOfCr?: () => void;
  onViewClickHandler?: () => void;
}

const OrnamentDetails: React.FC<OrnamentDetailsProps> = ({
  crId,
  crJobId,
  isVisible,
  disableForm = false,
  isLender = false,
  getMileStoneOfCr,
  onViewClickHandler,
}) => {
  const dispatch = useAppDispatch();
  const {showError, showSuccess} = useSnackBar();
  const accessToken = useAppSelector(authSelectors.getAuthToken);
  const {
    updateOrnamentDetails: isUpdateOrnamentDetailsLoading,
    getSelectedOrnamentDetails: isSelectedOrnamentDetailsLoading,
    getOrnamentsNamesList: isOrnamentsMenuLoading,
  } = useAppSelector(OrnamentsSelectors.getLoading);
  const selectedOrnamentDetails = useAppSelector(
    OrnamentsSelectors.getSelectedOrnamentsDetails,
  );
  const ornamentsNamesList = useAppSelector(
    OrnamentsSelectors.getOrnamentsNamesList,
  );
  const selectedOrnamentId = useAppSelector(
    OrnamentsSelectors.getSelectedOrnamentId,
  );
  const ornamentsList = useAppSelector(OrnamentsSelectors.getOrnamentsList);
  const {downloadOrnamentsCsv: isDownloadCsvLoading} = useAppSelector(
    OrnamentsSelectors.getLoading,
  );
  const [isUpdateFinalStatusLoading, setIsUpdateFinalStatusLoading] = useState<
    boolean
  >(false);
  const [ornamentsCarouselImages, setOrnamentsCarouselImages] = useState<
    {
      title: string;
      url: string;
    }[]
  >([]);

  const {
    id,
    ornamentType,
    quantity,
    newFinenessVal,
    newImpurityWeightInGrams,
    newOtherImpurityWeight,
    newStoneWeightInGrams,
    grossWeightInGrams,
    netWeightInGrams,
    finenessVal,
    newNetWeightInGrams,
    stoneWeightInGrams,
    impurityWeightInGrams,
    otherImpurityWeight,
    crOrnamentImageResponseDtoList = [],
    isHallmark,
  } = selectedOrnamentDetails || {};

  const onClickDownloadOrnamentsCsv = () => {
    if (crId) {
      dispatch(
        ornamentsActions.downloadOrnamentsCsv({
          crId: crId,
          isLenderFlow: false,
        }),
      );
    }
  };

  const onSubmitHandler = useCallback(async () => {
    try {
      setIsUpdateFinalStatusLoading(true);

      const response = await getNextJob({
        accessToken,
        crId,
        jobType: "CM_PROCESS_LOAN",
      });

      if (response.statusCode !== 200) {
        // showError(response?.error);
        setIsUpdateFinalStatusLoading(false);
        return;
      }

      const ornamentsResponse = await updateStatus({
        accessToken,
        crId,
        crJobId: response.payload.id,
        remark: "UPDATED",
        status: "CM_PROCESS_LOAN_GOLD_VALUATION_UPDATED",
        remarkEnum: "",
      });

      if (ornamentsResponse.statusCode !== 200) {
        showError(ornamentsResponse?.error);
        setIsUpdateFinalStatusLoading(false);
        return;
      }
      setIsUpdateFinalStatusLoading(false);
      showSuccess("Success");
      getMileStoneOfCr && getMileStoneOfCr();
      dispatch(resetOrnamentsList());
      onViewClickHandler && onViewClickHandler();
    } catch (error) {
      // handle error
    }
  }, [
    accessToken,
    crId,
    showSuccess,
    getMileStoneOfCr,
    dispatch,
    onViewClickHandler,
    showError,
  ]);

  const handleSubmitOrnamentInfo = async (values: OrnamentsFormValues) => {
    dispatch(
      ornamentsActions.updateOrnamentDetails({
        crId: crId,
        payload: {
          crOrnamentWeightKarateChangeRequestDtoList: [
            {
              crOrnamentId: Number(selectedOrnamentId),
              newFinenessVal: values.newFinenessVal,
              newImpurityWeightInGrams: values.newImpurityWeightInGrams,
              newOtherImpurityWeight: values.newOtherImpurityWeight,
              newStoneWeightInGrams: values.newStoneWeightInGrams,
            },
          ],
        },
      }),
    );
  };

  const validationSchema = useIGValidations({
    newFinenessVal: useStringValidations({
      required: "Purity is required",
    }),
    newImpurityWeightInGrams: useNumberValidations({
      required: "Required",
    }),
    newOtherImpurityWeight: useNumberValidations({
      required: "Required",
    }),
    newStoneWeightInGrams: useNumberValidations({
      required: "Required",
    }),
  });

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    resetForm,
    handleChange,
    handleBlur,
    isValid,
  } = useFormik<OrnamentsFormValues>({
    enableReinitialize: true,
    initialValues: {
      newFinenessVal: newFinenessVal || "",
      newImpurityWeightInGrams: newImpurityWeightInGrams || 0,
      newOtherImpurityWeight: newOtherImpurityWeight || 0,
      newStoneWeightInGrams: newStoneWeightInGrams || 0,
    },
    validationSchema: validationSchema,
    validateOnChange: true,
    onSubmit: async (values, actions) => {
      actions.setSubmitting(false);
      await handleSubmitOrnamentInfo(values);
      resetForm({values});
    },
  });

  const weightAndImpurityInfo = {
    makerGrossWeight: grossWeightInGrams || 0,
    makerNetWeight: netWeightInGrams || 0,
    makerPurity: finenessVal || "",
    checkerGrossWeight: grossWeightInGrams || 0,
    checkerNetWeight: newNetWeightInGrams || 0,
    checkerPurity: values.newFinenessVal || "",
    makerStoneWeight: stoneWeightInGrams || 0,
    checkerStoneWeight: values.newStoneWeightInGrams,
    makerImpurityWeight: impurityWeightInGrams || 0,
    checkerImpurityWeight: values.newImpurityWeightInGrams,
    makerOtherImpurityWeight: otherImpurityWeight || 0,
    checkerOtherImpurityWeight: values.newOtherImpurityWeight,
  };

  useEffect(() => {
    return () => {
      dispatch(
        setSelectedOrnamentId({
          ornamentId: null,
        }),
      );
    };
  }, [dispatch]);

  useEffect(() => {
    if (crId) {
      dispatch(
        ornamentsActions.getOrnamentsList({
          crId,
          isLender,
        }),
      );
      dispatch(
        ornamentsActions.getOrnamentsNamesList({
          crId,
          isLender,
        }),
      );
      dispatch(
        ornamentsActions.getOrnamentsFinenessConfig({
          crId,
          isLender,
        }),
      );
    }
  }, [crId, dispatch, isLender]);

  useEffect(() => {
    if (selectedOrnamentId) {
      dispatch(
        ornamentsActions.getSelectedOrnamentDetails({
          crId: crId,
          ornamentId: Number(selectedOrnamentId),
          isLender,
        }),
      );
    }
  }, [crId, selectedOrnamentId, dispatch, isLender]);

  useEffect(() => {
    if (selectedOrnamentDetails) {
      //  eslint-disable-next-line max-len
      const imageList = crOrnamentImageResponseDtoList.map((image) => ({
        title: image.ornamentImageType.replaceAll("_", " "),
        url: image.imageUrl,
      }));
      setOrnamentsCarouselImages([...imageList]);
    }
  }, [selectedOrnamentDetails, crOrnamentImageResponseDtoList]);

  useEffect(() => {
    if (ornamentsList.length > 0 && selectedOrnamentId) {
      dispatch(
        setOrnamentFormValues({
          ornamentId: Number(selectedOrnamentId),
          values: values,
        }),
      );
    }
  }, [dispatch, values, ornamentsList, selectedOrnamentId]);

  return (
    <Box>
      <Grid container>
        <Grid item xs={2}>
          {isOrnamentsMenuLoading ? (
            <IGLoading height="80vh" />
          ) : (
            <OrnamentsMenu
              crId={crId}
              ornamentsNamesList={ornamentsNamesList}
            />
          )}
        </Grid>
        {isSelectedOrnamentDetailsLoading ||
        !selectedOrnamentDetails ||
        !selectedOrnamentId ? (
          <Grid item xs={10}>
            <IGLoading height="80vh" />
          </Grid>
        ) : (
          <>
            <Grid item xs={5} p={1} pt={0}>
              <Stack gap={2}>
                <Stack p={1} px={2} borderRadius={1} bgcolor="#f6f6f6" gap={2}>
                  <Box>
                    <Typography color="gray" fontSize="12px">
                      ORNAMENT INFO
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      p={2}
                      border="1px solid #E0E0E0"
                      borderRadius={1}
                      mt={1}
                    >
                      <Box>
                        <Typography fontSize="11px">ID: {id}</Typography>
                        <Typography mt={1} fontSize="12px">
                          {ornamentType}
                        </Typography>
                      </Box>
                      <Box>
                        {isHallmark && (
                          <img src={HallmarkImg} height="26px" width="36px" />
                        )}
                        <Typography>Qty: {quantity}</Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <Typography color="gray" fontSize="12px">
                      WEIGHT INFO
                    </Typography>
                    <WeightInfoTable
                      weightInfo={weightAndImpurityInfo}
                      handleChange={handleChange}
                      disableForm={disableForm}
                      selectedOrnamentId={selectedOrnamentId}
                      isLender={isLender}
                    />
                  </Box>
                  <Box>
                    <Typography color="gray" fontSize="12px">
                      IMPURITY INFO
                    </Typography>
                    <ImpurityInfoTable
                      weightInfo={weightAndImpurityInfo}
                      handleChange={handleChange}
                      disableForm={disableForm}
                      selectedOrnamentId={selectedOrnamentId}
                      handleBlur={handleBlur}
                      errors={errors}
                      touched={touched}
                      isLender={isLender}
                    />
                  </Box>
                  {!isLender && (
                    <Box>
                      <IGButton
                        size="medium"
                        loading={isSubmitting || isUpdateOrnamentDetailsLoading}
                        disabled={
                          !isValid ||
                          !isVisible ||
                          !crJobId ||
                          ornamentsNamesList.find(
                            (item: GetOrnamentsNames) =>
                              item.id === selectedOrnamentId,
                          )?.isCheckedByCm
                        }
                        onClick={() => handleSubmit()}
                      >
                        Confirm Ornament
                      </IGButton>
                    </Box>
                  )}
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={5}>
              {!isLender && (
                <Box display="flex" justifyContent="flex-end" gap={2} mb={1}>
                  <IGButton
                    onClick={onClickDownloadOrnamentsCsv}
                    loading={isDownloadCsvLoading}
                    startIcon={<DownloadRounded />}
                    sx={{
                      bgcolor: "#000",
                      color: "#fff",
                    }}
                  >
                    DOWNLOAD CSV
                  </IGButton>
                  <IGButton
                    onClick={onSubmitHandler}
                    loading={isUpdateFinalStatusLoading}
                    disabled={
                      !isVisible ||
                      !crJobId ||
                      !ornamentsNamesList.every((item) => item.isCheckedByCm)
                    }
                  >
                    Update Final Status
                  </IGButton>
                </Box>
              )}
              <IGCarousel
                showHeaderName={false}
                loading={isSelectedOrnamentDetailsLoading}
                imageList={ornamentsCarouselImages}
                showThumbnailPreview={true}
                maxImageHeight="65vh"
                maxImageWidth="400px"
                orientation="vertical"
                containerHeight="75vh"
              />
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default OrnamentDetails;
