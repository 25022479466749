import React, {useState} from "react";
import {Box, Grid} from "@mui/material";
import {
  PictureAsPdfOutlined,
  SkipNext,
  SkipPrevious,
} from "@mui/icons-material";
import ImageComponent from "../Image";
import IGModal from "../Modal/IGModal";
import {StepWrapper, StepsContainer, NoteContainer} from "./styled";
import IGButton from "../Button/IGButton";

interface DocViewModalProps {
  open: boolean;
  onClose: () => void;
  imageList: {images: string[], fileType: string};
  imageTitles?: string[];
  title?: string;
  CustomBottom?: () => JSX.Element;
  imageTitle?: string;
  style?: React.StyleHTMLAttributes<HTMLImageElement>;
  comparisonImage?: string;
  comparisonImageTitle?: string;
  download?: boolean;
}

const DocViewModal: React.FC<DocViewModalProps> = ({
  open,
  title = "",
  CustomBottom,
  onClose,
  imageTitle = "",
  imageTitles = [],
  imageList: imageListWithFileType,
  comparisonImage = "",
  comparisonImageTitle = "",
  download = false,
}) => {
  const [count, setCount] = useState(0);
  const {innerHeight} = window;
  const imageHeight = innerHeight * (CustomBottom ? 0.45 : 0.65);
  const imageList = imageListWithFileType.images;
  const isPdf = imageListWithFileType.fileType.includes("pdf");

  const onClickHandlePrev = () => {
    if (count > 0) {
      setCount((count) => count - 1);
    }
  };
  const onClickHandleNext = () => {
    if (count < imageList.length - 1) {
      setCount((count) => count + 1);
    }
  };

  return (
    <>
      <IGModal
        open={open}
        onClose={onClose}
        title={title}
        subtitle={`Documents Uploaded (${imageList?.length})`}
      >
        <NoteContainer container>
          <Grid item xs={comparisonImage ? 6 : 12}>
            {isPdf ? (
              <PictureAsPdfOutlined
                style={{fontSize: "5rem", color: "#FF0000"}}
              />
            ) : (
              <ImageComponent
                src={imageList[count]}
                height={imageHeight}
                width={imageHeight}
                imageTitle={imageTitle || imageTitles[count]}
                rotate
                zoom
              />
            )}
            {imageList.length > 1 ? (
              <StepsContainer>
                <StepWrapper
                  variant="contained"
                  color="primary"
                  highLight={count === 0}
                  disabled={count === 0}
                  onClick={onClickHandlePrev}
                  startIcon={<SkipPrevious />}
                >
                  Prev
                </StepWrapper>
                <StepWrapper
                  variant="contained"
                  color="primary"
                  highLight={imageList.length - 1 === count}
                  disabled={imageList.length - 1 === count}
                  onClick={onClickHandleNext}
                  endIcon={<SkipNext />}
                >
                  Next
                </StepWrapper>
              </StepsContainer>
            ) : (
              <></>
            )}
          </Grid>
          {comparisonImage && (
            <Grid item xs={6}>
              <ImageComponent
                src={comparisonImage}
                height={imageHeight}
                width={imageHeight}
                imageTitle={comparisonImageTitle}
                rotate
                zoom
              />
            </Grid>
          )}
          <Grid item xs={12}>
            {CustomBottom ? <CustomBottom /> : <></>}
          </Grid>
          <Box
            sx={{
              width: "100%",
              justifyContent: "end",
              display: "flex",
              marginTop: "2rem",
            }}
          >
            {(download || isPdf) && (
              <IGButton
                onClick={async () => {
                  const link = document.createElement("a");
                  link.href = imageList[count];
                  link.download = "image.jpg";
                  link.target = "_blank";
                  document.body.appendChild(link);
                  link.click();
                }}
              >
                Download
              </IGButton>
            )}
          </Box>
        </NoteContainer>
      </IGModal>
    </>
  );
};

export default DocViewModal;
