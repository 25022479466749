import {goldApiService} from "app/infra/plugins/axios";

import {
  CreditDetailsApiResources,
  CreditDetailsApiInterface,
  CrIdPayload,
  CreditSessionStatusPayload,
  GetTakeoverCreditReportHtmlPayload,
} from "app/store/loanRenewalRebook/creditDetails/creditDetails.types";

const CREDIT_DETAILS_ENDPOINTS: CreditDetailsApiResources = {
  INITIATE_CREDIT_CONSENT: {
    URL: "/admin/renew-rebook/:crId/cibil",
    METHOD: "POST",
  },
  GET_CREDIT_SCORE_DETAILS: {
    URL: "/admin/renew-rebook/:crId/credit-score",
    METHOD: "GET",
  },

  GET_TAKEOVER_CIBIL_HTML: {
    URL: "/business/cr/:crId/takeover/credit-report-html",
    METHOD: "GET",
  },

  GET_CREDIT_SESSION_STATUS: {
    URL: "/business/cr/:crId/takeover/cibil-session",
    METHOD: "GET",
  },
};

const creditDetailsApi: CreditDetailsApiInterface = {
  getCreditScoreDetails: async ({crId}: CrIdPayload) => {
    return await goldApiService({
      resource: CREDIT_DETAILS_ENDPOINTS.GET_CREDIT_SCORE_DETAILS,
      options: {
        pathVars: {
          crId: crId,
        },
      },
    });
  },
  initiateCreditConsent: async ({crId}: CrIdPayload) => {
    return await goldApiService({
      resource: CREDIT_DETAILS_ENDPOINTS.INITIATE_CREDIT_CONSENT,
      options: {
        pathVars: {
          crId: crId,
        },
      },
    });
  },

  getTakeoverCreditReportHtml: async ({
    crId,
    cibilConsentType,
  }: GetTakeoverCreditReportHtmlPayload) => {
    return await goldApiService({
      resource: CREDIT_DETAILS_ENDPOINTS.GET_TAKEOVER_CIBIL_HTML,
      options: {
        pathVars: {
          crId: crId,
        },
        queryParams: {
          cibilConsentType,
        },
      },
    });
  },

  getCreditSessionStatus: async ({
    crId,
    cibilConsentType,
  }: CreditSessionStatusPayload) => {
    return await goldApiService({
      resource: CREDIT_DETAILS_ENDPOINTS.GET_CREDIT_SESSION_STATUS,
      options: {
        pathVars: {
          crId: crId,
        },
        queryParams: {
          cibilConsentType,
        },
      },
    });
  },
};

export {CREDIT_DETAILS_ENDPOINTS};
export default creditDetailsApi;
