import {goldApiService} from "app/infra/plugins/axios";
import {OccupationAndOtherDetailsForm} from "app/models/loanRenewalRebook/occupationDetails.model";
import {
  OccupationDetailsApiInterface,
  OccupationDetailsApiResources,
  GetOccupationDetailsPayload,
  GetWorkSectorDetailsPayload,
  PostLoanRequirementReasonPayload,
} from "app/store/loanRenewalRebook/occupationDetails/occupationDetails.types";

const OCCUPATION_DETAILS_ENDPOINTS: OccupationDetailsApiResources = {
  GET_OCCUPATION_DETAILS: {
    URL: "admin/renew-rebook/:crId/employment-detail",
    METHOD: "GET",
  },
  UPDATE_OCCUPATION_DETAILS: {
    URL: "admin/renew-rebook/:crId/employment-detail",
    METHOD: "POST",
  },
  GET_WORK_SECTOR_DETAIL: {
    URL: "admin/renew-rebook/sector-detail",
    METHOD: "GET", //?sectorid
  },

  POST_LOAN_REQUIREMENT_REASON: {
    URL: "business/cr/:crId/attribute",
    METHOD: "POST",
  },
};

const occupationDetailsApi: OccupationDetailsApiInterface = {
  getOccupationDetails: async (payload: GetOccupationDetailsPayload) => {
    return await goldApiService({
      resource: OCCUPATION_DETAILS_ENDPOINTS.GET_OCCUPATION_DETAILS,
      options: {
        pathVars: {
          crId: payload.crId,
        },
      },
    });
  },
  updateOccupationDetails: async (
    crId: number,
    payload: OccupationAndOtherDetailsForm,
  ) => {
    return await goldApiService({
      resource: OCCUPATION_DETAILS_ENDPOINTS.UPDATE_OCCUPATION_DETAILS,
      options: {
        pathVars: {
          crId: crId,
        },
        data: {
          ...payload,
        },
      },
    });
  },
  getWorkSectorDetails: async ({sectorId}: GetWorkSectorDetailsPayload) => {
    return await goldApiService({
      resource: OCCUPATION_DETAILS_ENDPOINTS.GET_WORK_SECTOR_DETAIL,
      options: {
        queryParams: {
          sectorId,
        },
      },
    });
  },

  postLoanRequirementReason: async ({
    crId,
    loanRequirementReason,
    loanRequirementReasonDisplayValue,
  }: PostLoanRequirementReasonPayload) => {
    return await goldApiService({
      resource: OCCUPATION_DETAILS_ENDPOINTS.POST_LOAN_REQUIREMENT_REASON,
      options: {
        pathVars: {
          crId,
        },
        data: {
          crId,
          loanRequirementReason,
          loanRequirementReasonDisplayValue,
        },
      },
    });
  },
};

export {OCCUPATION_DETAILS_ENDPOINTS};
export default occupationDetailsApi;
