import React from "react";
import {Box, Typography} from "@mui/material";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import {personalDetailsSelectors} from "app/store/loanRenewalRebook/personalDetails";
import GuarantorDocView from "../GuarantorDocView";

interface GuaranterDocumentDetailsProps {
  crId: number;
}
const GuaranterDocumentDetails = ({crId}: GuaranterDocumentDetailsProps) => {
  const dispatch = useAppDispatch();
  const guarantorIdentifyDocs = useAppSelector(
    personalDetailsSelectors.getKycDocuments,
  );

  return (
    <Box
      bgcolor="#fff"
      borderRadius="4px"
      width="40%"
      border="1px solid rgba(0, 0, 0, 0.12)"
      p={1}
    >
      {guarantorIdentifyDocs
        .filter(
          (i) =>
            i.documentType === "GUARANTOR_AADHAAR" ||
            i.documentType === "GUARANTOR_PAN",
        )
        .map((item) => (
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Typography color="text.secondary" variant="body1">
                {item.documentType === "GUARANTOR_AADHAAR"
                  ? "AADHAAR NUMBER"
                  : "PAN NUMBER"}
              </Typography>
              <Typography>{item?.documentNumber}</Typography>
            </Box>
            <Box>
              <GuarantorDocView guarantorDocument={item} />
            </Box>
          </Box>
        ))}
    </Box>
  );
};

export default GuaranterDocumentDetails;
