import React from "react";
import {useParams} from "react-router-dom";
import {
  Box,
  Typography,
  Stack,
  InputAdornment,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import {IGDialog, IGFileUpload, IGSelect, IGTextField} from "app/components";
import {closeModal} from "app/store/customerProfile/customerProfile.reducer";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import IGKeyValue from "app/components/IGLabelValue";
import numberWithCurrencyAndComma from "lib/numberWithCurrencyAndComma";
import {
  loanPaymentActions,
  loanPaymentSelectors,
} from "app/store/loanPayment";
import {useIGDialog} from "app/store/hooks/IGDialogHook";

interface RefundFormData {
  paymentId: number;
  paymentAmount: number;
  refundReason: string;
  remark: string;
  internalApprovalAttachment: any;
  shivalikConfirmationAttachment: any;
  isDoneAtShivalikEnd: boolean;
}

const RefundModal = ({
  paymentId,
  loanPaymentId,
  paymentAmount,
}: {
  paymentId: number;
  loanPaymentId: string;
  paymentAmount: number;
}) => {
  const dispatch = useAppDispatch();
  const {showConfirmDialog} = useIGDialog();
  const {refundPayment: refundPaymentLoading} = useAppSelector(
    loanPaymentSelectors.getLoading,
  );
  const [formData, setFormData] = React.useState<RefundFormData>({
    paymentId: paymentId ?? 0,
    paymentAmount: paymentAmount ?? 0,
    refundReason: "",
    remark: "",
    internalApprovalAttachment: [],
    shivalikConfirmationAttachment: [],
    isDoneAtShivalikEnd: false,
  });

  const isSubmitDisabled =
    !formData.refundReason ||
    (formData.refundReason === "OTHERS" && !formData.remark) ||
    !formData.internalApprovalAttachment.length ||
    !formData.shivalikConfirmationAttachment.length ||
    formData.isDoneAtShivalikEnd === false;

  const onSubmit = (formPayload: RefundFormData) => {
    showConfirmDialog({
      content: (
        <Box display="flex" justifyContent="center" flexDirection="column">
          <Typography fontWeight="bold" variant="h6">
            Confirm Refund Payment
          </Typography>
          <Stack
            mt={2}
            sx={{
              border: "1px solid #E0E0E0",
              borderRadius: "4px",
              backgroundColor: "#F9F9F9",
              padding: "16px",
              width: "550px",
            }}
          >
            <IGKeyValue label="Payment ID" value={formData.paymentId ?? 0} />
            <IGKeyValue
              label="Payment Amount"
              value={numberWithCurrencyAndComma(formData.paymentAmount)}
            />
            <IGKeyValue
              label="Reason"
              value={
                formData.refundReason === "OTHERS"
                  ? formData.remark
                  : formData.refundReason
              }
            />
            <IGKeyValue
              label="IG Approval File"
              value={formData.internalApprovalAttachment[0]?.name}
            />
            <IGKeyValue
              label="Shivalik Approval File"
              value={formData.shivalikConfirmationAttachment[0]?.name}
            />
          </Stack>
        </Box>
      ),
      onConfirm: () => {
        const formData = new FormData();
        formData.append("refundReason", formPayload.refundReason);
        formData.append("remark", formPayload.remark);
        formData.append(
          "isDoneAtShivalikEnd",
          formPayload.isDoneAtShivalikEnd.toString(),
        );
        formData.append(
          "internalApprovalAttachment",
          formPayload.internalApprovalAttachment[0],
        );
        formData.append(
          "shivalikConfirmationAttachment",
          formPayload.shivalikConfirmationAttachment[0],
        );
        dispatch(
          loanPaymentActions.refundPayment({
            paymentId: paymentId.toString(),
            formData,
          }),
        );
      },
      maxWidth: "sm",
    });
  };

  return (
    <IGDialog
      title="Refund Payment"
      open={true}
      onClose={() => {
        dispatch(closeModal("refund"));
      }}
      onCancel={() => {
        dispatch(closeModal("refund"));
      }}
      confirmProps={{
        text: "Submit",
        disabled: isSubmitDisabled,
        loading: refundPaymentLoading,
      }}
      onConfirm={() => {
        onSubmit(formData);
      }}
    >
      <Box>
        <Typography color="#FF7043">
          Please verify the refund payment details as this action cannot be
          reversed!
        </Typography>
        <Stack spacing={2} mt={2}>
          <IGTextField
            name="paymentId"
            label="Payment ID"
            value={loanPaymentId ?? ""}
            disabled
          />
          <IGTextField
            name="paymentAmount"
            label="Payment Amount"
            value={formData.paymentAmount}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">₹</InputAdornment>
              ),
            }}
            disabled
          />
          <IGSelect
            name="refundReason"
            label="Reason"
            options={[
              {text: "Wrong VA Shared By Agent", value: "WRONG_VA_SHARED_BY_AGENT"},
              {text: "Customer Made Payment in Wrong VA", value: "CUSTOMER_MADE_PAYMENT_IN_WRONG_VA"},
              {text: "Duplicate Payment", value: "DUPLICATE_PAYMENT"},
              {text: "Excess Payment Manual", value: "EXCESS_AMOUNT_MANUAL"},
              {text: "Others", value: "OTHERS"},
            ]}
            value={formData.refundReason}
            onChange={(e) => {
              setFormData({...formData, refundReason: e.target.value});
            }}
            required
          />
          <Box>
            <IGTextField
              name="remark"
              label="Other Remarks"
              placeholder="Enter your remarks here..."
              multiline
              rows={3}
              value={formData.remark}
              onChange={(e) => {
                setFormData({...formData, remark: e.target.value});
              }}
              inputProps={{
                maxLength: 500,
              }}
              required={formData.refundReason === "OTHERS"}
            />
            <Typography variant="body2" color="textSecondary">
              ({formData.remark.length}/500)
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2" color="textSecondary">
              IG Approval Screenshots
            </Typography>
            <IGFileUpload
              text=""
              value={formData.internalApprovalAttachment as any}
              onChange={(file) => {
                setFormData({...formData, internalApprovalAttachment: file});
              }}
              draggable={false}
              inputProps={{
                accept: "image/png, image/jpeg, image/jpg, application/pdf",
              }}
            />
          </Box>
          <Box>
            <FormControl>
              <FormLabel id="shivalik-radio">
                Have you received confirmation from shivalik?
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="shivalik-radio"
                defaultValue="false"
                name="radio-buttons-group"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    isDoneAtShivalikEnd: e.target.value === "true",
                  });
                }}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box>
            <Typography variant="body2" color="textSecondary">
              Shivalik Approval Screenshots
            </Typography>
            <IGFileUpload
              text=""
              value={formData.shivalikConfirmationAttachment as any}
              onChange={(file) => {
                setFormData({
                  ...formData,
                  shivalikConfirmationAttachment: file,
                });
              }}
              draggable={false}
              inputProps={{
                accept: "image/png, image/jpeg, image/jpg, application/pdf",
              }}
            />
          </Box>
        </Stack>
      </Box>
    </IGDialog>
  );
};

export default RefundModal;
