import {LeadApiInterface, LeadApiResources} from "app/store/lead/lead.types";
import {goldApiService} from "../../../plugins/axios";

const LEAD_ENDPOINTS: LeadApiResources = {
  GET_PARTNER_LEAD_LIST: {
    URL: "/lead/partner/partner-lead-list",
    METHOD: "GET",
  },
  DOWNLOAD_CSV: {
    URL: "/lead/partner/partner-lead-bulk-upload-template",
    METHOD: "GET",
  },
  ADD_NEW_LEAD: {
    URL: "/lead/partner/partner-lead",
    METHOD: "POST",
  },
  BULK_LEAD_UPLOAD: {
    URL: "/lead/partner/partner-lead-bulk-upload-request",
    METHOD: "POST",
  },
  GET_ANALYTICS: {
    URL: "/lead/partner/partner-lead-analytics",
    METHOD: "GET",
  },
  GET_LEAD_UNQUALIFIED_REASONS: {
    URL: "/lead/unqualified-reason-list",
    METHOD: "GET",
  },
  GET_PARENT_LEAD_ID: {
    URL: "/dsa-lead/:crmLeadId/lead-detail",
    METHOD: "GET",
  },
  GET_PARENT_LEAD_ID_CREDIT_DETAIL: {
    URL: "/parent-lead/credit-report/:parentLeadId/credit-detail",
    METHOD: "GET",
  },
};

const leadApi: LeadApiInterface = {
  getPartnerLeadList: async (payload) => {
    return await goldApiService({
      resource: LEAD_ENDPOINTS.GET_PARTNER_LEAD_LIST,
      options: {
        queryParams: {...payload},
      },
    });
  },
  downloadCSV: async () => {
    return await goldApiService({
      resource: LEAD_ENDPOINTS.DOWNLOAD_CSV,
    });
  },
  addNewLead: async (payload) => {
    return await goldApiService({
      resource: LEAD_ENDPOINTS.ADD_NEW_LEAD,
      options: {
        data: {...payload},
      },
    });
  },
  bulkLeadUpload: async (payload) => {
    return await goldApiService({
      resource: LEAD_ENDPOINTS.BULK_LEAD_UPLOAD,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      options: {
        data: payload.formData,
      },
    });
  },
  getAnalytics: async ({startTime, endTime}) => {
    const payload: Record<string, any> = {};
    if (startTime && endTime) {
      payload.startTime = startTime;
      payload.endTime = endTime;
    }
    return await goldApiService({
      resource: LEAD_ENDPOINTS.GET_ANALYTICS,
      options: {
        queryParams: {...payload},
      },
    });
  },

  getLeadUnQualifiedReasons: async () => {
    return await goldApiService({
      resource: LEAD_ENDPOINTS.GET_LEAD_UNQUALIFIED_REASONS,
    });
  },

  getParentLeadId: async ({crmLeadId}) => {
    return await goldApiService({
      resource: LEAD_ENDPOINTS.GET_PARENT_LEAD_ID,
      options: {
        pathVars: {
          crmLeadId,
        },
      },
    });
  },
  getParentLeadIdCreditDetail: async ({parentLeadId}) => {
    return await goldApiService({
      resource: LEAD_ENDPOINTS.GET_PARENT_LEAD_ID_CREDIT_DETAIL,
      options: {
        pathVars: {
          parentLeadId,
        },
      },
    });
  },
};

export {LEAD_ENDPOINTS};
export default leadApi;
