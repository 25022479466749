import {createAction} from "@reduxjs/toolkit";
import {
  AddNewLeadPayload,
  BulkLeadUploadPayload,
  GetAnalyticsPayload,
  GetLeadListPayload,
  LeadResponse,
  GetParentLeadId,
  GetParentLeadIdCreditDetail,
} from "./lead.types";

const leadActionTypes = {
  GetPartnerLeadList: "[Lead] Get Partner Lead List",
  GetPartnerLeadListSuccess: "[Lead] Get Partner Lead List Success",
  GetPartnerLeadListFailure: "[Lead] Get Partner Lead List Failure",
  DownloadCSV: "[Lead] Download CSV",
  DownloadCSVSuccess: "[Lead] Download CSV Success",
  DownloadCSVFailure: "[Lead] Download CSV Failure",
  AddNewLead: "[Lead] Add New Lead",
  AddNewLeadSuccess: "[Lead] Add New Lead Success",
  AddNewLeadFailure: "[Lead] Add New Lead Failure",
  BulkLeadUpload: "[Lead] Bulk Lead Upload",
  BulkLeadUploadSuccess: "[Lead] Bulk Lead Upload Success",
  BulkLeadUploadFailure: "[Lead] Bulk Lead Upload Failure",
  GetAnalytics: "[Lead] Get Analytics",
  GetAnalyticsSuccess: "[Lead] Get Analytics Success",
  GetAnalyticsFailure: "[Lead] Get Analytics Failure",
  GetLeadUnQualifiedReasons: "[Lead] Get Lead UnQualified",
  GetLeadUnQualifiedReasonsSuccess: "[Lead] Get Lead UnQualified Success",
  GetLeadUnQualifiedReasonsFailure: "[Lead] Get Lead UnQualified Failure",
  GetParentLeadId: "[Lead] Get Parent Lead Id",
  GetParentLeadIdCreditDetail: "[Lead] Get Parent Lead Id Credit Detail",
};

const leadActions = {
  getPartnerLeadList: createAction<GetLeadListPayload>(
    leadActionTypes.GetPartnerLeadList,
  ),
  getPartnerLeadListSuccess: createAction<LeadResponse>(
    leadActionTypes.GetPartnerLeadListSuccess,
  ),
  getPartnerLeadListFailure: createAction<LeadResponse>(
    leadActionTypes.GetPartnerLeadListFailure,
  ),
  downloadCSV: createAction(leadActionTypes.DownloadCSV),
  downloadCSVSuccess: createAction<LeadResponse>(
    leadActionTypes.DownloadCSVSuccess,
  ),
  downloadCSVFailure: createAction<LeadResponse>(
    leadActionTypes.DownloadCSVFailure,
  ),
  addNewLead: createAction<AddNewLeadPayload>(leadActionTypes.AddNewLead),
  addNewLeadSuccess: createAction<LeadResponse>(
    leadActionTypes.AddNewLeadSuccess,
  ),
  addNewLeadFailure: createAction<LeadResponse>(
    leadActionTypes.AddNewLeadFailure,
  ),
  bulkLeadUpload: createAction<BulkLeadUploadPayload>(
    leadActionTypes.BulkLeadUpload,
  ),
  bulkLeadUploadSuccess: createAction<LeadResponse>(
    leadActionTypes.BulkLeadUploadSuccess,
  ),
  bulkLeadUploadFailure: createAction<LeadResponse>(
    leadActionTypes.BulkLeadUploadFailure,
  ),
  getAnalytics: createAction<GetAnalyticsPayload>(leadActionTypes.GetAnalytics),
  getAnalyticsSuccess: createAction<LeadResponse>(
    leadActionTypes.GetAnalyticsSuccess,
  ),
  getAnalyticsFailure: createAction<LeadResponse>(
    leadActionTypes.GetAnalyticsFailure,
  ),

  getLeadUnQualifiedReasons: createAction(
    leadActionTypes.GetLeadUnQualifiedReasons,
  ),
  getLeadUnQualifiedReasonsSuccess: createAction<LeadResponse>(
    leadActionTypes.GetLeadUnQualifiedReasonsSuccess,
  ),
  getLeadUnQualifiedReasonsFailure: createAction<LeadResponse>(
    leadActionTypes.GetLeadUnQualifiedReasonsFailure,
  ),
  getParentLeadId: createAction<GetParentLeadId>(
    leadActionTypes.GetParentLeadId,
  ),
  getParentLeadIdCreditDetail: createAction<GetParentLeadIdCreditDetail>(
    leadActionTypes.GetParentLeadIdCreditDetail,
  ),
};

export default leadActions;

export {leadActionTypes};
