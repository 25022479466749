import React, {FC, useEffect, useMemo} from "react";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import {loanActions, loanSelectors} from "app/store/loan";
import {useNavigate, useParams} from "react-router-dom";
import {Alert, Box, Chip, Divider, Grid, Stack, Typography} from "@mui/material";
import {useIGTranslation} from "app/infra/plugins/i18n";
import {getValidTime} from "_metronic/utils/moment";
import {IGBoxBackdrop, IGSelect} from "app/components";
import ROUTES from "app/constants/routes";
import {loanSummarySelectors} from "app/store/loanSummary";
import {LoanModel} from "app/models/loan.model";
import check from "../../ServiceDesk/Common/utils/getFromConfigList";
import {getAuctionIconByStatus, getAuctionStatusColor} from "../../ServiceDesk/Services/utils";
import {servicesActions, servicesSelectors} from "app/store/serviceDesk/services";
import {RootState} from "app/store/store";
import {featureFlagSelectors} from "app/store/featureFlags";

interface LoanDetailsProps {}

const LoanDetails: FC<LoanDetailsProps> = () => {
  const dispatch = useAppDispatch();
  const {userId, loanId} = useParams();
  const {t} = useIGTranslation();
  const navigate = useNavigate();
  const isMonitoringCheckActive = useAppSelector((state) =>
    featureFlagSelectors.getFeatureFlagStatus(
      state,
      "INTERNAL_DASHBOARD",
      "INTERNAL_DASHBOARD_MONITORING_CHECK",
    ),
  );
  const loanDetails = useAppSelector((state) =>
    loanSelectors.getLoanDetails(state, Number(loanId)),
  );
  const loanSummary = useAppSelector((state) =>
    loanSummarySelectors.getLoanSummary(state, Number(loanId)),
  );
  const userLoans = useAppSelector(loanSelectors.getAllUserLoans);
  const {
    getLoanDetails: getLoanDetailsLoading,
    getAllUserLoans: getAllUserLoansLoading,
  } = useAppSelector(loanSelectors.getLoading);
  const {
    getLoanSummaryDetails: getLoanSummaryDetailsLoading,
  } = useAppSelector(loanSummarySelectors.getLoading);
  const serviceConfig = useAppSelector((state: RootState) =>
    servicesSelectors.getServiceConfig(state, "GOLD_LOAN"),
  );

  const auctionStatusEnumConfig = serviceConfig.auctionStatusResponseDtoList;

  const {
    loanStartDate,
    status,
    loanCloseDate,
    isGoldLoanTaken,
    goldLoanAccountNumber,
    goldLoanIgId,
    glLenderLoanId,
    isPersonalLoanTaken,
    personalLoanAccountNumber,
    personalLoanIgId,
    auctionParentLoanResponseDto,
  } = loanDetails || {};

  const {
    publicationChargeWarning,
    auctionStatus,
  } = auctionParentLoanResponseDto || {};

  const userLoanOptions = useMemo(() => {
    const loanIndex = userLoans.findIndex((l) => l.id === Number(loanId));
    if (loanIndex === -1) {
      return [...userLoans, {id: Number(loanId)} as LoanModel];
    }
    return [...userLoans];
  }, [loanId, userLoans]);

  useEffect(() => {
    if (!loanId) return;
    dispatch(
      loanActions.getAllUserLoans({
        userId: Number(userId),
      }),
    );
    dispatch(
      loanActions.getLoanDetails({
        loanId: Number(loanId),
      }),
    );
  }, [dispatch, loanId, userId]);

  useEffect(() => {
    dispatch(servicesActions.getServiceRequestConfigList({
      product: "GOLD_LOAN",
      requestType: "SERVICE",
    }));
  }, [dispatch]);

  return (
    <Box border="1px solid #D1D5DB" p={2} borderRadius={1} position="relative">
      <IGBoxBackdrop
        loading={getLoanSummaryDetailsLoading || getLoanDetailsLoading}
      />

      <Stack
        direction="row"
        spacing={2}
        divider={<Divider flexItem orientation="vertical" />}
      >
        <Grid item xs={4}>
          <Box display="flex" gap={2} mb={1}>
            <Box flex={1}>
              <Typography variant="subtitle1" color="#64748B">
                {t("LOAN_START_DATE")}:
              </Typography>
              <Typography variant="h6">
                {loanStartDate
                  ? getValidTime(loanStartDate).format("DD MMM, YYYY")
                  : "-"}
              </Typography>
            </Box>
            <Box flex={1}>
              <Typography variant="subtitle1" color="#64748B">
                CR ID:
              </Typography>
              {loanId && !getAllUserLoansLoading && (
                <IGSelect
                  fullWidth={false}
                  name="loanId"
                  value={loanId}
                  onChange={(event) => {
                    navigate(
                      `/${ROUTES.GOLD_LOAN}/customer-profiles/${userId}/${event.target.value}`,
                    );
                  }}
                  options={
                    [...userLoanOptions]?.map((loan) => ({
                      text: loan.id,
                      value: String(loan.id),
                    })) || []
                  }
                />
              )}
            </Box>
          </Box>
          <Box display="flex" gap={2}>
            <Box flex={1}>
              <Typography variant="subtitle1" color="#64748B">
                {t("LOAN_MATURITY_DATE")}:
              </Typography>
              <Typography variant="h6">
                {loanSummary?.maturityDate
                  ? getValidTime(loanSummary?.maturityDate).format(
                      "DD MMM, YYYY",
                    )
                  : "-"}
              </Typography>
            </Box>
            <Box flex={1}>
              <Typography variant="subtitle1" color="#64748B">
                {t("STATUS")}:
              </Typography>
              <Typography variant="h6">{status}</Typography>
            </Box>
          </Box>
          <Box display="flex" gap={2} mt={1}>
            <Box flex={1}>
              <Typography variant="subtitle1" color="#64748B">
                Loan Close Date:
              </Typography>
              <Typography variant="h6">
                {loanCloseDate && status === "CLOSED"
                  ? getValidTime(loanCloseDate).format("DD MMM, YYYY")
                  : "-"}
              </Typography>
            </Box>
            <Box flex={1}>
              {auctionStatus && (
                <Chip
                  label={check(auctionStatus, auctionStatusEnumConfig)}
                  icon={getAuctionIconByStatus(auctionStatus, true)() || <></>}
                  sx={{
                    fontSize: "1rem",
                    ...(getAuctionStatusColor(auctionStatus) as object),
                  }}
                  variant="outlined"
                />
              )}
            </Box>
          </Box>
        </Grid>

        {isGoldLoanTaken && (
          <Grid item xs={4}>
            <Box mb={1}>
              <Typography variant="subtitle1" color="#64748B">
                {t("CUSTOMER_PROFILE.LOAN_TYPE_LAN", {type: "Gold Loan"})}
              </Typography>
              <Typography variant="h6">{goldLoanAccountNumber}</Typography>
            </Box>
            <Box mb={1}>
              <Typography variant="subtitle1" color="#64748B">
                {t("CUSTOMER_PROFILE.LOAN_TYPE_IG_ID", {type: "Gold Loan"})}
              </Typography>
              <Typography variant="h6">{goldLoanIgId}</Typography>
            </Box>
            <Box>
              <Typography variant="subtitle1" color="#64748B">
                SB LAN
              </Typography>
              <Typography variant="h6">{glLenderLoanId || "-"}</Typography>
            </Box>
          </Grid>
        )}

        {isPersonalLoanTaken && !isMonitoringCheckActive && (
          <Grid item xs={4}>
            <Box mb={1}>
              <Typography variant="subtitle1" color="#64748B">
                {t("CUSTOMER_PROFILE.LOAN_TYPE_LAN", {type: "Personal Loan"})}
              </Typography>
              <Typography variant="h6">{personalLoanAccountNumber}</Typography>
            </Box>
            <Box>
              <Typography variant="subtitle1" color="#64748B">
                {t("CUSTOMER_PROFILE.LOAN_TYPE_IG_ID", {
                  type: "Personal Loan",
                })}
              </Typography>
              <Typography variant="h6">{personalLoanIgId}</Typography>
            </Box>
          </Grid>
        )}
      </Stack>
      {publicationChargeWarning && (
        <Box mt={2}>
          <Alert
            severity="warning"
            sx={{
              width: "fit-content",
            }}
          >
            {publicationChargeWarning}
          </Alert>
        </Box>
      )}
    </Box>
  );
};

export default LoanDetails;
