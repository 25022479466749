import React from "react";
import {Route, Outlet, Navigate} from "react-router-dom";

import routeNames from "./routeNames";
import {
  Algo,
  AlgoConfirmView,
  AlgoDetailView,
  AlgoStartView,
  JobView,
  ResourceView,
  LmAvailability,
} from "app/AppLayout/getAllComponents";
import LeadCreditHistoryByParentId from "app/pages/home/GoldLoan/Scheduling/JobView/LeadCreditHistoryByParentId";

const SchedulingRoutes = (feature) => {
  const accessibleSubFeatures = feature.subMenu?.map(
    (subFeature) => subFeature.path,
  );
  return (
    <Route path={routeNames.scheduling} element={<Outlet />}>
      {/* /gold-loan/scheduling */}
      <Route index element={feature.subMenu[0]?.component()} />
      {/* /gold-loan/scheduling/job-view */}
      <Route
        path={routeNames.jobView}
        element={
          <>
            {accessibleSubFeatures.includes(routeNames.jobView) ? (
              <JobView />
            ) : (
              <Navigate to={`/gold-loan/${routeNames.scheduling}`} />
            )}
          </>
        }
      >
        {/* /gold-loan/scheduling/job-view/algo */}
        <Route path={routeNames.algo} element={<Algo />}>
          <Route index element={<AlgoStartView />} />
          <Route exact path=":id" element={<Outlet />}>
            <Route index element={<AlgoDetailView />} />
            <Route path="detail" element={<AlgoDetailView />} />
            <Route path="confirm" element={<AlgoConfirmView />} />
          </Route>
        </Route>
      </Route>
      {/* /gold-loan/scheduling/resource-view */}
      <Route
        path={routeNames.resourceView}
        element={
          <>
            {accessibleSubFeatures.includes(routeNames.resourceView) ? (
              <ResourceView />
            ) : (
              <Navigate to={`/gold-loan/${routeNames.scheduling}`} />
            )}
          </>
        }
      />
      {/* /gold-loan/scheduling/agent-availability */}
      <Route
        path={routeNames.lmAvailability}
        element={
          <>
            {accessibleSubFeatures.includes(routeNames.lmAvailability) ? (
              <LmAvailability />
            ) : (
              <Navigate to={`/gold-loan/${routeNames.scheduling}`} />
            )}
          </>
        }
      />
      <Route
        path={routeNames.leadCreditHistory}
        element={<LeadCreditHistoryByParentId />}
      />
    </Route>
  );
};

export default SchedulingRoutes;
