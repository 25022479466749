import React, {useEffect} from "react";
import {Box, Typography} from "@mui/material";
import {
  personalDetailsActions,
  personalDetailsSelectors,
} from "app/store/loanRenewalRebook/personalDetails";
import {useAppDispatch, useAppSelector} from "app/store/hooks";

interface GuarantorAddressDetailsProps {
  crId: number;
}
const formatAddress = (address: any) => {
  const {addressLine1, addressLine2, addressLine3, city, state, pincode} =
    address ?? {};
  return [addressLine1, addressLine2, addressLine3, city, state, pincode]
    .filter(Boolean)
    .join(", ");
};
const GuarantorAddressDetails = ({crId}: GuarantorAddressDetailsProps) => {
  const dispatch = useAppDispatch();
  const residentialAddress = useAppSelector((state) =>
    personalDetailsSelectors.getGuarantorAddressDetailsByType(
      state,
      "RESIDENTIAL_ADDRESS",
    ),
  );
  const permanentAddress = useAppSelector((state) =>
    personalDetailsSelectors.getGuarantorAddressDetailsByType(
      state,
      "PERMANENT_ADDRESS",
    ),
  );

  const formattedResidentialAddress = formatAddress(residentialAddress);
  const formattedPermanentAddress = formatAddress(permanentAddress);
  return (
    <Box
      bgcolor="#fff"
      borderRadius="4px"
      p={1}
      border="1px solid rgba(0, 0, 0, 0.12)"
    >
      <Typography color="gray">Guarantor's Permanent Address</Typography>
      <Typography>{formattedPermanentAddress}</Typography>

      <Box mt={2}>
        <Typography color="gray">Guarantor's Temporary Address</Typography>
        <Typography>{formattedResidentialAddress}</Typography>
      </Box>
    </Box>
  );
};

export default GuarantorAddressDetails;
